import React, { useState, useEffect } from "react";
import api from "../Api";
import ButtonTwo from "./ButtonTwo";
import Dropdown from "./Dropdown";

import { v4 as uuidv4 } from "uuid";
import { adjustmentReasons } from "../constants/Dropdowns";

const OpeningBalanceInput = ({
  id,
  franchiseId,
  familyId,
  fetchFamilies,
  loading,
  setOpenModal,
}) => {
  const [balanceAdjust, setBalanceAdjust] = useState("");
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const now = new Date();
  const localDate = now.toISOString().slice(0, 10);
  const localTime = now.toLocaleTimeString();
  const [note, setNote] = useState("Opening Balance");

  useEffect(() => {
    console.log(note);
  }, [note]);

  useEffect(() => {
    if (balanceAdjust > 0) {
      setCredit(balanceAdjust);
      setDebit(0);
    } else {
      setDebit(Math.abs(balanceAdjust));
      setCredit(0);
    }
  }, [balanceAdjust]);

  const postOpeningBalance = async () => {
    loading(true);
    let uuid = uuidv4();
    try {
      await api.students.setOpeningBalance(
        familyId,
        credit,
        debit,
        localDate,
        localTime,
        note,
        franchiseId,
        uuid
      );
      setOpenModal(false);
      setTimeout(() => {
        fetchFamilies();
      }, 500);
    } catch (err) {
      console.log(`Error is ${err}`);
      // Stop loading: otherwise if success, loading will be set to false in parent after fetchFamilies
      loading(false);
    } finally {
      setBalanceAdjust("");
    }
  };

  return (
    <div className="flex flex-1 items-center leading-8 py-4">
      <div className="flex-1">
        <div className="flex flex-col">
          <input
            id={`amount${id}`}
            className="w-full my-2 px-1 text-gray-600 bg-gray-200 rounded border-gray-200 focus:outline focus:ring"
            type="text"
            placeholder="Adjust Balance"
            value={balanceAdjust}
            onChange={(e) => {
              setBalanceAdjust(e.target.value);
            }}
          />
          <div>
            <Dropdown
              id={`opening-balance-${id}`}
              title=""
              value={note}
              data={adjustmentReasons}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <span className="flex-shrink text-right ml-4">
        <ButtonTwo
          title="Submit"
          disabled={loading}
          onClick={() => postOpeningBalance(balanceAdjust)}
        />
      </span>
    </div>
  );
};

export default OpeningBalanceInput;
