import React, { useState } from "react";

export const TransactionList = ({ data }) => {
  const [expandedStates, setExpandedStates] = useState({});

  const toggleExpand = (id) => {
    setExpandedStates({
      ...expandedStates,
      [id]: !expandedStates[id],
    });
  };

  let lastRollNumber = null;
  let isAlternate = false;

  return data.map((item, index) => {
    if (lastRollNumber !== item.roll_number) {
      isAlternate = !isAlternate;
      lastRollNumber = item.roll_number;
    }

    const bgColorClass = isAlternate ? "bg-white" : "bg-gray-200";

    return (
      <div className={`text-lg px-6 py-4 ${bgColorClass}`} key={item._id}>
        <div className="flex">
          {/* <span
            className={`flex-1 text-gray-300 my-auto cursor-pointer ${color}`}
          >
            {item._id}
          </span> */}
          <span className="flex-1 my-auto">
            {item.first_name} {item.last_name}
          </span>
          <span className="flex-1 my-auto">{}</span>
          <span className="flex-1 my-auto">{item.roll_number}</span>

          <span
            className="flex-1 my-auto text-right items-right"
            onClick={() => toggleExpand(item._id)}
          >
            {item.total ? (
              <>
                {`$${item.total.toFixed(2)}`}
                <span className="material-symbols-outlined align-middle ml-2">
                  expand_more
                </span>
              </>
            ) : (
              "N/A"
            )}
          </span>
        </div>
        {expandedStates[item._id] && (
          <div className="text-xs text-right flex flex-1 flex-col">
            {item.debits && item.local_dates
              ? item.debits.split(",").map((value, index) => (
                  <span key={index}>
                    {value} on {item.local_dates.split(",")[index]}
                  </span>
                ))
              : ""}
          </div>
        )}
      </div>
    );
  });
};

export const TransactionListHeaders = () => {
  return (
    <div className="flex">
      {/* <span className="flex-1">Server ID</span> */}
      <span className="flex-1">Name</span>
      <span className="flex-1"></span>
      <span className="flex-1 ">Roll Number</span>

      <span className="flex-1 text-right">Debit</span>
    </div>
  );
};
