import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import MainLayout from "../layout/Main";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import { FamilyListByStudentId } from "../components/FamilyListByStudentId";
import { FamilyByStudentIdHeaders } from "../components/FamilyWithStudentsHeaders";
import api from "../Api";

const FamiliesByStudentId = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const [families, setFamilies] = useState([]);
  const [search, setSearch] = useState("");
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [currentFamilies, setCurrentFamilies] = useState([]);
  const pageCount = Math.ceil(families.length / itemsPerPage);
  const [forcePage, setForcePage] = useState(0);
  const [filter, setFilter] = useState("");
  const cancelTokenSource = useRef(axios.CancelToken.source());

  // Preventing race conditions by adding a cancel token
  const fetchStudents = async () => {
    try {
      const familyList = await api.students.getFamiliesByStudentId(
        schoolId,
        cancelTokenSource.current
      );
      if (typeof familyList === "undefined" || !familyList.resource) {
        return alert(`No families found for ${filter}`);
      }
      if (
        Array.isArray(familyList.resource) &&
        familyList.resource.length === 0
      ) {
        return alert(`No families found for ${filter}`);
      }
      setFamilies(familyList.resource);
    } catch (err) {
      if (axios.isCancel(err)) return;
      console.log(`Error is ${err}`);
      alert(`Error fetching families: ${err}`);
    }
  };

  useEffect(() => {
    console.log(`filter is ${filter}`);
    fetchStudents();
  }, [filter]);

  useEffect(() => {
    if (search.length > 2) {
      setFilter(
        `AND((name%20like%20%25${search}%25)OR(email%20like%20%25${search}%25))`
      );
    } else if (search.length === 0) {
      setFilter("");
    }
  }, [search]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (families.length > 0) {
      setCurrentFamilies(families.slice(itemOffset, endOffset));
    }
  }, [families, itemOffset]);

  const handlePaginationClick = (event) => {
    if (!families || !Array.isArray(families) || families.length === 0) {
      return;
    }
    const newOffset = (event.selected * itemsPerPage) % families.length;
    setItemOffset(newOffset);
  };

  return (
    <MainLayout
      pageTitle="Families by Student ID"
      rightContainer={<></>}
      leftContainer={
        null
        // <Search
        //   id="search"
        //   placeholder={"Search"}
        //   onChange={(e) => {
        //     setSearch(e.target.value);
        //   }}
        // />
      }
      headers={<FamilyByStudentIdHeaders />}
      content={
        currentFamilies && (
          <>
            <div className="mb-16">
              <FamilyListByStudentId
                data={currentFamilies}
                fetchStudents={fetchStudents}
              />
            </div>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePaginationClick}
              forcePage={forcePage}
            />
          </>
        )
      }
    />
  );
};

export default FamiliesByStudentId;
