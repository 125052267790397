import React from "react";

const ButtonTwo = ({ onClick, title }) => {
  return (
    <span
      className="bg-blue-600 text-white active:bg-blue-700 font-bold uppercase px-6 py-3 mx-1 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm text-center cursor-pointer"
      onClick={onClick}
    >
      {title}
    </span>
  );
};
export default ButtonTwo;
