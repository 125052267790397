// Navigate to a page by name

export const pathToPageName = {
  "/": "Home",
  "/locations": "Locations", // Schools.js
  "/orders": "Orders", // Orders.js
  "/orders-with-names": "Orders With Names", // OrdersWithNames.js
  "/menu": "Master Menu",
  "/location-menu": "Location Menu",
  "/customers": "Customer List",
  "/families": "Family List and Adjust Balances",
  "/pre-reg": "Pre-Registration",
  "/user": "User Profile",
  "/families-with-children": "Families and Children",
  "/families-by-student-id": "Families by Student",
  "/sales-by-item": "Pre-order Sales by Item", // SalesByItem.js
  "/show-duplicates": "Show Duplicate Students",
  "/family-balances": "Family Balances",
  "/temp-wallet-sales": "Wallet Sales to 09/08/2023",
  "/temp-cash-sales": "Cash Sales to 09/08/2023",
  "/pos-sales": "POS Sales by Item and Date",
  "/student-transactions": "Student POS Transactions", // StudentTransactions.js
  "/guest-sales": "Guest Sales", // GuestSales.js
  "/student-transactions-itemized": "POS Transactions by Customer", // StudentTransactionsItemised.js
  "/student-preorders-itemized": "Pre-Orders by Customer", // StudentPreordersItemised.js
  "/family-transactions": "Family Wallet Transactions", // FamilyTransactions.js
};

// Navigation bar values.  Name of array defines the name of the dropdown

export const navLinks = {
  orders: [
    { path: "/orders", label: "Orders" },
    { path: "/orders-with-names", label: "Order + Name" },
  ],
  menus: [
    { path: "/menu", label: "Master Menu" },
    { path: "/location-menu", label: "Location Menu" },
  ],
  customers: [
    { path: "/customers", label: "Customer List" },

    { path: "/pre-reg", label: "Pre-Reg" },
  ],
  customer_reports: [
    { path: "/families-with-children", label: "Families and Children" },
    { path: "/families-by-student-id", label: "Family from Student" },
    { path: "/family-transactions", label: "Family Wallet Transactions" },
    { path: "/families", label: "Adjust Balances" },
  ],
  sales_reports: [
    { path: "/pos-sales", label: "POS Sales by Item and Date" },
    { path: "/sales-by-item", label: "Pre-order Sales by Item and Date" },
    { path: "/guest-sales", label: "Guest Sales" },
    { path: "/family-balances", label: "Family Balances" },
    {
      path: "/student-preorders-itemized",
      label: "Pre-Order Activity by Customer",
    },
    {
      path: "/student-transactions-itemized",
      label: "POS Activity by Customer",
    },
    { path: "/temp-wallet-sales", label: "Wallet Sales to 09/08/2023" },
    { path: "/temp-cash-sales", label: "Cash Sales to 09/08/2023" },
  ],
};
