export const validatePrice = (price) => {
  return /^[0-9.]/.test(price);
};

export const validateEmail = (email) => {
  return /^\S+@\S+\.\S+$/.test(email);
};

export const validateNumber = (credit) => {
  return /^[0-9.]/.test(credit);
};

export const validateUpperCaseLettersOnly = (str) => {
  return /^[A-Z]+$/.test(str);
};

export const validateItem = (itemName, itemPrice) => {
  const isNameValid = itemName.length >= 3;
  const isPriceValid = /^[0-9.]/.test(itemPrice);
  const isPriceInRange = itemPrice <= 20;

  if (!isNameValid) {
    return "Please enter a product name of at least 3 letters";
  }

  if (!isPriceValid) {
    return "Please enter numbers and decimal point only";
  }

  if (!isPriceInRange) {
    return "This seems expensive!";
  }

  return null;
};
