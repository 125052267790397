import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";
import NavBar from "../components/Navbar";
import Modal from "../components/Modal";
import "react-datepicker/dist/react-datepicker.css";
import BulkUpload from "../components/CSVUpload";
import AddEditPreRegStudent from "../components/AddEditPreRegStudent";
import Search from "../components/Search";
import HeaderButton from "../components/HeaderButton";
import PreRegList from "../components/PreRegList";

const PreRegisteredStudents = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState({});
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [csvUpload, setCsvUpload] = useState(false);
  const [addEdit, setAddEdit] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentList = await api.students.getPreReg(
          schoolId,
          searchFilter
        );
        setStudents(studentList.resource);
      } catch (err) {
        alert(
          `Your session has expired.  Please close this message and login.`
        );
        localStorage.removeItem("user");
        history.push("/login");
      } finally {
        console.log(`search is ${searchFilter}`);
      }
    };
    if (!openModal) {
      fetchStudents();
    }
  }, [searchFilter, openModal]);

  useEffect(() => {
    if (search.length > 2) {
      setSearchFilter(`AND(last_name%20LIKE%20${search}%25)`);
    } else setSearchFilter("");
  }, [search]);

  return (
    <>
      <div className="sticky top-0 w-full bg-white z-10">
        <div>
          <NavBar
            title={"Students"}
            schoolId={schoolId}
            schoolName={schoolName}
          />
        </div>
        <div className="w-full bg-white px-4 mt-2 h-16">
          <span className="flex flex-1 my-auto">
            <Search
              id={"search"}
              placeholder={"Search Last Name"}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <div className="flex-1 my-auto">
              <span className="float-right">
                <HeaderButton
                  title={"Add Student"}
                  onClick={() => {
                    // Ensure object is empty
                    setStudent({});
                    setCsvUpload(false);
                    setOpenModal(true);
                    setAddEdit(true);
                  }}
                />
                <HeaderButton
                  title={"Upload CSV"}
                  onClick={() => {
                    setAddEdit(false);
                    setCsvUpload(true);
                    setOpenModal(true);
                  }}
                />
              </span>
            </div>
          </span>
        </div>
        <div className="w-full bg-gray-300 text-black px-6 py-3">
          <span className="flex text-lg w-full">
            <span className="flex-grow-2 flex-shrink-1 w-2/12">First Name</span>
            <span className="flex-grow-2 flex-shrink-1 w-2/12">Last Name</span>
            <span className="flex-grow-2 flex-shrink-1 w-4/12">
              Primary Email
            </span>
            <span className="flex-grow-2 flex-shrink-1 w-2/12">
              Customer ID
            </span>
            <span className="flex-grow-1 flex-shrink-1 w-1/12">Claimed</span>
            <span className="flex-grow text-right">Edit</span>
          </span>
        </div>
      </div>
      <div className="relative">
        <PreRegList
          students={students}
          openModal={setOpenModal}
          addEdit={setAddEdit}
          student={setStudent}
        />
        {csvUpload && openModal && (
          <Modal
            title={"Upload"}
            openModal={setOpenModal}
            csvUpload={setCsvUpload}
            content={
              <BulkUpload openModal={setOpenModal} csvUpload={setCsvUpload} />
            }
          />
        )}
        {openModal && addEdit && (
          <Modal
            openModal={setOpenModal}
            addEdit={setAddEdit}
            title={
              Object.keys(student).length > 0
                ? `Edit ${student.first_name} ${student.last_name}`
                : "Add a customer to pre-reg list"
            }
            content={
              <AddEditPreRegStudent
                openModal={setOpenModal}
                addEdit={setAddEdit}
                student={student}
              />
            }
          />
        )}
      </div>
    </>
  );
};
export default PreRegisteredStudents;
