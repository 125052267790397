import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";
export const CancelToken = axios.CancelToken;

const reports = {
  getSalesByItem: async (schoolId, startDate, endDate) => {
    console.log(schoolId, startDate, endDate);
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/report_orders_by_item?p0=${schoolId}&p1=${startDate}&p2=${endDate}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  getDuplicateStudents: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/report_duplicate_students?wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  deleteStudent: async (id, roll_number, previous_roll_number, archived) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.patch(
      `${API_BASE_URL}/mysql/_table/students/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        roll_number,
        previous_roll_number,
        archived,
      }
    );
    return response;
  },

  checkForOrdersWithStudentId: async (studentId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/orders?filter=student_id=${studentId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  tranferTransactionsToCorrectAccount: async (familyIdFrom, FamilyIdTo) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/credit_and_debit_ledger?filter=family_id=${familyIdFrom}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { resource: [{ family_id: FamilyIdTo }] }
    );
    return data;
  },

  transferPreOrdersToCorrectAccount: async (studentIdFrom, studentIdTo) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/orders?filter=student_id=${studentIdFrom}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { resource: [{ student_id: studentIdTo }] }
    );
    return data;
  },

  transferPosOrdersToCorrectAccount: async (studentIdFrom, studentIdTo) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/orders_pos?filter=student_id=${studentIdFrom}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { resource: [{ student_id: studentIdTo }] }
    );
    return data;
  },

  getFamilyBalances: async (schoolId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/family_balances_v2?p0=${schoolId}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  getFamilyTransactionsFromRollNumber: async (schoolId, rollNumber) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/family_transactions_from_roll_number?p0=${schoolId}&p1=${rollNumber}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  tempWalletBalances: async (schoolCode) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/temp_wallet_balances?p0=${schoolCode}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  tempCashBalances: async (schoolCode) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/temp_cash_balances?p1=${schoolCode}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  posSales: async (schoolId, startDate, endDate) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/pos_sales?p1=${schoolId}&p2=${startDate}&p3=${endDate}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  studentTransactions: async (schoolId, startDate, endDate) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/report_student_transactions_by_school?p0=${schoolId}&p1=${startDate}&p2=${endDate}}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  studentTransactionsItemised: async (schoolId, startDate, endDate) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/report_pos_transactions_itemised?p0=${schoolId}&p1=${startDate}&p2=${endDate}}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  studentPreOrdersItemised: async (schoolId, startDate, endDate) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/report_preorder_transactions_itemised?p0=${schoolId}&p1=${startDate}&p2=${endDate}}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  guestSales: async (schoolId, startDate, endDate) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/guest_sales?p0=${schoolId}&p1=${startDate}&p2=${endDate}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  familyTransactions: async (familyId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/credit_and_debit_ledger?filter=family_id=${familyId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  getFamilyMembers: async (familyId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/students?filter=family_id=${familyId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },
};

export default reports;
