import React, { useEffect, useState } from "react";
import { StudentHeaders, PreRegHeaders } from "./FamilyWithStudentsHeaders";
import api from "../Api";

export const FamilyListWithChildren = ({ data }) => {
  const [expandedStates, setExpandedStates] = useState(
    Array(data.length).fill(false)
  );
  const [showPreRegStudents, setShowPreRegStudents] = useState(false);
  const [preRegStudents, setPreRegStudents] = useState([]);

  const fetchPreRegStudentsWithSameEmail = async (email) => {
    try {
      const result = await api.students.getPreRegStudentsWithSameEmail(email);
      if (result.resource.length > 0) {
        setPreRegStudents(result.resource);
        setShowPreRegStudents(true);
      }
    } catch (err) {
      console.log(`Error is ${err}`);
      alert(`Error fetching pre-reg students: ${err}`);
    }
  };

  const toggleExpand = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  const togglePreRegStudents = () => {
    setShowPreRegStudents(!showPreRegStudents);
  };

  return data.map((family, familyIndex) => {
    const backgroundColor = expandedStates[familyIndex]
      ? "bg-gray-400 shadow-lg rounded"
      : "even:bg-gray-50";
    return (
      <div
        className={`text-lg px-6 py-6 mx-2 ${backgroundColor}`}
        key={family._id}
      >
        <div className="flex" onClick={() => toggleExpand(familyIndex)}>
          <span className="flex-1 my-auto">{family.name}</span>
          <span className="flex-1 my-auto text-left">{family.email}</span>
          <span className="flex-1 my-auto text-right">
            {family.initial_user}
          </span>
          <span className="flex-1 my-auto text-right">{family._id}</span>
          <span className="flex-1 my-auto text-right cursor-pointer material-symbols-outlined">
            {expandedStates[familyIndex] ? "expand_less" : "expand_more"}
          </span>
        </div>
        {expandedStates[familyIndex] && (
          <div className="mt-6 mb-12 bg-gray-200 shadow-lg rounded pt-2">
            <div className="flex">
              <span className="pl-4 text-xl flex-1">
                System Parent User Id (PA):{" "}
                {family.parent_admins_by_family_id[0].user_id}
              </span>
              {/* <button
                className="text-xl flex-1 text-right pr-4"
                onClick={() => fetchPreRegStudentsWithSameEmail(family.email)}
              >
                {showPreRegStudents
                  ? "Hide Pre-Reg Students"
                  : "Show Pre-Reg Students"}
              </button> */}
            </div>
            <StudentHeaders />
            {family.students_v2_by_family_id.map((student, index) => (
              <div className="flex flex-1 my-auto py-4 pl-12" key={index}>
                <span className="flex-1 my-auto text-gray-400">
                  {student._id}
                </span>
                <span className="flex-1 my-auto">{`${student.first_name} ${student.last_name}`}</span>
                <span className="flex-1 my-auto">{student.roll_number}</span>
                <span className="flex-1 my-auto">{student.grade}</span>
              </div>
            ))}
            {showPreRegStudents && (
              <div className="mt-6 mb-12 bg-white shadow-lg rounded pt-2">
                <span className="pl-4 text-xl">Pre-Reg Students</span>
                <PreRegHeaders />
                {preRegStudents.map((student, index) => (
                  <div
                    className="flex flex-1 my-auto py-4 pl-12 bg-white"
                    key={index}
                  >
                    <span className="flex-1 my-auto text-gray-400">
                      {student._id}
                    </span>
                    <span className="flex-1 my-auto">{`${student.first_name} ${student.last_name}`}</span>
                    <span className="flex-1 my-auto">
                      {student.roll_number}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  });
};
