// In OrdersWithNames.  url/orders-with-names
export const csvHeaders = [
  { label: "Student ID", key: "students_v2_by_student_id.roll_number" },
  { label: "First Name", key: "students_v2_by_student_id.first_name" },
  { label: "Last Name", key: "students_v2_by_student_id.last_name" },
  { label: "Grade", key: "students_v2_by_student_id.grade" },
  { label: "Nut Allergy", key: "students_v2_by_student_id.nut_allergy" },
  { label: "Dairy Allergy", key: "students_v2_by_student_id.dairy_allergy" },
  { label: "Gluten Allergy", key: "students_v2_by_student_id.gluten_allergy" },
  { label: "Item", key: "menu_item.title" },
  { label: "Price", key: "school_menu_item.fixed_price" },
  { label: "Date", key: "date" },
];

// In SalesByItem.  url/sales-by-item
export const csvHeadersSalesByItem = [
  { label: "Item", key: "short_name" },
  { label: "Date", key: "date" },
  { label: "Total", key: "total" },
];

// In menu under Sales Reports => Family Balances.  url/family-balances
// Component: src/components/FamilyBalancesList.js Page: src/pages/FamilyBalances.js
export const csvHeadersFamilyBalances = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Student ID", key: "roll_number" },
  { label: "Family ID", key: "family_id" },
  { label: "Email", key: "email" },
  { label: "Total Credit", key: "total_credit" },
  { label: "Total Debit", key: "total_debit" },
  { label: "Balance", key: "balance" },
];

//SalesPOSByType.js In menu under Sales Reports => POS Sales by Item and Date.  url/pos-sales
export const csvHeadersPOSSalesByType = [
  { label: "Type", key: "type" },
  { label: "Date", key: "date" },
  { label: "Short Name", key: "short_name" },
  { label: "Order Count", key: "order_count" },
  { label: "Total $", key: "total_value" },
];

//TempWalletSales.js In menu under Sales Reports => Wallet Sales to 09/08/2023.  url/temp-wallet-sales
export const csvHeadersTempWalletSales = [
  { label: "Date", key: "local_date" },
  { label: "Total", key: "total_debit" },
];

//TempCashSales.js In menu under Sales Reports => Cash Sales to 09/08/2023.  url/temp-cash-sales
export const csvHeadersTempCashSales = [
  { label: "Date", key: "local_date" },
  { label: "Guest Total", key: "guest_total" },
  { label: "Known ID Total", key: "known_id_total" },
];
