import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";

const orders = {
  getOrders: async (schoolId, date, service) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/admin_orders_aggregated?p0=${schoolId}%20&p1=${date}%20&p2=${service}%20&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  getOrdersWithNames: async (schoolId, start, finish) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/orders?related=students_v2_by_student_id,menu_item,school_menu_item&filter=(school_id=${schoolId})AND(date%20gte%20${start})AND(date%20lte%20${finish})&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  getOrderCount: async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/orders?filter=school_menu_id=${id}&count_only=true&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  studentLabels: async (schoolId, date, service) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/bevaris_picking_list?p0=${schoolId}%20&p1=${date}%20&p2=${service}%20&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  deleteMeal: async (params) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.delete(
      `${API_BASE_URL}/delete_meal?${params}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },

  deleteOrdersByDayAndGrade: async (filter) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.delete(
      `${API_BASE_URL}/mysql/_table/orders?filter=${filter}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },
};

export default orders;
