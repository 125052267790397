import React, { useState } from "react";

export const FamilyListByStudentId = ({ data }) => {
  const [expandedStates, setExpandedStates] = useState(
    Array(data.length).fill(false)
  );
  const [showPreRegStudents, setShowPreRegStudents] = useState(false);

  const togglePreRegStudents = () => {
    setShowPreRegStudents(!showPreRegStudents);
  };

  const toggleExpand = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  return data.map((student, studentIndex) => {
    const backgroundColor = expandedStates[studentIndex]
      ? "bg-gray-400 shadow-lg rounded"
      : "even:bg-gray-50";
    return (
      <div
        className={`text-lg px-6 py-6 mx-2 ${backgroundColor}`}
        key={student._id}
      >
        <div className="flex" onClick={() => null}>
          <span className="flex-1 my-auto">{student.last_name}</span>
          <span className="flex-1 my-auto text-left">{student.first_name}</span>
          <span className="flex-1 my-auto">{student.roll_number}</span>
          <span className="flex-1 my-auto">{student.email}</span>
        </div>
      </div>
    );
  });
};
