import React from "react";
import DateManager from "../utils/convertDate";

export const SalesByItemList = ({ data }) => {
  // _id can be the same for multiple items (because same item, different day), so using index
  return data.map((sales, index) => {
    return (
      <div className="text-lg px-6 even:bg-gray-50 py-2" key={index}>
        <div className="flex">
          <span className="flex-grow-2 flex-shrink-1 w-1/4 my-auto">
            {sales.short_name}
          </span>
          <span className="flex-grow-2 flex-shrink-1 w-1/5 my-auto">
            {DateManager.USDate(sales.date)}
          </span>
          <span className="flex-grow-2 flex-shrink-1 w-1/5 my-auto"></span>
          <span className="flex-grow-2 flex-shrink-1 w-1/5 my-auto"></span>
          <span className="flex-grow w-1/10 my-auto text-right">
            {sales.total}
          </span>
        </div>
      </div>
    );
  });
};

export const SalesByItemHeaders = () => {
  return (
    <div className="flex text-lg">
      <span className="flex-grow-2 flex-shrink-1 w-1/4">Item</span>
      <span className="flex-grow-2 flex-shrink-1 w-1/5 pointer-events-none">
        Date
      </span>
      <span className="flex-grow-2 flex-shrink-1 w-1/5 text-right pointer-events-none"></span>
      <span className="flex-grow-2 flex-shrink-1 w-1/5 text-right pointer-events-none"></span>
      <span className="flex-grow w-1/10 text-right pointer-events-none">
        Total Value
      </span>
    </div>
  );
};
