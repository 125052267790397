import React, { useState } from "react";

// In menu under Sales Reports => Family Balances.  url/family-balances

export const FamilyBalanceList = ({ data }) => {
  const [expandedStates, setExpandedStates] = useState({});
  const [transactions, setTransactions] = useState([]);
  return data.map((item) => {
    return (
      <div
        className={`text-lg px-6 py-4 even:bg-gray-50" ${
          item.balance < 0 ? "text-red-500" : ""
        }`}
        key={item._id}
      >
        <div className="flex">
          <span className="w-1/12 my-auto break-words">{item.last_name}</span>
          <span className="w-1/12 my-auto break-words">{item.first_name}</span>
          <span className="w-1/6 my-auto break-words">{item.roll_number}</span>
          <span className="w-1/12 my-auto">{item.family_id}</span>
          <span className="w-1/3 my-auto break-words">{item.email}</span>
          <div className="flex w-1/4 my-auto">
            <span className="w-1/3 my-auto text-right">
              {item.total_credit}
            </span>
            <span className="w-1/3 my-auto text-right">
              {item.total_debit ? `-${item.total_debit}` : ""}
            </span>
            <span className="w-1/3 my-auto text-right">{item.balance}</span>
          </div>
        </div>
        {expandedStates[transactions._id] ? {} : null}
      </div>
    );
  });
};

export const FamilyBalanceHeaders = () => {
  return (
    <div className="flex">
      <span className="w-1/12">Last Name</span>
      <span className="w-1/12">First Name</span>
      <span className="w-1/6">Student ID</span>
      <span className="w-1/12">Family ID</span>
      <span className="w-1/3">Email</span>
      <div className="flex w-1/4 text-right">
        <span className="flex-1 text-right">Total Credit</span>
        <span className="flex-1 text-right">Total Debit</span>
        <span className="flex-1 text-right">Balance</span>
      </div>
    </div>
  );
};
