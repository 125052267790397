import React from "react";

const Dropdown = ({ id, title, data, value, onChange }) => {
  return (
    <span className="text-xl">
      <label>
        {title}{" "}
        <select
          id={id}
          className="border border-solid border-gray-400 rounded text-gray-600"
          value={value}
          onChange={onChange}
        >
          {data.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </span>
  );
};

export default Dropdown;
