import React from "react";

export const FamilyListWithChildrenHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Name</span>
      <span className="flex-1">Email</span>
      <span className="flex-1 text-right">System Parent User ID (F)</span>
      <span className="flex-1 text-right">System Family ID</span>
      <span className="flex-1 text-center"></span>
    </div>
  );
};

export const StudentHeaders = () => {
  return (
    <div className="bg-gray-200 pt-4">
      <span className="pl-4 text-xl">Family Members</span>
      <div className="flex flex-1 pl-12 pt-8  border-b-2 border-gpsBrown">
        <span className="flex-1">Student System ID</span>
        <span className="flex-1">Student Name</span>
        <span className="flex-1">Student ID</span>
        <span className="flex-1">Grade</span>
      </div>
    </div>
  );
};

export const PreRegHeaders = () => {
  return (
    <div className="bg-white pt-4">
      <div className="flex flex-1 pl-12 pt-8  border-b-2 border-gpsBrown">
        <span className="flex-1">Student System ID</span>
        <span className="flex-1">Student Name</span>
        <span className="flex-1">Student ID</span>
        <span className="flex-1">Grade</span>
      </div>
    </div>
  );
};

/////////////////////////////

export const FamilyByStudentIdHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Last Name</span>
      <span className="flex-1">First Name</span>
      <span className="flex-1">Student Id</span>
      <span className="flex-1">Parent Email</span>
    </div>
  );
};
