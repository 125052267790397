import React, { useEffect, useState, useCallback } from "react";
import api from "../Api";

const AuthorizeSellerRedirect = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const state = params.get("state");
  const storedState = localStorage.getItem("state");
  const franchiseId = localStorage.getItem("franchiseId");
  const locationId = localStorage.getItem("locationId");
  const [message, setMessage] = useState("One moment please...");
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;

  // After authorizing the seller, we need to submit the token to the backend, where it is used to create
  // further tokens for access to the seller's account by GPS

  const submitToken = async () => {
    if (!code || !franchiseId || !locationId) {
      setMessage(`Something went wrong. Please close this tab and try again.`);
      return;
    }
    try {
      const result = await api.auth.submitToken(
        code,
        franchiseId,
        locationId,
        schoolId,
        schoolName
      );
      setMessage(`Success! You can now close this tab.`);
    } catch (error) {
      setMessage(
        `Something went wrong. Please close this tab and try again.  If the problem persists, please contact support ${error}`
      );
    } finally {
      localStorage.removeItem("state");
    }
  };

  // Check the state variable is the same as previous page, to prevent CSRF attacks
  useEffect(() => {
    if (state !== storedState) {
      setMessage(
        "Something went wrong. Please close this tab and try again.  If the problem persists, please contact support and provide the following error code: CSRF-ERROR "
      );
      return;
    }
    submitToken();
  }, [state, storedState]);

  return (
    <div className="absolute w-full h-full flex justify-center">
      <div className="text-4xl my-auto p-12 text-center">{message}</div>
    </div>
  );
};

export default AuthorizeSellerRedirect;
