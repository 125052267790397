import React, { useEffect, useState } from "react";
// API
import api from "../Api";
// Layouts
import MainLayout from "../layout/Main";
//Helpers
import DateManager from "../utils/convertDate";
import { getCurrentWeek } from "../utils/functions";
// Components
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Search from "../components/Search";
import DateRangePicker from "../components/DateRangePicker";
import {
  TransactionList,
  TransactionListHeaders,
} from "../components/StudentTransactionListItemised";

// Preorders by student
const StudentTransactionsPreOrderItemised = () => {
  const school = JSON.parse(localStorage.getItem("school") || "{}");
  const schoolId = school?.schoolId;
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [studentTransactions, setStudentTransactions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const pageCount = Math.ceil(studentTransactions.length / itemsPerPage);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const mealType = "pre-order";

  useEffect(() => {
    // Set the date range to the current week
    const { monday, friday } = getCurrentWeek();
    setStartDate(monday);
    setEndDate(friday);
  }, []);

  const filterData = (key, value, data) => {
    return data.filter((item) =>
      item[key].toLowerCase().includes(value.toLowerCase())
    );
  };

  useEffect(() => {
    if (search.length > 2) {
      setFilteredData(filterData("last_name", search, studentTransactions));
    } else {
      setFilteredData(studentTransactions);
    }
  }, [search, studentTransactions]);

  const fetchStudentTransactions = async () => {
    if (!startDate || !endDate) {
      return;
    }
    setLoading(true);
    try {
      const transactions = await api.reports.studentPreOrdersItemised(
        schoolId,
        DateManager.formatDateForMysql(startDate),
        DateManager.formatDateForMysql(endDate)
      );
      if (transactions.resource) {
        let data = transactions.resource;
        data = groupByStudent(data);
        setStudentTransactions(data);
      }
    } catch (err) {
      console.log(`Error is ${err}`);
      alert(`Error fetching student transactions: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchTransactions = async () => {
      if (isMounted) {
        await fetchStudentTransactions();
      }
    };
    fetchTransactions();
    return () => {
      isMounted = false;
    };
  }, [startDate, endDate, schoolId]);

  const handlePaginationClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const paginatedData = filteredData.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  // Group the data by student,. because MySQL doesn't have JSON_AGG etc
  function groupByStudent(rows) {
    const grouped = {};
    rows.forEach((row) => {
      if (!grouped[row.student_id]) {
        grouped[row.student_id] = {
          student_id: row.student_id,
          first_name: row.first_name,
          last_name: row.last_name,
          roll_number: row.roll_number,
          school_id: row.school_id,
          family_id: row.family_id,
          orders: [],
          total_price: 0,
        };
      }
      // Mainting aligment in naming with POS report to reuse the same list component
      if (row.transaction_id !== null) {
        grouped[row.student_id].orders.push({
          transaction_id: row.transaction_id,
          local_date: row.date, // Using 'local_date' instead of 'date'
          local_time: null, // Placeholder, as time data is not available
          price: row.price,
          created_by: null, // Placeholder, as created_by data is not available
          payment_type: null, // Placeholder, as payment_type data is not available
          short_name: row.title, // Using 'short_name' instead of 'title'
          collected: row.collected,
        });
        grouped[row.student_id].total_price += parseFloat(row.price);
      }
    });

    return Object.values(grouped);
  }

  return (
    <MainLayout
      pageTitle=""
      rightContainer={
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      }
      leftContainer={
        <Search
          id={"search"}
          placeholder={"Search Last Name"}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      }
      headers={<TransactionListHeaders />}
      content={
        loading ? (
          <Loading />
        ) : (
          paginatedData && (
            <>
              <div className="mb-16">
                <TransactionList data={paginatedData} mealType={mealType} />
              </div>
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePaginationClick}
              />
            </>
          )
        )
      }
    />
  );
};

export default StudentTransactionsPreOrderItemised;
