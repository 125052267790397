import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";

const Settings = () => {
  const history = useHistory();
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId } = school;
  const [accountStatus, setAccountStatus] = useState({});
  const [isConnected, setIsConnected] = useState(false);

  const isAccountConnected = () =>
    new Date(accountStatus.expires_at) > new Date();

  useEffect(() => {
    const getAccountStatus = async () => {
      try {
        const response = await api.auth.tokenStatus(schoolId);
        setAccountStatus(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getAccountStatus();
  }, [schoolId]);

  useEffect(() => {
    setIsConnected(isAccountConnected());
  }, [accountStatus]);

  const renderScopes = () => {
    return (
      <div className="flex flex-col flex-1 mt-2">
        Scopes:
        {accountStatus.scopes?.map((scope, index) => (
          <div key={index} className="text-left text-sm">
            {scope}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full p-6">
      <button
        className="text-gray-500 hover:text-gray-700 font-bold px-2 rounded cursor-pointer"
        onClick={() => {
          history.push("/locations");
        }}
      >
        Back to Locations
      </button>
      <h1 className="text-3xl text-center my-6">Settings</h1>
      <div className="bg-gray-200 py-4 rounded-xl">
        <p className="px-4 text-xl mb-2">Square Settings</p>
        <div className="flex">
          <div className="flex flex-row flex-grow items-center px-4">
            <p className="flex flex-col">
              <span className="flex-1 text-xl">
                {accountStatus.merchant_id &&
                  `Merchant ID: ${accountStatus?.merchant_id}`}
              </span>
              <span className="flex-1 text-xl">
                {accountStatus.location_id &&
                  `Location ID: ${accountStatus?.location_id}`}
              </span>
            </p>
            <button
              className={`flex-1 text-xl text-right px-4 ${
                isConnected ? "text-green-400" : "text-blue-400"
              }`}
              onClick={() => {
                history.push("/connect-account");
              }}
            >
              {isConnected ? "Connected" : "Connect >"}
            </button>
          </div>
        </div>
        <div className="flex mt-2">
          <div className="flex flex-col flex-1 px-4">
            <span className={`flex-1 text-lg`}>
              Valid until {new Date(accountStatus.expires_at)?.toLocaleString()}
            </span>
            {renderScopes()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
