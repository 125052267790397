import React, { Fragment } from "react";

const StudentList = ({ students, openModal, student, addStudent }) => {
  // const allergies = (record) => {
  //   const dairyAllergy = record.dairy_allergy ? "Dairy Allergy" : "";
  //   const nutAllergy = record.nut_allergy ? "Nut Allergy" : "";
  //   const glutenAllergy = record.gluten_allergy ? "Gluten Allergy" : "";
  //   const vegetarian = record.vegetarian ? "Vegetarian" : "";
  //   const vegan = record.vegan ? "Vegan" : "";
  //   if (!dairyAllergy && !nutAllergy) {
  //     return null;
  //   }
  //   return `${dairyAllergy}${
  //     dairyAllergy && nutAllergy ? " & " : ""
  //   }${nutAllergy}`;
  // };

  const allergies = (record) => {
    const dairyAllergy = record.dairy_allergy ? "Dairy Allergy" : "";
    const nutAllergy = record.nut_allergy ? "Nut Allergy" : "";
    const glutenAllergy = record.gluten_allergy ? "Gluten Allergy" : "";
    const vegetarian = record.vegetarian ? "Vegetarian" : "";
    const vegan = record.vegan ? "Vegan" : "";

    if (
      !dairyAllergy &&
      !nutAllergy &&
      !glutenAllergy &&
      !vegetarian &&
      !vegan
    ) {
      return null;
    }

    const allergyOptions = [];
    if (dairyAllergy) {
      allergyOptions.push(dairyAllergy);
    }
    if (nutAllergy) {
      allergyOptions.push(nutAllergy);
    }
    if (glutenAllergy) {
      allergyOptions.push(glutenAllergy);
    }
    if (vegetarian) {
      allergyOptions.push(vegetarian);
    }
    if (vegan) {
      allergyOptions.push(vegan);
    }

    return allergyOptions.join(" / ");
  };

  return (
    <div className="relative w-full pb-12">
      <div className="w-full" id="printable-div">
        {students.map((e) => {
          return (
            <Fragment key={e._id}>
              <div className="py-5 text-lg px-6">
                <div className="flex mb-4">
                  <span className="flex-1">{e.first_name}</span>
                  <span className="flex-1">{e.last_name}</span>
                  <span className="flex-1 capitalize">
                    {e.grade ? e.grade : "-"}
                  </span>
                  <div className="flex-1">
                    <span className="">{e.roll_number}</span>
                    <span className="">{e.previous_roll_number}</span>
                  </div>
                  <span className="flex-1">{!e.stipend ? "No" : "Yes"}</span>
                  <span className="flex-shrink text-right">
                    <span
                      onClick={() => {
                        addStudent(false);
                        student(e);
                        openModal(true);
                      }}
                      className="material-symbols-outlined"
                    >
                      edit
                    </span>
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="flex-1 text-blue-500">
                    {allergies(e) && (
                      <span className="mr-6 border border-solid border-gray-50 p-2 rounded shadow">
                        {allergies(e)}
                      </span>
                    )}

                    {/* {e?.restrictions_by_student_id[0]?.restriction && (
                      <span className="mr-6 border border-solid border-gray-50 p-2 rounded shadow">
                        {e?.restrictions_by_student_id[0]?.restriction}
                      </span>
                    )} */}
                    {e.max_spend && e.max_spend < 999 && (
                      <span className="mr-6 border border-solid border-gray-50 p-2 rounded shadow">
                        Maximum spend ${e.max_spend.toFixed(2)}
                      </span>
                    )}
                    {e.allow_negative_balance && (
                      <span className=" mr-6 border border-solid border-gray-50 p-2 rounded shadow">
                        Allow negative balance
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <hr className="w-full" />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default StudentList;
