import React, { useState } from "react";
import api from "../Api";

// MenuCheckbox allows instant update of values in the scrolling list of items, without opening an edit modal,
// and includes some styling not found in "Checkbox"

const MenuCheckbox = ({
  service,
  serviceReadable,
  defaultChecked,
  id,
  itemId,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleOnChange = () => {
    console.log({ id });
    updateStatus(id, service, !isChecked);
    setIsChecked(!isChecked);
  };

  const updateStatus = async (id, service, isChecked) => {
    try {
      const payload = { [service]: isChecked };
      await api.menus.updateService(itemId, payload);
    } catch (err) {
      console.log(`Error is ${err}`);
    }
  };
  return (
    <span
      className={`block my-auto mx-4 border border-gray-300 rounded p-2 ${
        isChecked && "bg-blue-50 shadow-md "
      }${service === "active" && " "}`}
    >
      <label htmlFor={id} className=" mx-auto text-gray-700 flex-1">
        {serviceReadable}
        <input
          id={id}
          name="service"
          className="flex-1 align-middle w-4 h-4 border-gray-300 rounded mx-2"
          type="checkbox"
          value={service}
          defaultChecked={defaultChecked}
          key={`${service}${id}`}
          onClick={() => handleOnChange()}
        />
      </label>
    </span>
  );
};

export default MenuCheckbox;
