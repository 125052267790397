export const handlePosOrder = (order, posOrder) => {
  switch (order) {
    case "ASC":
      posOrder("DESC");
      break;
    case "DESC":
      posOrder("");
      break;
    case "":
      posOrder("ASC");
      break;
    default:
      posOrder("");
  }
};

export const handleItemAlphaOrder = (order, itemOrder) => {
  switch (order) {
    case "ASC":
      itemOrder("DESC");
      break;
    case "DESC":
      itemOrder("");
      break;
    case "":
      itemOrder("ASC");
      break;
    default:
      itemOrder("");
  }
};
