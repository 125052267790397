import React, { useEffect, useState } from "react";
import api from "../Api";
import "react-datepicker/dist/react-datepicker.css";
import DateManager from "../utils/convertDate";
import { CSVLink } from "react-csv";
import { csvHeaders } from "../constants";
import ButtonTwo from "../components/ButtonTwo";
import MainLayout from "../layout/Main";
import AllergyDisplay from "../components/AllergyDisplay";
import { convertFalseToEmptyString } from "../utils/functions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrdersWithNames = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [service] = useState("breakfast");
  const [asFile, setAsFile] = useState("");

  const sortArrayByDateThenLastName = (arr) => {
    return [...arr].sort((a, b) => {
      // Parse the dates to timestamps for numerical comparison
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();

      // Retrieve last names and convert them to uppercase
      const nameA = (
        a?.students_v2_by_student_id?.last_name || ""
      ).toUpperCase();
      const nameB = (
        b?.students_v2_by_student_id?.last_name || ""
      ).toUpperCase();

      // First sort by date descending
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;

      // Then sort by last name
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchOrders = async () => {
      const start = DateManager.formatDateForMysql(startDate);
      const finish = DateManager.formatDateForMysql(endDate);
      try {
        const orderList = await api.orders.getOrdersWithNames(
          schoolId,
          start,
          finish
        );
        if (isMounted) {
          if (!orderList.resource) {
            setOrders([]);
          }
          setOrders(orderList.resource);
        }
      } catch (err) {
        if (isMounted) {
          alert(`There was an error, please try again`);
        }
      } finally {
        if (isMounted) {
          setAsFile("");
        }
      }
    };

    fetchOrders();

    return () => {
      isMounted = false;
    };
  }, [service, startDate, endDate, schoolId]);

  useEffect(() => {
    let isMounted = true;

    if (asFile !== "") {
      const fetchOrders = async () => {
        const start = DateManager.formatDateForMysql(startDate);
        const finish = DateManager.formatDateForMysql(endDate);
        try {
          const orderList = await api.orders.getOrdersWithNames(
            schoolId,
            start,
            finish
          );
          if (isMounted) {
            if (!orderList.resource) {
              setOrders([]);
            }
            setOrders(orderList.resource);
          }
        } catch (err) {
          if (isMounted) {
            alert(`There was an error, please try again`);
          }
        } finally {
          if (isMounted) {
            setAsFile("");
          }
        }
      };
      fetchOrders();
    }

    return () => {
      isMounted = false;
    };
  }, [asFile, startDate, endDate, schoolId]);

  const headers = () => {
    return (
      <div className="flex px-6 text-lg w-full">
        <span className="flex-1">Name</span>
        <span className="flex-1">Student ID</span>
        <span className="flex-1">Grade</span>
        <span className="flex-1">Item</span>
        <span className="flex-1">Allergens</span>
        <span className="flex-1 text-right">Date</span>
      </div>
    );
  };

  return (
    <MainLayout
      title={`Aggregated Orders`}
      schoolId={schoolId}
      schoolName={schoolName}
      rightContainer={
        <div className="w-full text-right pr-6">
          <CSVLink
            // Remove false values to improve display in Excel and order by last name
            data={sortArrayByDateThenLastName(
              convertFalseToEmptyString(orders)
            )}
            headers={csvHeaders}
          >
            <ButtonTwo title="Download" />
          </CSVLink>
        </div>
      }
      leftContainer={
        <div className="flex w-1/2 pl-6">
          <div className="flex-1 my-auto">
            <span className="text-lg"> Start Date: </span>
            <DatePicker
              id="date-picker"
              startDate={startDate}
              className="border border-solid border-gray-400 rounded w-32 text-xl p-1"
              closeOnScroll={true}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="flex-1 my-auto">
            <span className="text-lg"> End Date: </span>
            <DatePicker
              id="date-picker"
              startDate={endDate}
              className="border border-solid border-gray-400 rounded w-32 text-xl p-1"
              closeOnScroll={true}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
      }
      headers={headers()}
      content={
        <div>
          {orders.length > 0 ? (
            sortArrayByDateThenLastName(orders).map((e) => {
              const studentInfo = e.students_v2_by_student_id;

              return (
                <div className="w-full px-6 border-b-2" key={e._id}>
                  <div className="flex py-2 px-6 text-lg w-full">
                    <span className="flex-1">
                      {studentInfo
                        ? `${studentInfo.first_name} ${studentInfo.last_name}`
                        : "N/A"}
                    </span>
                    <span className="flex-1">
                      {studentInfo ? studentInfo.roll_number : "N/A"}
                    </span>
                    <span className="flex-1">
                      {studentInfo ? studentInfo.grade : "N/A"}
                    </span>
                    <span className="flex-1">
                      {e.menu_item ? e.menu_item.title : "N/A"}
                    </span>
                    <span className="flex-1">
                      {<AllergyDisplay data={e} />}
                    </span>
                    <span className="flex-1 text-right">
                      {DateManager.USDate(e.date)}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div className="w-full text-lg text-center mt-16">
                There are no orders for this date
              </div>
            </>
          )}
        </div>
      }
    />
  );
};
export default OrdersWithNames;
