import React, { useState, useEffect } from "react";
import api from "../Api";
import { grades, max } from "../constants";
import Dropdown from "./Dropdown";
import Input from "./Input";
import ButtonTwo from "./ButtonTwo";
import Checkbox from "./Checkbox";

// Now only EDIT, not add and edit because ADD is significantly different

/*
 * Restrictions table needs to be remade or joined to students_v2 by FK
 * In the meantime we have removed the restrictions table from the API
 */

const AddEditStudent = ({ openModal, student }) => {
  const [franchiseId] = useState(localStorage.getItem("franchiseId"));
  const [firstName, setFirstName] = useState(student?.first_name);
  const [lastName, setLastName] = useState(student?.last_name);
  const [grade, setGrade] = useState(student?.grade);
  const [rollNumber, setRollNumber] = useState(student?.roll_number);
  const [id] = useState(student?._id);
  const [dairyAllergy, setDairyAllergy] = useState(student?.dairy_allergy);
  const [nutAllergy, setNutAllergy] = useState(student?.nut_allergy);
  const [glutenAllergy, setGlutenAllergy] = useState(student?.gluten_allergy);
  const [vegetarian, setVegetarian] = useState(student?.vegetarian);
  const [vegan, setVegan] = useState(student?.vegan);
  const [maxSpend, setMaxSpend] = useState(student?.max_spend || 999);
  const [stipend, setStipend] = useState(student?.stipend || false);
  const [allowNegativeBalance, setAllowNegativeBalance] = useState(
    student?.allow_negative_balance
  );
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState(student?.school_id);
  // const [restriction, setRestriction] = useState(
  //   student?.restrictions_by_student_id[0]?.restriction || ""
  // );
  // const [patchId] = useState(student?.restrictions_by_student_id[0]?._id || "");
  const fetchSchools = async () => {
    try {
      const list = await api.schoolInfo.getList(franchiseId);
      const schoolList = list.resource
        .filter((item, index) => index !== 0)
        .map((school) => {
          return {
            label: school.name,
            value: school._id,
          };
        });
      setSchools(schoolList);
    } catch (err) {
      console.error(`Error is ${err}`);
    }
  };

  useEffect(() => {
    fetchSchools();
  }, [openModal, franchiseId]);

  const handleEditStudent = () => {
    !firstName || !lastName ? alert("Please fill all fields!") : editStudent();
  };

  const editStudent = async () => {
    try {
      await api.students.updateStudent(
        allowNegativeBalance,
        dairyAllergy,
        firstName,
        grade,
        id,
        lastName,
        maxSpend,
        nutAllergy,
        glutenAllergy,
        vegetarian,
        vegan,
        school,
        rollNumber,
        stipend
      );
    } catch (err) {
      alert("Sorry, that wasn't possible");
    } finally {
      openModal(false);
      // updateRestriction();
    }
  };

  // const updateRestriction = async () => {
  //   try {
  //     patchId
  //       ? await api.students.updateRestrictions(id, restriction)
  //       : await api.students.newRestrictions(id, restriction);
  //   } catch (err) {
  //     console.log(`Error is ${err}`);
  //   } finally {
  //     openModal(false);
  //   }
  // };

  return (
    <>
      <form>
        <div className="px-2 mb-3 -mt-4 w-full text-xl">
          <Input
            placeholder={firstName ? firstName : "First Name"}
            val={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            placeholder={lastName ? lastName : "Last Name"}
            val={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Input
            placeholder={rollNumber ? rollNumber : "Roll Number"}
            val={rollNumber}
            onChange={(e) => setRollNumber(e.target.value)}
          />
          <span className="inline-flex bg-white flex-1 ml-1 my-6">
            <Dropdown
              title={"School Assignment"}
              data={schools}
              value={school}
              onChange={(e) => setSchool(e.target.value)}
            />
          </span>

          <div className="ml-1 mt-2">
            <Checkbox
              label="Dairy Allergy"
              value={dairyAllergy}
              action={setDairyAllergy}
            />
            <Checkbox
              label="Nut Allergy"
              value={nutAllergy}
              action={setNutAllergy}
            />
            <Checkbox
              label="Gluten Allergy"
              value={glutenAllergy}
              action={setGlutenAllergy}
            />
            <Checkbox
              label="Vegetarian"
              value={vegetarian}
              action={setVegetarian}
            />
            <Checkbox label="Vegan" value={vegan} action={setVegan} />
          </div>
          <span className="inline-flex bg-white flex-1 ml-1 my-6">
            <Dropdown
              title={"Service Assignment"}
              data={grades}
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
            />
          </span>

          <div className="mb-4 ml-1">
            <Dropdown
              title="Maximum Spend"
              data={max}
              value={maxSpend}
              onChange={(e) => setMaxSpend(e.target.value)}
            />
            <span className="ml-6">
              <Checkbox
                label="Allow Negative Balance?"
                value={allowNegativeBalance}
                action={setAllowNegativeBalance}
              />
            </span>
          </div>
          <div className="w-full">
            <Checkbox label="Stipend?" value={stipend} action={setStipend} />
          </div>
          {/* <Input
            placeholder={restriction ? restriction : "Other Restrictions"}
            val={restriction}
            onChange={(e) => setRestriction(e.target.value)}
          /> */}
          <div className="w-full flex flex-col items-center mt-8">
            <ButtonTwo title="Save" onClick={() => handleEditStudent()} />
          </div>
        </div>
      </form>
    </>
  );
};
export default AddEditStudent;
