import React from "react";
import api from "../Api";
import MainLayout from "../layout/Main";

const CancelOrders = () => {
  const getPreOrdersForDay = async () => {
    try {
      const response = await api.getPreOrdersForDay();
    } catch (err) {
      console.error(`Error is ${err}`);
      alert("Failed to fetch. Please try again.");
    }
  };
  return (
    <MainLayout
      leftContainer={null}
      rightContainer={null}
      headers={null}
      content={<></>}
    />
  );
};
export default CancelOrders;
