import React from "react";
import MenuCheckbox from "./MenuCheckbox";
import { services, allergies } from "../constants";

const MainMenuList = ({ menu, openModal, edit, menuItem }) => {
  return menu.map((e) => {
    return (
      <div
        onClick={() => {}}
        className="flex flex-row items-center align-middle border border-bottom border-gray-100"
        key={`menu${e._id}`}
      >
        <span className="flex flex-row w-1/2 px-6 text-2xl">
          <span className="inline-flex flex-1 pr-4">{e.title}</span>
          <span className="inline-flex flex-1 text-right">
            ${e?.price.toFixed(2)}
          </span>
        </span>
        <span className="flex flex-col w-1/2 py-4 justify-end">
          <span className="flex flex-row flex justify-end py-4">
            {services.map((atr) => {
              return (
                <span className="" key={atr.service + e._id}>
                  <MenuCheckbox
                    itemId={e._id}
                    id={atr.service + e._id}
                    service={atr.service}
                    serviceReadable={atr.readable}
                    defaultChecked={e[atr.service]}
                  />
                </span>
              );
            })}
          </span>

          <span className="flex flex-row py-4 justify-end">
            {allergies.map((allergy) => {
              return (
                <span className="" key={allergy.service + e._id}>
                  <span className="">
                    {/* We are using "service" and "readable" to accord with the naming convention above */}
                    <MenuCheckbox
                      id={allergy.service + e._id}
                      itemId={e._id}
                      service={allergy.service}
                      serviceReadable={allergy.readable}
                      defaultChecked={e[allergy.service]}
                    />
                  </span>
                </span>
              );
            })}
          </span>
        </span>
        <span
          className="right-8 my-auto material-symbols-outlined mx-6"
          onClick={() => {
            menuItem(e);
            edit(true);
            openModal(true);
          }}
        >
          edit
        </span>
        <hr />
      </div>
    );
  });
};

export default MainMenuList;
