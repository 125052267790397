import React, { useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { pathToPageName } from "../constants";
import { navLinks } from "../constants";
import { capitalizeWords, getPageTitle } from "../utils/functions";

const NavBar = ({ schoolId, schoolName }) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const [isloggedIn] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const location = useLocation();
  const currentPage = pathToPageName[location.pathname];

  // Leaving these in case we want to use them later
  const inActive =
    "px-3 py-2 flex items-center leading-snug text-white hover:opacity-75";
  const active =
    "px-3 py-2 flex items-center leading-snug text-white hover:opacity-75 underline";

  const handleClick = (e) => {
    setOpenDropdown(null);
    if (isNaN(schoolId)) {
      e.preventDefault();
      alert("Please select a location");
      history.push("/locations"); // Redirect to /locations
    }
  };

  const handleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  return (
    <nav className="relative">
      <div className="flex bg-gpsBrown">
        <div className="flex flex-shrink my-auto">
          <img
            src="/logo512.png"
            className="flex-shrink w-16 ml-2"
            alt="gps-logo"
          />
        </div>
        <div className="flex-1 flex flex-col mx-auto flex flex-wrap items-center justify-between py-1 mr-4">
          <div className="container flex-1 mx-auto text-left text-white text-2xl py-4">
            {getPageTitle(currentPage, schoolName)}
          </div>
          <div className="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start text-white text-2xl"></div>

          <ul className="flex flex-col lg:flex-row list-none ml-auto">
            <li className="nav-item">
              <NavLink
                to="/locations"
                className={inActive}
                activeClassName={active}
                onClick={handleClick}
              >
                Locations
              </NavLink>
            </li>

            {/*
             * Values for navLinks are defined in constants => Naviagation.js
             * Name of heading is the key and the values for each menu item an array of objects
             */}
            {Object.keys(navLinks).map((key) => (
              <li className="nav-item dropdown relative" key={key}>
                <button
                  onClick={() => handleDropdown(key)}
                  className={inActive}
                >
                  {capitalizeWords(key)}
                </button>
                <div
                  className={`dropdown-content absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden ${
                    openDropdown === key ? "block" : "hidden"
                  }`}
                >
                  {navLinks[key].map((link, index) => (
                    <NavLink
                      key={index}
                      to={link.path}
                      className={inActive}
                      activeClassName={active}
                      onClick={handleClick}
                    >
                      {link.label}
                    </NavLink>
                  ))}
                </div>
              </li>
            ))}

            <li className="nav-item">
              <NavLink className={inActive} activeClassName={active} to="/user">
                {isloggedIn ? `Hi ${user?.first_name}` : "Log in"}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
