const AllergyDisplay = ({ data }) => {
  if (!data || !data.students_v2_by_student_id) {
    return <div>N/A</div>;
  }

  const {
    students_v2_by_student_id: {
      dairy_allergy,
      gluten_allergy,
      nut_allergy,
      vegan,
      vegetarian,
    },
  } = data;

  return (
    <div className="w-full flex-col text-red-500">
      {nut_allergy && <div>Nut allergy</div>}
      {dairy_allergy && <div>Dairy allergy</div>}
      {gluten_allergy && <div>Gluten Allergy</div>}
      {/* {vegetarian && <div>Vegetarian</div>}
      {vegan && <div>Vegan</div>} */}
    </div>
  );
};

export default AllergyDisplay;
