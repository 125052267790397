import React, { useState, useEffect } from "react";
import api from "../Api";
import Checkbox from "./Checkbox";
import Input from "./Input";
import { validateItem } from "../utils/validations";
import ButtonTwo from "./ButtonTwo";

const AddMenuItem = ({ openModal, franchiseId, id }) => {
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [breakfast, setBreakfast] = useState(false);
  const [nutrition, setNutrition] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [afterSchool, setAfterSchool] = useState(false);
  const [active, setActive] = useState(false);
  const [containsDairy, setContainsDairy] = useState(false);
  const [containsNuts, setContainsNuts] = useState(false);
  const [containsGluten, setContainsGluten] = useState(false);
  const [vegetarian, setVegetarian] = useState(false);
  const [vegan, setVegan] = useState(false);

  useEffect(() => {
    console.log(`State of modal ${openModal}`);
  }, [openModal]);

  // const handleAddItem = () => {
  //   !validatePrice(itemPrice)
  //     ? alert("Please enter numbers and decimal point only")
  //     : itemPrice > 20
  //     ? alert("This seems expensive!")
  //     : itemName.length < 3
  //     ? alert("Please enter a product name of at least 3 letters")
  //     : addItem(itemName, itemPrice);
  // };

  // const validateItem = (itemName, itemPrice) => {
  //   const isNameValid = itemName.length >= 3;
  //   const isPriceValid = /^(\d*\.)?\d+$/.test(itemPrice);
  //   const isPriceInRange = itemPrice <= 20;

  //   if (!isNameValid) {
  //     return "Please enter a product name of at least 3 letters";
  //   }

  //   if (!isPriceValid) {
  //     return "Please enter numbers and decimal point only";
  //   }

  //   if (!isPriceInRange) {
  //     return "This seems expensive!";
  //   }

  //   return null;
  // };

  const handleAddItem = () => {
    const validationError = validateItem(itemName, itemPrice);
    if (validationError) {
      alert(validationError);
    } else {
      addItem(itemName, itemPrice);
    }
  };

  const addItem = async () => {
    try {
      const result = await api.menus.addMenuItem(
        active,
        afterSchool,
        breakfast,
        containsDairy,
        containsNuts,
        itemPrice,
        itemName,
        lunch,
        nutrition,
        franchiseId,
        containsGluten,
        vegetarian,
        vegan
      );
      console.log(`Result is ${result}`);
    } catch (err) {
      alert("Sorry, that wasn't possible");
    } finally {
      openModal(false);
    }
  };

  return (
    <>
      <form>
        <div className="mb-3 pt-0 w-full">
          <Input
            id="item-name"
            placeholder={"Item Name"}
            val={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
          <Input
            id="item-price"
            placeholder={"Item Price"}
            val={itemPrice}
            onChange={(e) => setItemPrice(e.target.value)}
          />

          <div className="w-full flex-wrap flex-row items-center mt-4">
            <Checkbox
              id="breakfast"
              value={breakfast}
              label={"Breakfast"}
              action={setBreakfast}
            />
            <Checkbox
              id="nutrition"
              value={nutrition}
              label={"Nutrition"}
              action={setNutrition}
            />
            <Checkbox
              id="lunch"
              value={lunch}
              label={"Lunch"}
              action={setLunch}
            />
            <Checkbox
              id="after-school"
              value={afterSchool}
              label={"After School"}
              action={setAfterSchool}
            />
            <Checkbox
              id="available"
              value={active}
              label={"Available"}
              action={setActive}
            />
          </div>
          <div className="w-full flex-wrap flex-row, items-start mt-4">
            <Checkbox
              id="contains-dairy"
              value={containsDairy}
              label={"Contains Dairy"}
              action={setContainsDairy}
            />
            <Checkbox
              id="contains-nuts"
              value={containsNuts}
              label={"Contains Nuts"}
              action={setContainsNuts}
            />
            <Checkbox
              id="contains-gluten"
              value={containsGluten}
              label={"Contains Gluten"}
              action={setContainsGluten}
            />
            <Checkbox
              id="vegetarian"
              value={vegetarian}
              label={"Vegetarian"}
              action={setVegetarian}
            />
            <Checkbox
              id="vegan"
              value={vegan}
              label={"Vegan"}
              action={setVegan}
            />
          </div>
          <div className="w-full flex flex-col items-center mt-8">
            <ButtonTwo title="Save" onClick={() => handleAddItem()} />
          </div>
        </div>
      </form>
    </>
  );
};
export default AddMenuItem;
