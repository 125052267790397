import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";

const menus = {
  getMasterMenu: async (franchiseId, filter) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/menu_items?order=title%20ASC%20&filter=(franchise_id=${franchiseId})${filter}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  updateService: async (id, payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/menu_items/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      payload
    );
    return response;
  },

  addMenuItem: async (
    active,
    after_school,
    breakfast,
    contains_dairy,
    contains_nuts,
    price,
    title,
    lunch,
    nutrition,
    franchise_id,
    contains_gluten,
    vegetarian,
    vegan
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.post(
      `${API_BASE_URL}/mysql/_table/menu_items?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        resource: [
          {
            active,
            after_school,
            breakfast,
            contains_dairy,
            contains_nuts,
            price,
            title,
            lunch,
            nutrition,
            franchise_id,
            contains_gluten,
            vegetarian,
            vegan,
          },
        ],
      }
    );
    return response;
  },

  editMenu: async (id, title, price) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.patch(
      `${API_BASE_URL}/mysql/_table/menu_items/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { title, price }
    );
    return response;
  },

  deleteItem: async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.delete(
      `${API_BASE_URL}/mysql/_table/menu_items/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },

  getSchoolMenu: async (
    schoolId,
    service,
    archived,
    preOrder,
    orderPOS,
    franchiseId
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/school_menu?franchise_id=${franchiseId}&related=menu_item${orderPOS}&filter=(school_id=${schoolId})AND(service=${service})${preOrder}${archived}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  // getSchoolMenu: async (schoolId, service, archived, preOrder) => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const { data } = await axios.get(
  //     `${API_BASE_URL}/mysql/_proc/school_menu?wrapper=resource&school_id=${schoolId}&service=${service}&archived=${archived}&pre_order=${preOrder}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
  //   );
  //   return data;
  // },

  setSchoolMenu: async (
    school_id,
    menu_item_id,
    service,
    price,
    start_date,
    end_date,
    short_name,
    pos_position,
    pre_order
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.post(
      `${API_BASE_URL}/add_to_school_menu_v2?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        resource: [
          {
            school_id,
            menu_item_id,
            service,
            price,
            start_date,
            end_date,
            short_name,
            pos_position,
            pre_order,
          },
        ],
      }
    );
    return response;
  },

  updateSchoolMenu: async (
    id,
    school_id,
    service,
    price,
    start_date,
    end_date,
    short_name,
    pos_position,
    archive,
    pre_order
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.patch(
      `${API_BASE_URL}/add_to_school_menu_v2?id=${id}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        school_id,
        service,
        price,
        start_date,
        end_date,
        short_name,
        pos_position,
        archive,
        pre_order,
      }
    );
    return response;
  },

  deleteSchoolMenu: async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.delete(
      `${API_BASE_URL}/mysql/_table/school_menu/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },
};

export default menus;
