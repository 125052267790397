import React from "react";
import ReactLoading from "react-loading";

const Loading = () => (
  <div className="flex items-center justify-center w-screen h-screen z-10">
    <ReactLoading type={"bubbles"} color={"#EA7C04"} height={300} width={300} />
  </div>
);

export default Loading;
