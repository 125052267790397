import React, { useState, useEffect } from "react";
import api from "../Api";

const SquareIndicator = ({ onClick, schoolId }) => {
  const [accountStatus, setAccountStatus] = useState(false);
  const [locationId, setLocationId] = useState("");

  // CHECK! What if the account is connected but the token has expired?
  const getAccountStatus = async () => {
    try {
      const result = await api.auth.tokenStatus(schoolId);
      setLocationId(result.data.location_id);
      setAccountStatus(true);
    } catch (error) {
      setAccountStatus(false);
    }
  };

  useEffect(() => {
    getAccountStatus();
  }, [schoolId]);

  return (
    <div className="flex-1 flex flex-grow justify-center align-middle cursor-pointer">
      <button
        onClick={() => {
          onClick();
        }}
      >
        {accountStatus ? (
          <>
            <img
              src="/Square_LogoLockup_Black.png"
              className="max-w-12 max-h-12 mx-5"
              alt="Connect Square"
            />
            <span className="text-xs">{locationId}</span>
          </>
        ) : (
          <span className="text-xs text-white py-1 px-2 rounded bg-blue-500">
            Connect to Square
          </span>
        )}
      </button>
    </div>
  );
};

export default SquareIndicator;
