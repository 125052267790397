import React, { useEffect, useState, useCallback } from "react";
import api from "../Api";
import MainLayout from "../layout/Main";
import {
  PosSalesList,
  PosSalesListHeaders,
} from "../components/SalesPosByTypeList";
import DateManager from "../utils/convertDate";
import DateRangePicker from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import { csvHeadersPOSSalesByType as csvHeaders } from "../constants";
import ButtonTwo from "../components/ButtonTwo";

// url/pos-sales
// Menu => Sales Reports => POS Sales By Item and Date

const PosSales = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const schoolId = school?.schoolId;
  const [sales, setSales] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchSales = async () => {
    setError("");
    try {
      const result = await api.reports.posSales(
        schoolId,
        DateManager.formatDateForMysql(startDate),
        DateManager.formatDateForMysql(endDate)
      );
      if (!result.resource || result.resource.length === 0) {
        return setError("No POS sales found for this school.");
      }
      setSales(result.resource);
    } catch (err) {
      setError(`Error fetching sales data: ${err}`);
    }
  };

  useEffect(() => {
    fetchSales();
  }, [startDate, endDate, schoolId]);

  // Add a total at the bas
  const calculateTotal = useCallback(() => {
    return sales.reduce((acc, curr) => {
      return Number(acc) + Number(curr.total_value);
    }, 0);
  }, [sales]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  return (
    <MainLayout
      rightContainer={
        <div className="w-full text-right pr-6">
          <CSVLink data={sales} headers={csvHeaders}>
            <ButtonTwo title="Download" />
          </CSVLink>
        </div>
      }
      leftContainer={
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      }
      headers={<PosSalesListHeaders />}
      content={
        error ? (
          <div className="w-full text-lg text-center mt-16">{error}</div>
        ) : (
          <>
            <div className="mb-16">
              <PosSalesList data={sales} />
              <div className="w-full text-right pr-6 text-xl bg-gray-200 py-4">
                Total for date range:{" "}
                {calculateTotal().toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            </div>
          </>
        )
      }
    />
  );
};

export default PosSales;
