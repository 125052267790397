import React from "react";

function ListElement({ e, index, name, handleAction }) {
  return (
    <span
      // Hide all but name and connect to Square indicator for default main location
      className={`flex-1 ${index === 0 && "opacity-0"} `}
      onClick={() => handleAction(e, "goToOrders")}
    >
      {name}
    </span>
  );
}

export default ListElement;
