import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatDate from "../utils/helper";
import { printOrders } from "../utils/printing";
import { serviceOptions } from "../constants";

const Labels = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const [schoolId] = useState(school.schoolId);
  const [startDate, setStartDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [service, setService] = useState("breakfast");
  const history = useHistory();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderList = await api.orders.studentLabels(
          schoolId,
          formatDate(startDate),
          service
        );
        setOrders(orderList.resource);
      } catch (err) {
        alert(
          `Your session has expired.  Please close this message and login. ${err}`
        );
        localStorage.removeItem("user");
        history.push("/login");
      }
    };
    fetchOrders();
  }, [startDate, service, history, schoolId]);

  return (
    <>
      <div className="fixed block top-0 w-full bg-white z-10">
        <div className="w-full px-12 bg-white">
          <div className="flex flex-row my-3">
            <span
              className="flex-1 text-gray-400 my-3 text-2xl hover:text-gray-700"
              onClick={() => history.push("/orders")}
            >
              Close
            </span>
            <span className="mt-4 px-3 text-xl">Date: </span>
            <span className="mt-4 flex-1">
              <DatePicker
                className="border border-solid border-gray-400 rounded w-32 text-xl"
                closeOnScroll={true}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </span>
            <span className="bg-white m-3 flex-1 text-lg">
              <label>
                Service:{" "}
                <select
                  className="border border-solid border-gray-400 rounded text-xl mt-1"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                >
                  {serviceOptions.map((option) => (
                    <option value={option.service}>{option.label}</option>
                  ))}
                </select>
              </label>
            </span>
            <span className="static w-32 my-3 flex-1">
              <span
                className="bg-blue-600 text-white active:bg-blue-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 px-3 text-white bg-blue-600 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm text-center float-right"
                onClick={printOrders}
              >
                Print
                <span />
              </span>
            </span>
          </div>
        </div>
        <div className="w-3/4 mx-auto" id="printable-div">
          <div className="flex flex-row mx-auto">
            {orders ? (
              orders.map((e) => {
                return (
                  <div
                    key={e._id}
                    className="flex flex-col h-60 m-2 p-3 w-1/3 border border-gray-200 rounded-lg text-lg"
                  >
                    <p className="">
                      <p className="mt-2">
                        {e.first_name} {e.last_name}
                      </p>
                      <span className="inline-block capitalize">
                        {e.service.replace("_", " ")}
                      </span>
                    </p>
                    <p className="w-full my-auto">{e.my_meal}</p>
                    <p className=""></p>
                    <p className="mt-auto">
                      <span className="inline-block w-1/2">{e.date}</span>
                      <span className="inline-block text-right w-1/2">
                        {e.grade}
                      </span>
                    </p>
                  </div>
                );
              })
            ) : (
              <>
                <div className="w-full text-lg text-center mt-16">
                  There are no orders for this date and service
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Labels;
