export const grades = [
  { label: "All", value: "All" },
  { label: "Unknown", value: "Unknown" },
  { label: "None", value: "None" },
  { label: "PK", value: "PK" },
  { label: "TK", value: "TK" },
  { label: "K", value: "K" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "Mod 1", value: "Mod 1" },
  { label: "Mod 2", value: "Mod 2" },
  { label: "P1", value: "P1" },
  { label: "P2", value: "P2" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
];

export const services = [
  {
    readable: "Breakfast",
    service: "breakfast",
  },
  {
    readable: "Nutrition",
    service: "nutrition",
  },
  {
    readable: "Lunch",
    service: "lunch",
  },
  {
    readable: "After School",
    service: "after_school",
  },
  {
    readable: "Active",
    service: "active",
  },
];

export const options = [
  { label: "All", value: "%20" },
  { label: "Archived", value: "AND(archive=true)" },
  { label: "Live", value: "AND(archive=false)" },
];

export const activeOptions = [
  { label: "All", value: "all" },
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

export const positions = [
  { label: "Exclude", value: 100 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
];

// N.B. If these are changed, they must also be changed in the mobile app
export const leadTime = [
  { label: "7:00pm on day of service", value: 19 },
  { label: "6:00pm on day of service", value: 18 },
  { label: "5:00pm on day of service", value: 17 },
  { label: "4:00pm on day of service", value: 16 },
  { label: "3:00pm on day of service", value: 15 },
  { label: "2:00pm on day of service", value: 14 },
  { label: "1:00pm on day of service", value: 13 },
  { label: "12:00 noon on day of service", value: 12 },
  { label: "11:00am on day of service", value: 11 },
  { label: "10:00am on day of service", value: 10 },
  { label: "9:00am on day of service", value: 9 },
  { label: "8:00am on day of service", value: 8 },
  { label: "7:00am on day of service", value: 7 },
  { label: "6:00am on day of service", value: 6 },
  { label: "5:00am on day of service", value: 5 },
  // N.B. If these are changed, they must also be changed in the mobile app
  { label: "12:00 midnight", value: 0 },
  { label: "11:00pm of previous day", value: -1 },
  { label: "10:00pm of previous day", value: -2 },
  { label: "9:00pm of previous day", value: -3 },
  { label: "8:00pm of previous day", value: -4 },
  { label: "7:00pm of previous day", value: -5 },
  { label: "6:00pm of previous day", value: -6 },
  { label: "5:00pm of previous day", value: -7 },
  { label: "4:00pm of previous day", value: -8 },
  { label: "3:00pm of previous day", value: -9 },
  { label: "2:00pm of previous day", value: -10 },
  { label: "1:00pm of previous day", value: -11 },
  { label: "12:00 noon of previous day", value: -12 },
  // N.B. If these are changed, they must also be changed in the mobile app
  { label: "11:00am of previous day", value: -13 },
  { label: "10:00am of previous day", value: -14 },
  { label: "9:00am of previous day", value: -15 },
  { label: "8:00am of previous day", value: -16 },
  { label: "7:00am of previous day", value: -17 },
  { label: "6:00am of previous day", value: -18 },
  { label: "5:00am of previous day", value: -19 },
  { label: "4:00am of previous day", value: -20 },
  { label: "3:00am of previous day", value: -21 },
  { label: "2:00am of previous day", value: -22 },
  { label: "1:00am of previous day", value: -23 },
  // N.B. If these are changed, they must also be changed in the mobile app
  { label: "1 day prior to day of service", value: -24 },
  { label: "2 days prior to day of service", value: -48 },
  { label: "3 days prior to day of service", value: -72 },
  { label: "4 days prior to day of service", value: -96 },
  { label: "5 days prior to day of service", value: -120 },
  { label: "6 days prior to day of service", value: -144 },
  { label: "7 days prior to day of service", value: -168 },
  { label: "1 week prior to week of service", value: 1000 },
  { label: "2 weeks prior to week of service", value: 2000 },
  { label: "3 weeks prior to week of service", value: 3000 },
  { label: "1 month prior to month of service", value: 4000 },
];

export const preorder = [
  { label: "True", val: true },
  { label: "False", val: false },
];

export const preOrderFilter = [
  { label: "All", value: "" },
  { label: "Yes", value: "AND(pre_order=true)&" },
  { label: "No", value: "AND(pre_order=false)&" },
];

export const serviceOptions = [
  { label: "Breakfast", value: "breakfast" },
  { label: "Nutrition", value: "nutrition" },
  { label: "Lunch", value: "lunch" },
  { label: "After School", value: "after_school" },
];

// Using "service" and "readable" to match "services" above
export const allergies = [
  { readable: "Contains Dairy", service: "contains_dairy" },
  { readable: "Contains Peanuts", service: "contains_nuts" },
  { readable: "Contains Gluten", service: "contains_gluten" },
  { readable: "Vegetarian", service: "vegetarian" },
  { readable: "Vegan", service: "vegan" },
];

export const max = [
  { label: "No Limit", value: 9999 },
  { label: "$5.00", value: 5 },
  { label: "$7.50", value: 7.5 },
  { label: "$10.00", value: 10 },
  { label: "$12.50", value: 12.5 },
  { label: "$15.00", value: 15 },
  { label: "$20.00", value: 20 },
];

export const menuItems = [
  { label: "Breakfast", serviceTime: "breakfast" },
  { label: "Nutrition", serviceTime: "nutrition" },
  { label: "Lunch", serviceTime: "lunch" },
  { label: "Afternoon", serviceTime: "after_school" },
];

export const stipendFilterValues = [
  { label: "All", value: "" },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const adjustmentReasons = [
  { label: "Opening Balance", value: "Opening Balance" },
  { label: "Closing Balance", value: "Closing Balance" },
  { label: "Admin Credit", value: "Admin Credit" },
  { label: "Admin Debit", value: "Admin Debit" },
  { label: "Admin Refund", value: "Admin Refund" },
  { label: "Stipend", value: "Stipend" },
  { label: "Other", value: "Other" },
];
