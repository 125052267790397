import React, { useEffect, useState } from "react";
import OpeningBalanceInput from "./OpeningBalanceInput";
import api from "../Api";
import { FamilyTransactionList } from "./FamilyTransactionList";

// In menu under Customer Reports => Adjust Balances  url/families

export const FamilyList = ({
  familyList,
  fetchFamilies,
  franchiseId,
  loading,
}) => {
  // const [familyTransactions, setFamilyTransactions] = useState([]);
  // const [familyId, setFamilyId] = useState("");
  // const [error, setError] = useState("");

  // const fetchFamilyTransactions = async () => {
  //   setError("");
  //   try {
  //     const transactions = await api.reports.familyTransactions(familyId);
  //     if (!transactions.resource || transactions.resource.length === 0) {
  //       return setError("No data found for this student.");
  //     }
  //     setFamilyTransactions(transactions.resource);
  //     console.log(transactions);
  //   } catch (err) {
  //     setError(`Error fetching family transactions: ${err}`);
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   if (familyId) {
  //     fetchFamilyTransactions();
  //   }
  // }, [familyId]);

  return familyList.map((family) => {
    return (
      <div className="text-lg px-6 even:bg-gray-50" key={family._id}>
        <div className="flex">
          <span className="flex-1 my-auto">{family.name}</span>
          <span className="flex-1 my-auto">{family.email}</span>
          <span className="flex-1 my-auto text-right">{family.balance}</span>
          <span className="flex-1 my-auto text-center cursor-pointer text-blue-500"></span>
          <span className="flex-1 my-auto">
            <OpeningBalanceInput
              id={`opening-balance-${family._id}`}
              value={family.balance || ""}
              familyId={family._id}
              fetchFamilies={fetchFamilies}
              franchiseId={franchiseId}
              loading={loading}
            />
          </span>
        </div>
      </div>
    );
  });
};

export const FamilyHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Name</span>
      <span className="flex-1">Email</span>
      <span className="flex-1 text-right">Current Balance $</span>
      <span className="flex-1 text-right"></span>
      <span className="flex-1 text-center">Adjust Balance $ {}</span>
    </div>
  );
};
