import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import MainLayout from "../layout/Main";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import { FamilyListWithChildren } from "../components/FamilyListWithChildren";
import { FamilyListWithChildrenHeaders } from "../components/FamilyWithStudentsHeaders";
import api from "../Api";
import Loading from "../components/Loading";

const FamiliesWithChildren = () => {
  const franchiseId = localStorage.getItem("franchiseId");
  const [families, setFamilies] = useState([]);
  const [search, setSearch] = useState("");
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [currentFamilies, setCurrentFamilies] = useState([]);
  const pageCount = Math.ceil(families.length / itemsPerPage);
  const [forcePage, setForcePage] = useState(0);
  const [filter, setFilter] = useState("");
  const cancelTokenSource = useRef(axios.CancelToken.source());
  const [loading, setLoading] = useState(false);

  /*
   * N.B. We are searching for the parents email or name, but the email is the one from the family table
   * which is not reliable if data has been changed.  Keep this in mind if updating data manually.
   * We should update to a procedure that returns the email from the user table, since it can be updated.
   */

  const fetchFamilies = async () => {
    // Preventing race conditions by adding a cancel token
    setLoading(true);
    try {
      const familyList = await api.students.getFamiliesWithChildren(
        franchiseId,
        filter,
        cancelTokenSource.current
      );
      if (typeof familyList === "undefined" || !familyList.resource) {
        // return alert(`No families found for ${filter}`);
      }
      if (
        Array.isArray(familyList.resource) &&
        familyList.resource.length === 0
      ) {
        // return alert(`No families found for ${filter}`);
      }
      setFamilies(familyList.resource);
    } catch (err) {
      if (axios.isCancel(err)) return;
      console.log(`Error is ${err}`);
      alert(`Error fetching families: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(`filter is ${filter}`);
    fetchFamilies();
  }, [filter]);

  useEffect(() => {
    if (search.length > 2) {
      setFilter(
        `AND((name%20like%20%25${search.trim()}%25)OR(email%20like%20%25${search.trim()}%25))`
      );
    } else if (search.length === 0) {
      setFilter("");
    }
  }, [search]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (families.length > 0) {
      setCurrentFamilies(families.slice(itemOffset, endOffset));
    }
  }, [families, itemOffset]);

  const handlePaginationClick = (event) => {
    if (!families || !Array.isArray(families) || families.length === 0) {
      return;
    }
    const newOffset = (event.selected * itemsPerPage) % families.length;
    setItemOffset(newOffset);
  };

  return (
    <MainLayout
      pageTitle="Families"
      rightContainer={<></>}
      leftContainer={
        <Search
          id="search"
          placeholder={"Search"}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      }
      headers={<FamilyListWithChildrenHeaders />}
      content={
        !loading ? (
          families.length > 0 ? (
            <>
              <div className="mb-16">
                <FamilyListWithChildren
                  data={currentFamilies}
                  fetchFamilies={fetchFamilies}
                />
              </div>
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePaginationClick}
                forcePage={forcePage}
              />
            </>
          ) : (
            <div className="w-full text-lg text-center mt-16">
              No families found
            </div>
          )
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default FamiliesWithChildren;
