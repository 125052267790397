import React from "react";

const NotFound = () => {
  return (
    <div className="text-center mt-16 text-xl">
      <h1>404</h1>
      <p className="mb-32">Sorry! Page Not Found</p>
      <button
        className="bg-gpsRed text-white px-4 py-2 rounded"
        onClick={() => window.history.back()}
      >
        Go Back
      </button>
      <iframe
        src="https://giphy.com/embed/2olWl39PDPSNi"
        width="480"
        height="480"
        className="giphy-embed"
        allowFullScreen
        style={{ margin: "0 auto", display: "block" }} // Inline CSS to center the iframe
      ></iframe>
      <div>
        <a href="https://giphy.com/gifs/satisfying-way-plate-2olWl39PDPSNi"></a>
      </div>
    </div>
  );
};

export default NotFound;
