import React, { useEffect, useState } from "react";
import api from "../Api";
import MainLayout from "../layout/Main";
import { SalesList, SalesListHeaders } from "../components/TempCashSalesList";
import { CSVLink } from "react-csv";
import { csvHeadersTempCashSales as csvHeaders } from "../constants";
import ButtonTwo from "../components/ButtonTwo";

const TempCashSales = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const schoolId = school?.schoolId;
  const [balancesByDay, setBalancesByDay] = useState([]);
  const [error, setError] = useState("");

  const fetchBalances = async () => {
    setError("");
    try {
      const balances = await api.reports.tempCashBalances(schoolId);
      if (!balances.resource || balances.resource.length === 0) {
        return setError("No data found for this school.");
      }
      setBalancesByDay(balances.resource);
    } catch (err) {
      setError(`Error fetching families: ${err}`);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  return (
    <MainLayout
      rightContainer={
        <div className="w-full text-right pr-6">
          <CSVLink data={balancesByDay} headers={csvHeaders}>
            <ButtonTwo title="Download" />
          </CSVLink>
        </div>
      }
      leftContainer={null}
      headers={<SalesListHeaders />}
      content={
        error ? (
          <div className="w-full text-lg text-center mt-16">{error}</div>
        ) : (
          <>
            <div className="mb-16">
              <SalesList data={balancesByDay} />
            </div>
          </>
        )
      }
    />
  );
};

export default TempCashSales;
