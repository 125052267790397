import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// Layout
import MainLayout from "../layout/Main";

// API
import api from "../Api";

// Components
import DateRangePicker from "../components/DateRangePicker";
import {
  SalesByItemList,
  SalesByItemHeaders,
} from "../components/SalesByItemList";
import ButtonTwo from "../components/ButtonTwo";
import Graph from "../components/Graph";

// Utilities
import DateManager from "../utils/convertDate";

// Constants
import { csvHeadersSalesByItem } from "../constants";

// url/sales-by-item
// Menu => Customer Reports => Pre-order Sales By Item and Date

const SalesByItem = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [salesByItem, setSalesByItem] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [error, setError] = useState(null);

  const fetchSalesByItem = async () => {
    try {
      const salesByItem = await api.reports.getSalesByItem(
        schoolId,
        DateManager.formatDateForMysql(startDate),
        DateManager.formatDateForMysql(endDate)
      );
      if (typeof salesByItem === "undefined" || !salesByItem.resource) {
        return setError(`No pre-order sales found for ${schoolName}`);
      }
      setSalesByItem(salesByItem.resource);
    } catch (err) {
      setError(`Error fetching sales by item: ${err}`);
    }
  };

  const calculateGrandTotal = (data) => {
    return data
      .reduce((acc, item) => {
        return acc + parseFloat(item.total);
      }, 0)
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  };

  useEffect(() => {
    setGrandTotal(calculateGrandTotal(salesByItem));
  }, [salesByItem]);

  useEffect(() => {
    fetchSalesByItem();
  }, [schoolId, startDate, endDate]);

  // // Aggregate data for graph to give total sales per day, not by item type
  // const aggregatedData = salesByItem.reduce((acc, item) => {
  //   const date = item.date;
  //   const total = parseFloat(item.total);

  //   acc[date] = (acc[date] || 0) + total;

  //   return acc;
  // }, {});

  // // Convert aggregated data to array of objects
  // const chartData = Object.keys(aggregatedData).map((date) => ({
  //   date: DateManager.USDate(date),
  //   total: aggregatedData[date],
  // }));

  return (
    <MainLayout
      pageTitle="Sales By Item"
      rightContainer={
        <div className="w-full text-right pr-6">
          <CSVLink data={salesByItem} headers={csvHeadersSalesByItem}>
            <ButtonTwo title="Download" />
          </CSVLink>
        </div>
      }
      leftContainer={
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      }
      headers={<SalesByItemHeaders />}
      content={
        <>
          <SalesByItemList data={salesByItem} />
          {error && <div className="mt-16 text-center text-xl">{error}</div>}
          <div className="text-right pr-6 text-xl mt-4 mb-16">
            {grandTotal > 0 ? `$${grandTotal}` : ""}
          </div>
          {/* <Graph data={chartData} /> */}
        </>
      }
    />
  );
};

export default SalesByItem;
