import React from "react";

const Sort = ({ sortDirection, onClick }) => {
  const symbol = () => {
    switch (sortDirection) {
      case "ASC":
        return "arrow_upward";
      case "DESC":
        return "arrow_downward";
      case "":
        return "swap_vert";
      default:
        return "swap_vert";
    }
  };
  return (
    <span
      className="material-symbols-outlined px-1 align-bottom"
      onClick={onClick}
    >
      {symbol()}
    </span>
  );
};

export default Sort;
