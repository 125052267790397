import React from "react";

const ButtonCancel = ({ title, onClick }) => {
  return (
    <div className="flex flex-col items-center p-6 border-t border-solid border-slate-200 rounded-b w-full mt-8">
      <button
        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  );
};

export default ButtonCancel;
