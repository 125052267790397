import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";

const schoolInfo = {
  getList: async (franchiseId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/schools?filter=franchise_id=${franchiseId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  addSchool: async (
    name,
    code,
    student_credit_limit,
    last_order_time,
    franchise_id
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.post(
      `${API_BASE_URL}/mysql/_table/schools?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        resource: [
          { name, code, student_credit_limit, last_order_time, franchise_id },
        ],
      }
    );
    return response;
  },

  updateSchool: async (id, student_credit_limit, last_order_time) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/schools/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { student_credit_limit, last_order_time }
    );
    return response;
  },

  deleteSchool: async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.delete(
      `${API_BASE_URL}/mysql/_table/schools/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },
};

export default schoolInfo;
