import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar";
import api from "../Api";
import ServiceSelector from "../components/ServiceSelector";
import SelectMenu from "../components/MenuSelectFromMaster";
import EditMenuItem from "../components/EditLocationMenuItem";
import DateManager from "../utils/convertDate";
import { EditableObject } from "../utils/classes";
import { options, preOrderFilter } from "../constants";
import Sort from "../components/Sort";
import { handlePosOrder } from "../utils/sortOrders";
import Dropdown from "../components/Dropdown";
import HeaderButton from "../components/HeaderButton";

const SchoolMenu = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const [franchiseId] = useState(localStorage.getItem("franchiseId"));
  const [openModal, setOpenModal] = useState(false);
  const [menu, setMenu] = useState([]);
  const [service, setService] = useState("lunch");
  const [newMenuItem, setNewMenuItem] = useState({});
  const [openEditItem, setOpenEditItem] = useState(false);
  const [editOnly, setEditOnly] = useState(false);
  const [archived, setArchived] = useState("%20");
  const [preOrder, setPreOrder] = useState("");
  const [posOrder, setPosOrder] = useState("");
  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const fetchSchoolMenu = async () => {
      const orderPOS = posOrder ? `&order=pos_position%20${posOrder}%20` : "";
      try {
        const menu = await api.menus.getSchoolMenu(
          schoolId,
          service,
          archived,
          preOrder,
          orderPOS,
          franchiseId
        );
        setMenu(menu.resource);
      } catch (err) {
        console.log(err);
        alert(err.response?.data?.error?.message);
      }
    };
    if (!openModal && !openEditItem) {
      fetchSchoolMenu();
    }
  }, [
    openModal,
    service,
    openEditItem,
    archived,
    preOrder,
    posOrder,
    franchiseId,
    schoolId,
    schoolName,
  ]);

  return (
    <>
      <div className="sticky top-0 w-full bg-white z-10">
        <div>
          <NavBar
            title={"Master Menu"}
            schoolId={schoolId}
            schoolName={schoolName}
          />
        </div>
        <div className="w-full px-4 bg-white">
          <span className="flex my-2">
            <span className="flex-1 pt-5 text-xl text-gray-700 text-2xl">
              <div className=" flex-1 my-auto"></div>
            </span>
            <span className="bg-white pt-5 flex-2 text-xl h-16"></span>
            <span className="flex-1 my-auto bg-white w-full text-right">
              <HeaderButton
                title="Add Item"
                onClick={() => {
                  setOpenModal(true);
                  setEditOnly(false);
                }}
              />
            </span>
          </span>
        </div>
        <div className="w-full bg-gray-100 h-12 px-6 py-3">
          <ServiceSelector
            className="w-1/2"
            service={service}
            updateService={setService}
          />
          <span className="absolute right-16 text-xl">
            <Dropdown
              id="status"
              title={"Status"}
              data={options}
              onChange={(e) => setArchived(e.target.value)}
            />
          </span>
        </div>
        <div className="w-full flex flex-row text-xl py-4 px-16 bg-gray-300">
          <span className="inline-block flex-1">Item</span>
          <span className="inline-flex flex-1 pl-4">From</span>
          <span className="inline-flex flex-1">To</span>
          <span className="inline-flex flex-1">
            <Sort
              sortDirection={posOrder}
              onClick={() => handlePosOrder(posOrder, setPosOrder)}
            />
            POS Button #
          </span>
          <span className="inline-flex flex-1">
            <Dropdown
              id="pre-order"
              title={"Pre-order?"}
              data={preOrderFilter}
              onChange={(e) => setPreOrder(e.target.value)}
            />
          </span>
          <span className="inline-flex flex-shrink pr-14">Price</span>
          <span className="absolute right-8 text-right">Edit</span>
        </div>
        {openModal && (
          <div>
            <SelectMenu
              schoolId={schoolId}
              schoolName={schoolName}
              service={service}
              openModal={setOpenModal}
              newMenuItem={setNewMenuItem}
              openEditItem={setOpenEditItem}
            />
          </div>
        )}
        {openEditItem && (
          <div className="">
            <EditMenuItem
              openEditItem={setOpenEditItem}
              menuItem={newMenuItem}
              service={service}
              schoolId={schoolId}
              editOnly={editOnly}
            />
          </div>
        )}
      </div>
      <div className="relative">
        {menu &&
          menu
            .sort((a, b) => a.menu_item.title.localeCompare(b.menu_item.title)) // Sort the menu items alphabetically
            .map((e) => {
              return (
                <div key={e._id} className={null}>
                  <div
                    className={`flex flex-row w-full text-xl py-10 px-16 ${
                      e.archive && "text-gray-400"
                    }`}
                  >
                    <span className="inline-flex flex-1 flex flex-col line">
                      <p className="flex-1">{e.menu_item.title}</p>
                      <p className="flex-1 text-xs text-gray-400 mt-1">
                        POS Short Name
                        {e.short_name ? ` ${e.short_name}` : " not set"}
                      </p>
                      <div className="flex-1 text-xs text-red-400 mt-1">
                        <p>
                          {e.menu_item.contains_dairy && "Contains Dairy "}
                          {e.menu_item.contains_nuts && "Contains Peanuts "}
                          {e.menu_item.contains_gluten && "Contains Gluten "}
                        </p>
                        <p className="flex-1 text-xs text-green-600 mt-1">
                          {e.menu_item.vegetarian && "Vegetarian "}
                          {e.menu_item.vegan && "Vegan "}
                        </p>{" "}
                      </div>
                    </span>
                    <span className="inline-flex flex-1 pl-4">
                      {DateManager.USDate(e.start_date)}
                    </span>
                    <div className="inline-flex flex-1 flex flex-col">
                      <p
                        className={`flex-1 ${
                          e.end_date < today && !e.archive
                            ? "text-red-400"
                            : null
                        }`}
                      >
                        {DateManager.USDate(e.end_date)}
                      </p>
                      <div className={`flex-1 ${!e.archive && "text-red-400"}`}>
                        {e.end_date < today && <p>Item has expired.</p>}
                      </div>
                    </div>
                    <div className="inline-flex flex-1 flex flex-col">
                      <p>
                        {e.pos_position === 100
                          ? "Not displayed"
                          : e.pos_position}
                      </p>
                    </div>
                    <span className="inline-flex flex-1">
                      {e.pre_order ? "Yes" : "No"}
                    </span>
                    <span className="inline-flex flex-shrink pr-12">
                      ${e.price.toFixed(2)}
                    </span>
                    <span
                      className="absolute material-symbols-outlined text-right right-8"
                      onClick={() => {
                        setOpenEditItem(true);
                        // Component editSchoolMenuItem.js takes the object from the master menu. This reconstructs that form for editing an item.
                        setNewMenuItem(new EditableObject(e));
                        setEditOnly(true);
                      }}
                    >
                      edit
                    </span>
                  </div>
                  <hr />
                </div>
              );
            })}
      </div>
    </>
  );
};

export default SchoolMenu;
