import React, { useEffect, useState } from "react";
import MainLayout from "../layout/Main";
import api from "../Api";
import Pagination from "../components/Pagination";
import {
  DuplicateStudentList,
  DuplicateStudentListHeaders,
} from "../components/DuplicateStudentList";

/*
 ** ShowDuplicates page finds students with same id and school with two family accounts.  Tap Debit column to see transactions for each account.
 ** Tap family ID to select where to move debits from, and then tap family id of destination account.
 ** To delete an account, tap the far left ID value, and then confirm after it turns red.
 ** N.B. This is for internal use only - there are few safeguards in place.
 */

const ShowDuplicates = () => {
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [studentDuplicates, setStudentDuplicates] = useState([]);
  const pageCount = Math.ceil(studentDuplicates.length / itemsPerPage);

  useEffect(() => {
    getDuplicateStudents();
  }, []);

  const getDuplicateStudents = async () => {
    try {
      const duplicateStudents = await api.reports.getDuplicateStudents();
      setStudentDuplicates(duplicateStudents.resource);
    } catch (err) {
      console.log(`Error is ${err}`);
    }
  };

  const handlePaginationClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const paginatedData = studentDuplicates.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  return (
    <MainLayout
      pageTitle="Show Duplicates"
      rightContainer={<></>}
      leftContainer={null}
      headers={<DuplicateStudentListHeaders />}
      content={
        paginatedData && (
          <>
            <div className="mb-16">
              <DuplicateStudentList
                data={paginatedData}
                refreshData={getDuplicateStudents}
              />
            </div>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePaginationClick}
            />
          </>
        )
      }
    />
  );
};

export default ShowDuplicates;
