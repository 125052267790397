import React, { useState } from "react";
import api from "../Api";
import { useCSVReader } from "react-papaparse";

const CSVReader = ({ openModal, csvUpload }) => {
  const school = JSON.parse(localStorage.getItem("school"));
  const [schoolId] = useState(school.schoolId);
  const [json, setJson] = useState({});
  const [btnColour, setBtnColour] = useState("bg-gray-200");
  const [disabled, setDisabled] = useState(true);
  const { CSVReader } = useCSVReader();

  const uploadCsv = async (payload) => {
    try {
      await api.students.uploadPreReg(payload);
      alert("File was uploaded successfully");
    } catch (err) {
      alert(
        `Sorry, that didn't work; please check you aren't duplicating a previous submission.  Error code ${err}`
      );
    } finally {
      setJson({});
      setDisabled(true);
      setBtnColour("bg-gray-200");
      openModal(false);
      csvUpload(false);
    }
  };

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        const [keys, ...values] = results.data;
        const result = values.map((a) =>
          a.reduce((a, v, i) => ({ ...a, [keys[i]]: v }), {})
        );
        const resultWithSchoolId = result.map((a) => ({
          ...a,
          school_id: schoolId,
        }));
        setJson(resultWithSchoolId);
        setDisabled(false);
        setBtnColour("bg-blue-600");
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <>
          <div className="flex flex-row mb-2">
            <button
              type="button"
              {...getRootProps()}
              className={
                "text-lg w-1/5 bg-blue-600 rounded mx-2 px-6 py-3 text-white"
              }
            >
              Select file
            </button>
            <div className="text-lg h-16 w-4/5 border border-slate-300 pl-4">
              {acceptedFile && acceptedFile.name}
            </div>

            <button
              type="button"
              {...getRemoveFileProps()}
              className="px-4 bg-gray-100 rounded mx-2 text-sm text-gray-600"
            >
              Remove
            </button>
          </div>

          <ProgressBar className="bg-red-700" />

          <div className="flex flex-col items-center">
            <button
              disabled={disabled}
              className={`${btnColour} text-white text-lg px-6 py-3 my-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
              onClick={() => uploadCsv(json)}
            >
              Upload File!
            </button>
          </div>
        </>
      )}
    </CSVReader>
  );
};

export default CSVReader;
