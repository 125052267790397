import React from "react";

const Search = ({ id, placeholder, onChange, onFocus }) => {
  return (
    <div className="flex-1 my-auto">
      <input
        id={id}
        type="text"
        placeholder={placeholder}
        className="px-2 py-3 placeholder-slate-800 text-slate-700 relative bg-white rounded text-lg border-0 shadow outline-none focus:outline-none focus:ring w-full"
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  );
};

export default Search;
