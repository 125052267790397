import React, { useEffect, useState, useMemo } from "react";
import api from "../Api";
import NavBar from "../components/Navbar";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../components/Modal";
import AddEditStudent from "../components/AddEditStudent";
import AddStudent from "../components/AddStudent";
import { grades, stipendFilterValues } from "../constants";
import { print } from "../utils/print";
import StudentList from "../components/StudentList";
import Search from "../components/Search";
import Dropdown from "../components/Dropdown";
import Pagination from "../components/Pagination";
import HeaderButton from "../components/HeaderButton";

// Customers page

const Students = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const [franchiseId] = useState(localStorage.getItem("franchiseId"));
  const [grade, setGrade] = useState("All");
  const [filter, setFilter] = useState("%20");
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [addStudent, setAddStudent] = useState(false);
  const [student, setStudent] = useState({});
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [forcePage, setForcePage] = useState(0);
  const currentStudents = useMemo(
    () => students.slice(itemOffset, endOffset),
    [students, itemOffset, endOffset]
  );
  const pageCount = useMemo(
    () => Math.ceil(students.length / itemsPerPage),
    [students.length, itemsPerPage]
  );
  const [stipend, setStipend] = useState("All");
  const [stipendFilter, setStipendFilter] = useState("");

  useEffect(() => {
    if (!openModal) {
      fetchStudents();
    }
  }, [filter, searchFilter, stipendFilter, openModal]);

  useEffect(() => {
    console.log(stipendFilter);
  }, [stipendFilter]);

  useEffect(() => {
    let filter = "";
    if (grade === "Unknown") {
      filter = `AND(grade IS NULL)`;
    } else if (grade !== "All") {
      filter = `AND(grade=${grade})`;
    }
    setFilter(filter);
  }, [grade]);

  useEffect(() => {
    if (search.length > 2) {
      setSearchFilter(`AND(last_name%20LIKE%20${search}%25)`);
    } else setSearchFilter("");
  }, [search]);

  useEffect(() => {
    if (stipend === "") {
      setStipendFilter("");
    } else if (stipend === "Yes") {
      setStipendFilter("AND(stipend=true)");
    } else if (stipend === "No") {
      setStipendFilter("AND(stipend=false)");
    }
  }, [stipend]);

  const fetchStudents = async () => {
    try {
      const studentList = await api.students.getStudents(
        schoolId,
        filter,
        searchFilter,
        stipendFilter,
        franchiseId
      );
      setStudents(studentList.resource);
    } catch (err) {
      alert(`Error fetching students: ${err}`);
    }
  };

  const handleService = (e) => {
    setGrade(e.target.value);
  };

  const addCustomer = () => {
    setAddStudent(true);
    setOpenModal(true);
  };

  const handlePaginationClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % students.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="sticky top-0 w-full bg-white z-10">
        <div>
          <NavBar
            title={"Students"}
            schoolId={schoolId}
            schoolName={schoolName}
          />
        </div>
        <div className="w-full px-4 bg-white h-16">
          <span className="flex my-1">
            <Search
              id={"search"}
              placeholder={"Search Last Name"}
              onFocus={() => {
                setItemOffset(0);
                setForcePage(null);
              }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <span className="w-32 flex-1 my-auto text-right content-end">
              <HeaderButton title="Print" onClick={print} />
              <HeaderButton
                title="Add Customer"
                onClick={() => addCustomer()}
              />
            </span>
          </span>
        </div>
        <div className="w-full bg-gray-300 text-black px-6 py-3">
          <span className="flex text-lg w-full">
            <span className="flex-1">First Name</span>
            <span className="flex-1">Last Name</span>
            <span className="flex-1">
              <Dropdown
                id={"grade"}
                title={"Service Assignment: "}
                data={grades}
                value={grade}
                onChange={handleService}
              />
            </span>
            <span className="flex-1 capitalize">Customer ID</span>
            <span className="flex-1 capitalize">
              <Dropdown
                id="stipend"
                title="Stipend: "
                data={stipendFilterValues}
                value={stipend}
                onChange={(e) => {
                  setStipend(e.target.value);
                }}
              />
            </span>
            <span className="flex-shrink text-right">Edit</span>
          </span>
        </div>
      </div>
      <div className="relative">
        <StudentList
          students={currentStudents}
          openModal={setOpenModal}
          addStudent={setAddStudent}
          student={setStudent}
        />
        <Pagination
          pageCount={pageCount}
          onPageChange={handlePaginationClick}
          forcePage={forcePage}
        />
        {openModal && (
          <Modal
            openModal={setOpenModal}
            title={
              addStudent
                ? "Add a customer"
                : `Edit ${student.first_name} ${student.last_name}`
            }
            content={
              addStudent ? (
                <AddStudent
                  openModal={setOpenModal}
                  addStudent={setAddStudent}
                />
              ) : (
                <AddEditStudent openModal={setOpenModal} student={student} />
              )
            }
          />
        )}
      </div>
    </>
  );
};
export default Students;
