import React, { useState } from "react";

const Checkbox = ({ label, value, action, id }) => {
  const [isChecked, setIsChecked] = useState(value);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    action(!isChecked);
  };

  return (
    <span className="inline-flex mt-2 flex">
      <label htmlFor={id} className="text-gray-900">
        {label}
        <input
          id={id}
          className="align-middle ml-2 mr-4 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          type="checkbox"
          value={isChecked}
          defaultChecked={isChecked}
          onChange={(e) => handleOnChange()}
        />
      </label>
    </span>
  );
};

export default Checkbox;
