import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <div className="flex flex-1 my-auto w-1/2 pl-6 p-2">
      <div className="flex-1">
        <span className="text-lg"> Start Date: </span>
        <DatePicker
          id="start-date-picker"
          startDate={startDate}
          className="rounded w-32 text-xl p-1 shadow"
          closeOnScroll={true}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Select a date"
          autoComplete="off"
        />
      </div>
      <div className="flex-1">
        <span className="text-lg"> End Date: </span>
        <DatePicker
          id="end-date-picker"
          startDate={endDate}
          className="rounded w-32 text-xl p-1 shadow"
          closeOnScroll={true}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="Select a date"
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
