import React, { useState } from "react";
import api from "../Api";
import Input from "./Input";
import { validatePrice } from "../utils/validations";
import ButtonTwo from "./ButtonTwo";

const EditMainMenu = ({ openModal, menuItem, deleteItem }) => {
  const [itemName, setItemName] = useState(menuItem.title);
  const [price, setPrice] = useState(menuItem.price || 0);

  const editItem = async () => {
    if (validatePrice(price)) {
      try {
        const result = await api.menus.editMenu(menuItem._id, itemName, price);
        console.log(`Result is ${result}`);
      } catch (err) {
        alert("Sorry, that wasn't possible");
      } finally {
        openModal(false);
      }
    } else {
      alert("Price should be numbers only!");
    }
  };

  return (
    <>
      <div className="">
        <form className="mb-3 pt-0 w-full">
          <Input
            id="item-name"
            placeholder={"Item Name"}
            val={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
          {/* <Input
            placeholder={"Price"}
            val={price}
            onChange={(e) => setPrice(e.target.value)}
          /> */}
          <span className="my-3 py-2 px-1 placeholder-slate-400 text-slate-600 bg-white rounded shadow outline-none focus:outline-none focus:ring">
            <label className="inline-block mt-4">
              <span className="pr-2">$</span>
              <input
                id="item-price"
                placeholder={"Price"}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>
          </span>
          <button
            className="block my-8 bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            onClick={() => deleteItem(menuItem._id)}
          >
            Delete
          </button>
          <div className="w-full flex flex-col items-center mt-4">
            <ButtonTwo title="Save" onClick={() => editItem()} />
          </div>
        </form>
      </div>
    </>
  );
};
export default EditMainMenu;
