import React, { useState } from "react";
import api from "../Api";

export const TransactionList = ({ data, mealType }) => {
  const [expandedStates, setExpandedStates] = useState({});

  const deleteMeal = async (i, transactionId) => {
    console.log({ i, transactionId });
    let now = new Date().toISOString().slice(0, 10);
    try {
      const orderToDelete = i.orders.find(
        (order) => order.transaction_id === transactionId
      );
      if (orderToDelete) {
        const refundAmount = orderToDelete.price;
        const paymentType = orderToDelete.payment_type;
        const params = `payment_type=${paymentType}&meal_type=${mealType}&refund_amount=${refundAmount}&ids=${transactionId}&family_id=${i.family_id}&local_date=${now}`;
        await api.orders.deleteMeal(params);
        window.location.reload();
      } else {
        alert("Order with the provided transactionId not found.");
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const toggleExpand = (id) => {
    setExpandedStates({
      ...expandedStates,
      [id]: !expandedStates[id],
    });
  };

  let lastRollNumber = null;
  let isAlternate = false;
  return data.map((item) => {
    if (lastRollNumber !== item.roll_number) {
      isAlternate = !isAlternate;
      lastRollNumber = item.roll_number;
    }
    const bgColorClass = isAlternate ? "bg-white" : "bg-gray-200";

    const adjustDateTimeIfNeeded = (local_date, local_time) => {
      const targetDate = new Date(2023, 9, 15); // Month is 0-indexed, so 9 is October
      const combinedDateTime = new Date(
        `${local_date.replace(" ", "T")}T${local_time}`
      );

      if (combinedDateTime < targetDate) {
        combinedDateTime.setHours(combinedDateTime.getHours() - 7);
      }

      return {
        adjustedDate: combinedDateTime.toLocaleDateString(),
        adjustedTime: combinedDateTime.toLocaleTimeString(),
      };
    };

    return (
      <div className={`text-lg px-6 py-4 ${bgColorClass}`} key={item._id}>
        <div className="flex">
          <span className="flex-1 my-auto">
            {item.first_name} {item.last_name}
          </span>
          <span className="flex-1 my-auto">{}</span>
          <span className="flex-1 my-auto">{item.roll_number}</span>

          <span
            className="flex-1 my-auto text-right items-right"
            onClick={() => toggleExpand(item.student_id)}
          >
            {item.total_price ? (
              <>
                <span className="material-symbols-outlined align-middle ml-2">
                  expand_more
                </span>
                {`$${item.total_price.toFixed(2)}`}
              </>
            ) : (
              "N/A"
            )}
          </span>
        </div>
        {expandedStates[item.student_id] && (
          <div className="text-sm text-right flex flex-1 flex-col">
            {item.orders && item.orders.length > 0
              ? item.orders.map((order) => (
                  <div
                    key={order.transaction_id}
                    className="flex flex-1 flex-row justify-between my-1 hover:bg-gray-100"
                  >
                    <span className="flex-1 text-left">
                      {new Date(
                        order.local_date.replace(" ", "T")
                      ).toLocaleDateString()}
                    </span>
                    <span className="flex-1 text-left">{order.local_time}</span>
                    <span className="flex-1 text-left">{order.short_name}</span>
                    <span className="flex-1">
                      {order.collected !== undefined && order.collected !== null
                        ? order.collected === false
                          ? "Collected"
                          : "Not Collected"
                        : ""}
                    </span>

                    {order.payment_type && (
                      <span className="flex-1 text-right">
                        {order.payment_type}
                      </span>
                    )}
                    <span className="flex-1 text-right">${order.price}</span>
                    <span
                      className="flex-1 text-right text-xs material-symbols-outlined"
                      onClick={() => {
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        ) && deleteMeal(item, order.transaction_id);
                      }}
                    >
                      delete
                    </span>
                  </div>
                ))
              : ""}
          </div>
        )}
      </div>
    );
  });
};

export const TransactionListHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Name</span>
      <span className="flex-1"></span>
      <span className="flex-1 ">Customer ID</span>

      <span className="flex-1 text-right">Debit</span>
    </div>
  );
};
