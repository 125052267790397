import React, { useEffect, useState } from "react";
import MainLayout from "../layout/Main";
import Search from "../components/Search";
import api from "../Api";
import Pagination from "../components/Pagination";
import { FamilyList, FamilyHeaders } from "../components/FamilyList";
import Loading from "../components/Loading";

// This in menu under Customer Reports => Family Balances url/families
// Has adjustment input

const Families = () => {
  const itemsPerPage = 50;
  const franchiseId = localStorage.getItem("franchiseId");
  const [families, setFamilies] = useState([]);
  const [search, setSearch] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [currentFamilies, setCurrentFamilies] = useState([]);
  const pageCount = Math.ceil(families.length / itemsPerPage);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFamilies();
  }, [filter]);

  useEffect(() => {
    setError(null);
    if (search.length > 2) {
      setFilter(search);
    } else if (search.length === 0) {
      setFilter("");
    }
  }, [search]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (families.length > 0) {
      setCurrentFamilies(families.slice(itemOffset, endOffset));
    }
  }, [families, itemOffset]);

  const fetchFamilies = async () => {
    // Due to change in DreamFactory, we need to check the responses more carefully
    try {
      // Is a procedure that can return an empty array or nothing at all
      const familyList = await api.students.getFamilies(
        franchiseId,
        encodeURIComponent(filter)
      );
      if (typeof familyList === "undefined" || !familyList.resource) {
        return setError(`No families found for ${filter}`);
      }
      if (
        Array.isArray(familyList.resource) &&
        familyList.resource.length === 0
      ) {
        return setError(`No families found for ${filter}`);
      }
      setFamilies(familyList.resource);
    } catch (err) {
      setError(`Error fetching families: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationClick = (event) => {
    if (!families || !Array.isArray(families) || families.length === 0) {
      return;
    }
    const newOffset = (event.selected * itemsPerPage) % families.length;
    setItemOffset(newOffset);
  };

  return (
    <MainLayout
      pageTitle="Families"
      rightContainer={<></>}
      leftContainer={
        <Search
          id="search"
          placeholder={"Search"}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      }
      headers={<FamilyHeaders />}
      content={
        currentFamilies && (
          <>
            <div className="mb-16">
              {error ? (
                <div className="mt-16 text-center text-xl">{error}</div>
              ) : loading ? (
                <Loading />
              ) : (
                <FamilyList
                  familyList={currentFamilies}
                  fetchFamilies={fetchFamilies()}
                  franchiseId={franchiseId}
                  loading={setLoading}
                />
              )}
            </div>

            <Pagination
              pageCount={pageCount}
              onPageChange={handlePaginationClick}
            />
          </>
        )
      }
    />
  );
};

export default Families;
