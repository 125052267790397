import React from "react";
import NavBar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import ButtonTwo from "../components/ButtonTwo";
import api from "../Api";

const User = () => {
  const history = useHistory();
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId, schoolName } = school;
  const user = JSON.parse(localStorage.getItem("user"));
  const { first_name, last_name, email, last_login_date, session_token } = user;

  const logout = async () => {
    try {
      await api.user.logout(session_token);
      localStorage.removeItem("user");
      localStorage.removeItem("school");
      localStorage.removeItem("franchiseId");
      history.push("/login");
    } catch (err) {
      console.error(`Error is ${err}`);
      alert("Failed to log out. Please try again.");
    }
  };

  const lineItem = (title, value) => {
    return (
      <span className="border p-3 text-2xl flex rounded my-4">
        <span className="w-1/3">{title} </span>
        <span className="w-2/3">{value}</span>
      </span>
    );
  };
  return (
    <>
      <div className="h-screen bg-gray-100">
        <div className="fixed block top-0 w-full z-10 h-16">
          <div>
            <NavBar
              title={`${first_name} ${last_name}`}
              schoolId={schoolId}
              schoolName={schoolName}
            />
          </div>
        </div>
        <div className="inline-block w-full mt-16 text-gray-600">
          <div className="block w-1/2 mt-24 mx-auto bg-white p-4 rounded-md">
            {lineItem("First Name", first_name)}
            {lineItem("Last Name", last_name)}
            {lineItem("Email", email)}
            {lineItem("Last log in", last_login_date)}
            <div className="flex flex-col items-center my-8">
              <ButtonTwo title="Log Out" onClick={() => logout()} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
