import React, { useEffect, useState } from "react";
import MainLayout from "../layout/Main";
import Search from "../components/Search";
import api from "../Api";
import ButtonTwo from "../components/ButtonTwo";
import {
  FamilyTransactionList,
  FamilyTransactionListHeaders,
} from "../components/FamilyTransactionList";
import AdjustBalanceModal from "../components/BalanceAdjustmentModal";

const FamilyTransactions = () => {
  const school = JSON.parse(localStorage.getItem("school") || "{}");
  const franchiseId = localStorage.getItem("franchiseId");
  const schoolId = school?.schoolId;
  const [rollNumber, setRollNumber] = useState("");
  const [error, setError] = useState(
    "N.B. Entering a student ID will return all transactions for the family, not just those for the student."
  );
  const [familyTransactions, setFamilyTransactions] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [mergedData, setMergedData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [familyId, setFamilyId] = useState("");

  // Calculate totals to display in the right container
  const calculateTransactionTotals = (transactions) => {
    const totalDebit = transactions
      .reduce((acc, transaction) => {
        return acc + parseFloat(transaction.debit);
      }, 0)
      .toFixed(2);

    const totalCredit = transactions
      .reduce((acc, transaction) => {
        return acc + parseFloat(transaction.credit);
      }, 0)
      .toFixed(2);

    const balance = (parseFloat(totalCredit) - parseFloat(totalDebit)).toFixed(
      2
    );
    setTotalDebit(totalDebit);
    setTotalCredit(totalCredit);
    setBalance(balance);
  };

  useEffect(() => {
    calculateTransactionTotals(familyTransactions);
  }, [familyTransactions]);

  useEffect(() => {
    if (rollNumber) {
      setError("");
    }
  }, [rollNumber]);

  const fetchFamilyTransactions = async () => {
    setError("");
    if (!rollNumber) {
      return setError("Please enter a student ID.");
    }
    try {
      const transactions =
        await api.reports.getFamilyTransactionsFromRollNumber(
          schoolId,
          rollNumber
        );
      console.log(JSON.stringify(transactions));
      if (!transactions.resource || transactions.resource.length === 0) {
        return setError("No data found for this student.");
      }
      setFamilyTransactions(transactions.resource);
    } catch (err) {
      setError(`Error fetching family transactions: ${err}`);
      console.log(err);
    }
  };

  useEffect(() => {
    if (familyTransactions.length > 0) {
      fetchFamilyMembers();
    }
  }, [familyTransactions]);

  // Doing this here because it doesn't work very well in MySQL
  const fetchFamilyMembers = async () => {
    try {
      const familyMembers = await api.reports.getFamilyMembers(
        familyTransactions[0].family_id
      );
      setFamilyMembers(familyMembers.resource);
      setFamilyId(familyMembers.resource[0].family_id);
    } catch (err) {
      setError(`Error fetching family members: ${err}`);
      console.log(err);
    }
  };

  // Create a map of family members by roll number
  const studentMap = familyMembers.reduce((acc, student) => {
    acc[student.roll_number] = student;
    return acc;
  }, {});

  // Merge family transactions with family members
  const combinedData = familyTransactions.map((transaction) => {
    const student = studentMap[transaction.roll_number];
    return {
      ...transaction,
      first_name: student ? student.first_name : null,
      last_name: student ? student.last_name : null,
      stipend: student ? student.stipend : null,
    };
  });

  // Set the merged data
  useEffect(() => {
    if (familyTransactions.length > 0 && familyMembers.length > 0) {
      setMergedData(combinedData);
    }
  }, [familyTransactions, familyMembers]);

  return (
    <MainLayout
      leftContainer={
        <div className="flex flex-row">
          <div className="flex-1 my-auto flex flex-row">
            <div className="flex-grow my-auto">
              <Search
                placeholder="Enter Student ID"
                onChange={(e) => {
                  setRollNumber(e.target.value);
                  setOpenModal(false);
                }}
              />
            </div>
            <div className="flex-shrink my-auto text-left ml-2">
              <ButtonTwo title="GO" onClick={() => fetchFamilyTransactions()} />
            </div>
          </div>
        </div>
      }
      rightContainer={
        <div className="flex flex-1 my-auto">
          <div className="flex-1 text-lg">Total Credit: ${totalCredit}</div>
          <div className="flex-1 text-lg">Total Debit: ${totalDebit}</div>
          <div className="flex-1 text-lg">Balance: ${balance}</div>
          <div className="flex-1">
            <ButtonTwo title="Adjust" onClick={() => setOpenModal(true)} />
          </div>
        </div>
      }
      headers={<FamilyTransactionListHeaders />}
      content={
        error ? (
          <div className="text-2xl text-center mt-16">{error}</div>
        ) : (
          <>
            <div className="mb-16">
              <FamilyTransactionList data={mergedData} />
            </div>
            {openModal && (
              <AdjustBalanceModal
                setOpenModal={setOpenModal}
                franchiseId={franchiseId}
                familyId={familyId}
                // Reusing component so different name
                fetchFamilies={fetchFamilyTransactions}
                loading={setLoading}
              />
            )}
          </>
        )
      }
    />
  );
};

export default FamilyTransactions;
