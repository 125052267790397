import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Schools from "../pages/Schools";
import MasterMenu from "../pages/MainMenu";
import Orders from "../pages/Orders";
import Students from "../pages/Students";
import User from "../pages/User";
import LoginPage from "../pages/Login";
import Labels from "../pages/MealLabels";
import SchoolMenu from "../pages/LocationMenu";
import PreRegisteredStudents from "../pages/PreRegisterList";
import OrdersWithNames from "../pages/OrdersWithNames";
// import CalendarComponent from "../pages/Test";
import AuthorizeSellerRedirect from "../pages/AuthorizeSellerRedirect";
import AuthorizeSeller from "../pages/AuthorizeSeller";
import Settings from "../pages/Settings";
import Families from "../pages/Families";
import FamiliesWithChildren from "../pages/FamiliesWithChildren";
import FamiliesByStudentId from "../pages/FamiliesWithChildrenByStudentId";
import NotFound from "../pages/NotFound";
import SalesByItem from "../pages/SalesByItem";
import ShowDuplicates from "../pages/ShowDuplicates";
import FamilyBalances from "../pages/FamilyBalances";
import TempWalletSales from "../pages/TempSalesWallet";
import TempCashSales from "../pages/TempSalesCash";
import PosSales from "../pages/SalesPOSByType";
import StudentTransactions from "../pages/StudentTransactionsBySchool";
import GuestSales from "../pages/GuestSales";
import StudentTransactionsPOSItemised from "../pages/StudentTransactionsItemised";
import StudentTransactionsPreOrdersItemised from "../pages/StudentPreordersItemised";
import FamilyTransactions from "../pages/FamilyTransactions";
import CancelOrders from "../pages/CancelOrders";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/locations">
          <Schools />
        </Route>
        <Route path="/menu">
          <MasterMenu />
        </Route>
        <Route path="/orders">
          <Orders />
        </Route>
        <Route path="/customers">
          <Students />
        </Route>
        <Route path="/user">
          <User />
        </Route>
        <Route path="/labels">
          <Labels />
        </Route>
        <Route path="/location-menu">
          <SchoolMenu />
        </Route>
        <Route path="/pre-reg">
          <PreRegisteredStudents />
        </Route>
        <Route path="/orders-with-names">
          <OrdersWithNames />
        </Route>
        {/* <Route path="/test">
          <CalendarComponent />
        </Route> */}
        <Route path="/auth">
          <AuthorizeSellerRedirect />
        </Route>
        <Route path="/connect-account">
          <AuthorizeSeller />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/families">
          <Families />
        </Route>
        <Route path="/families-with-children">
          <FamiliesWithChildren />
        </Route>
        <Route path="/families-by-student-id">
          <FamiliesByStudentId />
        </Route>
        <Route path="/sales-by-item">
          <SalesByItem />
        </Route>
        <Route path="/show-duplicates">
          <ShowDuplicates />
        </Route>
        <Route path="/family-balances">
          <FamilyBalances />
        </Route>
        <Route path="/temp-wallet-sales">
          <TempWalletSales />
        </Route>
        <Route path="/pos-sales">
          <PosSales />
        </Route>
        <Route path="/temp-cash-sales">
          <TempCashSales />
        </Route>
        <Route path="/student-transactions">
          <StudentTransactions />
        </Route>
        <Route path="/guest-sales">
          <GuestSales />
        </Route>
        {/* StudentTransactionsItemised.js */}
        <Route path="/student-transactions-itemized">
          <StudentTransactionsPOSItemised />
        </Route>
        <Route path="/student-preorders-itemized">
          <StudentTransactionsPreOrdersItemised />
        </Route>
        <Route path="/family-transactions">
          <FamilyTransactions />
        </Route>
        <Route path="/cancel-orders">
          <CancelOrders />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
