import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";

// For connecting franchisee Square accounts

const auth = {
  // Should be modified so that server gets franchise id from the user object
  submitToken: async (code, franchiseId, locationId, schoolId, schoolName) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = axios.post(
      `${API_BASE_URL}/authorize_seller_account_v2?api_key=${COMMON_API_KEY}&session_token=${user.session_token}&code=${code}&franchise_id=${franchiseId}&location_id=${locationId}&school_id=${schoolId}&school_name=${schoolName}`
    );
    return response;
  },

  tokenStatus: async (schoolId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = axios.get(
      `${API_BASE_URL}/token_status_v2?school_id=${schoolId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },

  revokeToken: async (schoolId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = axios.post(
      `${API_BASE_URL}/revoke_auth_v2?school_id=${schoolId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response.data;
  },
};

export default auth;
