import React from "react";

const Input = ({
  id,
  placeholder,
  val,
  onChange,
  type,
  label,
  disabled,
  onBlur,
  autoComplete,
}) => {
  return (
    <label className="text-lg">
      {label}
      <input
        id={id}
        autoComplete={autoComplete}
        className="w-full my-3 py-2 px-1 placeholder-slate-400 text-slate-600 bg-white rounded shadow outline-none focus:outline-none focus:ring"
        type={type ? type : "text"}
        placeholder={placeholder}
        value={val}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    </label>
  );
};

export default Input;
