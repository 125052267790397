import React, { useEffect, useState } from "react";
import MainLayout from "../layout/Main";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import {
  FamilyBalanceList,
  FamilyBalanceHeaders,
} from "../components/FamilyBalancesList";
import api from "../Api";
import ButtonTwo from "../components/ButtonTwo";
import { CSVLink } from "react-csv";
import { csvHeadersFamilyBalances } from "../constants";

// In menu under Sales Reports => Family Balances.  url/family-balances
const FamilyBalances = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId } = school;
  const [familyBalances, setFamilyBalances] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  const [error, setError] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [forcePage, setForcePage] = useState(0);

  const fetchBalances = async () => {
    try {
      const balances = await api.reports.getFamilyBalances(schoolId);
      if (!balances.resource || balances.resource.length === 0) {
        return setError("No data found for this school.");
      }
      setFamilyBalances(balances.resource);
      setFilteredData(balances.resource); // setting filteredData here
    } catch (err) {
      setError(`Error fetching families: ${err}`);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentPageItems(filteredData.slice(itemOffset, endOffset));
  }, [filteredData, itemOffset]);

  const handlePaginationClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    setForcePage(event.selected);
  };

  // Search by last name or roll number
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setItemOffset(0); // reset the item offset to the start
    if (searchTerm) {
      const newFilteredData = familyBalances.filter(
        (item) =>
          item.last_name.toLowerCase().includes(searchTerm) ||
          (item.roll_number &&
            item.roll_number.toString().toLowerCase().includes(searchTerm))
      );
      if (newFilteredData.length === 0) {
        setError("No results found.");
      } else {
        setError("");
      }
      setFilteredData(newFilteredData);
    } else {
      setError("");
      setFilteredData(familyBalances);
    }
  };

  // Set the page count
  const pageCount = Math.ceil(filteredData.length / itemsPerPage) || 1;

  return (
    <MainLayout
      pageTitle="Families by Student ID"
      rightContainer={
        <div className="w-full text-right pr-6">
          <CSVLink data={familyBalances} headers={csvHeadersFamilyBalances}>
            <ButtonTwo title="Download" />
          </CSVLink>
        </div>
      }
      leftContainer={
        <Search id="search" placeholder={"Search"} onChange={handleSearch} />
      }
      headers={<FamilyBalanceHeaders />}
      content={
        error ? (
          <div className="w-full text-lg text-center mt-16">{error}</div>
        ) : (
          <>
            <div className="mb-16">
              <FamilyBalanceList data={currentPageItems} />
            </div>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePaginationClick}
              forcePage={forcePage}
            />
          </>
        )
      }
    />
  );
};

export default FamilyBalances;
