import React, { useState, useEffect } from "react";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import ButtonTwo from "../../components/ButtonTwo";
import { validateEmail } from "../../utils/validations";

const LoginForm = ({ path }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const history = useHistory();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    setEmailErr("");
    if (validateEmail(email)) {
      try {
        await api.user.login(email, password);
        getFranchise();
      } catch (err) {
        setEmailErr(err?.response?.data?.error?.message);
      }
    } else {
      setEmailErr("Email format is invalid!");
    }
  };

  const getFranchise = async () => {
    try {
      const result = await api.franchise.getFranchise();
      const franchiseId = result?.data?.resource[0]?.franchise_id;
      localStorage.setItem("franchiseId", franchiseId);
      history.push("/locations");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email, password]);

  return (
    <>
      <div className="w-full m-auto">
        <div className="input-container">
          <span className="text-xl inline-block my-2">Sign In</span>
          <form>
            <div className="relative flex w-full flex-wrap items-stretch my-5">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-envelope"></i>
              </span>
              <input
                id="email"
                value={email}
                autoComplete="off"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErr("");
                }}
                type="email"
                placeholder="Email"
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10 cursor-pointer"
              />
              <span className="inline-block text-red-600 text-sm mt-3 w-full text-center">
                {emailErr}
              </span>
            </div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-lock"></i>
              </span>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setEmailErr("");
                }}
                placeholder="Password"
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"
              />
              <span
                className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer"
                onClick={() => togglePassword()}
              >
                {showPassword ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
          </form>
          <div className="flex flex-col items-center pt-8">
            <ButtonTwo title={"Submit"} onClick={() => handleSubmit()} />
          </div>
          <span
            className="text-gray-400 text-sm my-10 hover:text-blue-600 inline-block"
            onClick={() => path("forgot")}
          >
            Forgot password?
          </span>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
