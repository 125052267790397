import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";
import NavBar from "../components/Navbar";
import Modal from "../components/Modal";
import AddSchool from "../components/AddSchool";
import EditSchool from "../components/EditSchoolCreditLimit";
import SchoolsList from "../components/SchoolList";
import HeaderButton from "../components/HeaderButton";

// Locations page
// Needs to be refactored to use Layout please

const Schools = () => {
  const [franchiseId] = useState(localStorage.getItem("franchiseId"));
  const history = useHistory();
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState({});
  const [schoolName, setSchoolName] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [schoolId, setSchoolId] = useState();
  const [moveOn, setMoveOn] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [firstSchool, setFirstSchool] = useState(true);

  useEffect(() => {
    console.log("schools", schools.length);
    if (schools.length > 0) {
      setFirstSchool(false);
    }
  }, [schools]);

  useEffect(() => {
    console.log(`firstSchool is ${firstSchool}`);
  }, [firstSchool]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const list = await api.schoolInfo.getList(franchiseId);
        setSchools(list.resource);
      } catch (err) {
        console.error(`Error is ${err}`);
        alert("Failed to fetch schools. Please try again.");
        localStorage.removeItem("user");
        history.push("/login");
      }
    };
    if (!openModal || !edit) {
      fetchSchools();
    }
  }, [openModal, edit, history]);

  useEffect(() => {
    localStorage.setItem(
      "school",
      JSON.stringify({
        schoolId,
        schoolCode,
        schoolName,
      })
    );
    if (moveOn === "goToOrders" && schoolName !== "Default Main Location") {
      history.push("/orders");
    }
    if (moveOn === "goToSettings") {
      history.push("/settings");
    }
  }, [moveOn, schoolCode, schoolId, schoolName, history]);

  const handleAction = (e, moveOnValue) => {
    setSchoolCode(e.code);
    setSchoolName(e.name);
    setSchoolId(e._id);
    setMoveOn(moveOnValue);
  };

  return (
    <>
      <div className="sticky block top-0 w-full bg-white z-10">
        {edit && (
          <EditSchool edit={setEdit} schoolId={schoolId} school={school} />
        )}
        <NavBar title="Schools" schoolId={schoolId} schoolName={schoolName} />
        <div className="w-full">
          <div className="w-full px-8 bg-white">
            <div className="flex my-2">
              <div className="flex flex-1 h-16 w-full">
                <span className="flex-1 my-auto text-center"></span>
                <span className="flex-1 my-auto text-right">
                  <HeaderButton
                    className="flex-1"
                    title="Add Location"
                    onClick={() => setOpenModal(true)}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row py-2 px-8 justify-between bg-gray-300 text-black">
            <span className="flex-1 py-1 pointer-events-none">Name</span>
            <span className="flex-1 py-1 pointer-events-none">Code</span>
            <span className="flex-1 py-1 pointer-events-none">
              Credit Limit
            </span>
            <span className="flex-1 py-1 pointer-events-none">
              Order Cut Off
            </span>
            <span className="flex-1 py-1 text-center pointer-events-none">
              Status
            </span>
            <span className="flex-1 py-1 text-right pointer-events-none">
              Fees at POS?
            </span>
            <span className="flex-1 py-1 pointer-events-none"></span>
          </div>
        </div>
        {openModal && (
          <Modal
            openModal={setOpenModal}
            title={"Add a School"}
            content={
              <AddSchool
                openModal={setOpenModal}
                franchiseId={franchiseId}
                firstSchool={firstSchool}
              />
            }
          />
        )}
      </div>
      {schools.length > 0 ? (
        <div className="text-lg">
          <SchoolsList
            schools={schools}
            handleAction={handleAction}
            edit={setEdit}
            school={setSchool}
            schoolId={setSchoolId}
          />
        </div>
      ) : (
        <div className="text-3xl text-center w-full mt-8">
          You have no locations at present! Use the button above to add your
          first one.
        </div>
      )}
      <div className="fixed bottom-0 bg-gray-600 text-sm text-white p-1">
        Version: 12.4.0 Issued: 2023-10-13
      </div>
    </>
  );
};
export default Schools;

// Refactor below using Layout, but modal not working atm

// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import api from "../Api";
// import NavBar from "../components/Navbar";
// import Modal from "../components/Modal";
// import AddSchool from "../components/AddSchool";
// import EditSchool from "../components/EditSchoolCreditLimit";
// import SchoolsList from "../components/SchoolList";
// import HeaderButton from "../components/HeaderButton";
// import MainLayout from "../layout/Main";

// // Schools page
// const Schools = () => {
//   const [franchiseId] = useState(localStorage.getItem("franchiseId"));
//   const history = useHistory();
//   const [schools, setSchools] = useState([]);
//   const [school, setSchool] = useState({});
//   const [schoolName, setSchoolName] = useState("");
//   const [schoolCode, setSchoolCode] = useState("");
//   const [schoolId, setSchoolId] = useState();
//   const [moveOn, setMoveOn] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   const [edit, setEdit] = useState(false);

//   useEffect(() => {
//     const fetchSchools = async () => {
//       try {
//         const list = await api.schoolInfo.getList(franchiseId);
//         setSchools(list.resource);
//       } catch (err) {
//         console.error(`Error is ${err}`);
//         alert("Failed to fetch schools. Please try again.");
//         localStorage.removeItem("user");
//         history.push("/login");
//       }
//     };
//     if (!openModal || !edit) {
//       fetchSchools();
//     }
//   }, [openModal, edit, history, franchiseId]);

//   useEffect(() => {
//     if (moveOn) {
//       localStorage.setItem(
//         "school",
//         JSON.stringify({
//           schoolId,
//           schoolCode,
//           schoolName,
//         })
//       );
//       history.push("/orders");
//     }
//   }, [moveOn, schoolCode, schoolId, schoolName, history]);

//   const handleSchool = (e) => {
//     setSchoolCode(e.code);
//     setSchoolName(e.name);
//     setSchoolId(e._id);
//     setMoveOn(true);
//   };

//   useEffect(() => {
//     console.log(`openModal is ${openModal}`);
//   }, [openModal]);

//   return (
//     <MainLayout
//       title="Schools"
//       leftContainer={
//         <HeaderButton
//           title="Settings"
//           onClick={() => history.push("/settings")}
//         />
//       }
//       rightContainer={
//         <HeaderButton title="Add Location" onClick={() => setOpenModal(true)} />
//       }
//       headers={
//         <div className="flex text-lg">
//           <span className="flex-1 py-1 pointer-events-none">Name</span>
//           <span className="flex-1 py-1 pointer-events-none">Code</span>
//           <span className="flex-1 py-1 pointer-events-none">Credit Limit</span>
//           <span className="flex-1 py-1 pointer-events-none">Order Cut Off</span>
//           <span className="flex-1 py-5 px-1 text-right pointer-events-none"></span>
//         </div>
//       }
//       content={
//         schools.length > 0 ? (
//           <SchoolsList
//             schools={schools}
//             handleSchool={handleSchool}
//             edit={setEdit}
//             school={setSchool}
//             schoolId={setSchoolId}
//           />
//         ) : (
//           <div className="text-3xl text-center w-full mt-8">
//             You have no locations at present! Use the button above to add your
//             first one.
//           </div>
//         )
//       }
//     >
//       {openModal && (
//         <Modal
//           franchiseId={franchiseId}
//           openModal={setOpenModal}
//           title={"Add a School"}
//           content={
//             <AddSchool openModal={setOpenModal} franchiseId={franchiseId} />
//           }
//         />
//       )}
//       {edit && (
//         <EditSchool edit={setEdit} schoolId={schoolId} school={school} />
//       )}
//     </MainLayout>
//   );
// };
// export default Schools;
