import axios from "axios";
import { API_BASE_URL } from "./config";

// Auth etc. for logging in and out - see ./auth for Square auth calls

const user = {
  register: async (email, password) => {
    const response = axios.post(`${API_BASE_URL}/user/register?`, {
      email,
      password,
    });
    return response.data;
  },

  login: async (email, password) => {
    const response = await axios.post(`${API_BASE_URL}/user/session?`, {
      email,
      password,
    });
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
  },

  profile: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(
      `${API_BASE_URL})/user/profile?session_token=${user.session_token}`
    );
    return response;
  },

  refresh: async (token) => {
    const response = await axios.put(
      `${API_BASE_URL}/user/session?session_token=${token}`
    );
    localStorage.setItem("user", JSON.stringify(response.data));
    return response;
  },

  reset: async (email, reset) => {
    const response = await axios.post(
      `${API_BASE_URL}/user/password?reset=true`,
      {
        email,
        reset,
      }
    );
    return response;
  },

  recover: async (code, email, password) => {
    const response = await axios.post(
      `${API_BASE_URL}/user/password?login=true`,
      {
        email,
        new_password: password,
        code,
        remember_me: true,
      }
    );
    await user.login(email, password);
    return response;
  },

  logout: async (sessionToken) => {
    const response = await axios.delete(
      `${API_BASE_URL}/user/session?session_token=${sessionToken}`
    );
    return response;
  },
};

export default user;
