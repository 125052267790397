import React from "react";
import CSVReader from "../components/CsvReader";

const BulkUpload = ({ openModal, csvUpload }) => {
  return (
    <div className="">
      <div className="block flex flex-wrap items-stretch mb-3">
        <div className="block mx-auto w-2/3 p-4 bg-white bg-white rounded text-xl border border-slate-300 shadow">
          <span className="inline-block w-full text-slate-600 text-center pb-3">
            Upload lists prior to term start
          </span>
          <p className="text-slate-500 text-sm">
            Use this utility to upload student lists prior to the term start.
            This list will be used to register students whose parents have
            failed to do so before term start. If you are sure all students will
            be registered you do not need to do this.
          </p>
        </div>
        <div className="block mx-auto mt-3 w-2/3 p-6 bg-white bg-white rounded text-3xl border border-slate-300 shadow">
          <span className="inline-block w-full text-slate-600 text-center pb-3">
            Instructions
          </span>
          <p className="text-slate-500 text-lg">
            File type MUST be .csv, first row of which should contain the
            headers: roll_number, parent_email, first_name and last_name.
          </p>
        </div>
      </div>
      <div className="block flex flex-wrap items-stretch">
        <div className="block mx-auto w-2/3 p-6 bg-white bg-white rounded text-3xl border border-slate-300 shadow">
          <CSVReader openModal={openModal} csvUpload={csvUpload} />
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
