const DateManager = {
  MySqlDate: (date) => {
    const dateOnlyUsedByTheUSADespiteBeingObviouslyWrong = date;
    const parts = dateOnlyUsedByTheUSADespiteBeingObviouslyWrong.split("-");
    const sensibleDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return sensibleDate;
  },

  USDate: (date) => {
    const sensibleDate = date;
    const parts = sensibleDate.split("-");
    const dateOnlyUsedByTheUSADespiteBeingObviouslyWrong =
      parts[1] + "/" + parts[2] + "/" + parts[0];
    return dateOnlyUsedByTheUSADespiteBeingObviouslyWrong;
  },

  formatDateForMysql: (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = "" + d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return `${year}-${month}-${day}`;
  },
};

export default DateManager;
