import React, { useEffect, useState, useCallback } from "react";
import api from "../Api";
import MainLayout from "../layout/Main";
import { SalesList, SalesListHeaders } from "../components/GuestSalesList";
import { CSVLink } from "react-csv";
import { csvHeadersTempCashSales as csvHeaders } from "../constants";
import ButtonTwo from "../components/ButtonTwo";
import DateManager from "../utils/convertDate";
import DateRangePicker from "../components/DateRangePicker";

const GuestSales = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const schoolId = school?.schoolId;
  const [balancesByDay, setBalancesByDay] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchBalances = async () => {
    setError("");
    try {
      const balances = await api.reports.guestSales(
        schoolId,
        DateManager.formatDateForMysql(startDate),
        DateManager.formatDateForMysql(endDate)
      );
      if (!balances.resource || balances.resource.length === 0) {
        return setError(
          "No guest sales found for this school for these dates."
        );
      }
      setBalancesByDay(balances.resource);
    } catch (err) {
      setError(`Error fetching families: ${err}`);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, [startDate, endDate, schoolId]);

  const calculateTotal = useCallback(() => {
    return balancesByDay.reduce((acc, curr) => {
      return Number(acc) + Number(curr.daily_total);
    }, 0);
  }, [balancesByDay]);

  return (
    <MainLayout
      rightContainer={
        <div className="w-full text-right pr-6">
          <CSVLink data={balancesByDay} headers={csvHeaders}>
            <ButtonTwo title="Download" />
          </CSVLink>
        </div>
      }
      leftContainer={
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      }
      headers={<SalesListHeaders />}
      content={
        error ? (
          <div className="w-full text-lg text-center mt-16">{error}</div>
        ) : (
          <>
            <div className="mb-16">
              <SalesList data={balancesByDay} />
              <div className="w-full text-right pr-6 text-xl bg-gray-200 py-4">
                Total for date range:{" "}
                {calculateTotal().toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            </div>
          </>
        )
      }
    />
  );
};

export default GuestSales;
