import React, { useEffect, useState } from "react";
import api from "../Api";
import DatePicker from "react-datepicker";
import DateManager from "../utils/convertDate";
import EditCheckbox from "./LocationMenuCheckbox";
import { positions } from "../constants";
import Dropdown from "./Dropdown";
import Input from "./Input";
import ButtonTwo from "./ButtonTwo";
import { validatePrice } from "../utils/validations";

const EditMenuItem = ({
  schoolId,
  service,
  openEditItem,
  menuItem,
  // editOnly sets PATCH not POST call etc.
  editOnly,
}) => {
  const menuItemId = menuItem._id;
  const [serviceArray, setServiceArray] = useState([]);
  const [price, setPrice] = useState(menuItem.price);
  const [locationStartDate, setLocationStartDate] = useState(
    menuItem.start_date ? new Date(menuItem.start_date) : new Date()
  );
  const [locationEndDate, setLocationEndDate] = useState(
    // If a new item, default is from today to a very long time hence...
    menuItem.end_date ? new Date(menuItem.end_date) : new Date("2075-01-01")
  );
  const [startError, setStartError] = useState();
  const [breakfast, setBreakfast] = useState(menuItem.breakfast);
  const [nutrition, setNutrition] = useState(menuItem.nutrition);
  const [lunch, setLunch] = useState(menuItem.lunch);
  const [afterSchool, setAfterSchool] = useState(menuItem.after_school);
  const [shortName, setShortName] = useState(
    menuItem.short_name || menuItem.title
  );
  const [posOrder, setPosOrder] = useState(menuItem.pos_position || 100);
  const [archive, setArchive] = useState(menuItem.archive);
  const [canDelete, setCanDelete] = useState(false);
  const [pre, setPre] = useState(menuItem.pre_order || true);

  const handlePrice = () => {
    !validatePrice(price)
      ? alert("Please enter numbers and decimal point only")
      : price > 50
      ? alert("This seems expensive!")
      : setPrice(price);
  };

  useEffect(() => {
    console.log(
      `Start date is ${DateManager.formatDateForMysql(
        locationStartDate
      )} and end date is ${DateManager.formatDateForMysql(locationEndDate)}`
    );
    DateManager.formatDateForMysql(locationStartDate) >
    DateManager.formatDateForMysql(locationEndDate)
      ? setStartError("Start date must be earlier than end date")
      : setStartError(false);
  }, [locationStartDate, locationEndDate]);

  useEffect(() => {
    const checkIfUsed = async () => {
      try {
        const count = await api.orders.getOrderCount(menuItemId);
        console.log(count);
        count > 0 ? setCanDelete(false) : setCanDelete(true);
      } catch (err) {
        console.log(err);
      }
    };
    checkIfUsed();
  }, [menuItemId]);

  const addToMenu = async () => {
    handlePrice();
    if (validatePrice(price) && !startError) {
      try {
        await api.menus.setSchoolMenu(
          [schoolId],
          menuItemId,
          serviceArray,
          price,
          DateManager.formatDateForMysql(locationStartDate),
          DateManager.formatDateForMysql(locationEndDate),
          shortName,
          posOrder
        );
      } catch (err) {
        console.log({ err });
      } finally {
        openEditItem(false);
      }
    }
  };

  const updateMenu = async () => {
    handlePrice();
    if (validatePrice(price) && !startError) {
      try {
        await api.menus.updateSchoolMenu(
          menuItemId,
          schoolId,
          service,
          price,
          DateManager.formatDateForMysql(locationStartDate),
          DateManager.formatDateForMysql(locationEndDate),
          shortName,
          posOrder,
          archive,
          pre
        );
      } catch (err) {
        console.log({ err });
      } finally {
        openEditItem(false);
      }
    }
  };

  const deleteMenuItem = async () => {
    try {
      await api.menus.deleteSchoolMenu(menuItemId);
    } catch (err) {
      console.log(err);
    } finally {
      openEditItem(false);
    }
  };

  // const locationServices = [
  //   {
  //     val: breakfast,
  //     service: "breakfast",
  //   },
  //   {
  //     val: nutrition,
  //     service: "nutrition",
  //   },
  //   {
  //     val: lunch,
  //     service: "lunch",
  //   },
  //   {
  //     val: afterSchool,
  //     service: "after_school",
  //   },
  // ];

  // // useEffect(() => {
  // //   let serviceArr = [];
  // //   locationServices.forEach((e) => {
  // //     if (e.val === true) {
  // //       serviceArr.push(e.service);
  // //     }
  // //   });
  // // }, [breakfast, nutrition, lunch, afterSchool]);

  // const makeServiceArray = () => {
  //   let serviceArr = [];
  //   locationServices.forEach((e) => {
  //     if (e.val === true) {
  //       serviceArr.push(e.service);
  //     }
  //   });
  //   return serviceArr;
  // };

  // useEffect(() => {
  //   setServiceArray(makeServiceArray());
  // }, [breakfast, nutrition, lunch, afterSchool]);

  // useEffect(() => {
  //   console.log(serviceArray);
  // }, [breakfast, nutrition, lunch, afterSchool]);

  const makeServiceArray = () => {
    const currentLocationServices = [
      {
        val: breakfast,
        service: "breakfast",
      },
      {
        val: nutrition,
        service: "nutrition",
      },
      {
        val: lunch,
        service: "lunch",
      },
      {
        val: afterSchool,
        service: "after_school",
      },
    ];

    return currentLocationServices
      .filter((serviceObj) => serviceObj.val)
      .map((serviceObj) => serviceObj.service);
  };

  useEffect(() => {
    setServiceArray(makeServiceArray());
  }, [breakfast, nutrition, lunch, afterSchool]);

  return (
    <>
      <div className="fixed z-80 w-full h-full -mt-48 bg-black overflow-y-scroll bg-opacity-50 pt-4 pb-32">
        <div className="w-1/2 bg-white mx-auto rounded">
          <p
            className="text-right pt-4 px-8 text-2xl"
            onClick={() => openEditItem(false)}
          >
            X
          </p>
          <div className="text-center text-3xl pt-4">
            {editOnly ? "Edit" : "Add"} {service.replace("_", " ")} item
          </div>
          <div className="relative top-8">
            <div className="px-6">
              <hr />
              <div className="mx-6 pb-32">
                <span className="inline-block text-2xl w-full p-6 mt-2">
                  {menuItem.title}
                </span>
                <div className="text-xl flex flex-row px-6 py-2">
                  <span className="inline-flex flex-1 mt-3 pt-2">
                    Short Name for POS button
                  </span>
                  <span className="inline-flex flex-1">
                    <Input
                      id="short-name"
                      placeholder={posOrder === 100 ? "Not in POS" : shortName}
                      value={posOrder === 100 ? "Not in POS" : shortName}
                      onChange={(e) => setShortName(e.target.value)}
                      disabled={posOrder === 100 ? true : false}
                    />
                  </span>
                </div>
                <div className="text-xl flex flex-row px-6 py-2">
                  <span className="inline-flex flex-1">Postion in POS</span>
                  <span className="inline-flex flex-1 text-xl py-1">
                    <Dropdown
                      id="pos-order"
                      title={""}
                      data={positions}
                      value={posOrder}
                      onChange={(e) => setPosOrder(e.target.value)}
                    />
                  </span>
                </div>
                <div className="text-xl flex flex-row px-6 py-2 mb-2">
                  <span className="inline-flex flex-1">
                    Available for pre-order?
                  </span>
                  <span className="inline-flex flex-1 text-xl -mt-2 -ml-8">
                    <span className="">
                      <EditCheckbox
                        id="can-pre-order"
                        label={""}
                        val={setPre}
                        value={pre}
                      />
                    </span>
                  </span>
                </div>
                <div className="text-xl flex flex-row px-6 py-2">
                  <span className="inline-flex flex-1 mt-3 pt-2">Price $</span>
                  <span className="inline-flex flex-1">
                    <Input
                      id="price"
                      placeholder={price}
                      val={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </span>
                </div>
                <div className="text-xl flex flex-row px-6 py-2">
                  <span className="inline-flex flex-1">Start Date</span>
                  <span className="inline-flex flex-1">
                    <DatePicker
                      id="start-date"
                      className="rounded shadow p-1"
                      selected={locationStartDate}
                      onChange={(date) => {
                        !archive
                          ? setLocationStartDate(date)
                          : alert("Item is archived!  Uncheck archive to edit");
                      }}
                    />
                  </span>
                </div>
                <p className="text-red-600 px-6 py-2">{startError}</p>
                <div className="text-xl flex flex-row px-6 py-2">
                  <span className="inline-flex flex-1">End Date</span>
                  <span className="inline-flex flex-1">
                    <DatePicker
                      id="end-date"
                      className="rounded shadow p-1"
                      selected={locationEndDate}
                      onChange={(date) => {
                        !archive
                          ? setLocationEndDate(date)
                          : alert("Item is archived!  Uncheck archive to edit");
                      }}
                    />
                  </span>
                </div>

                {!editOnly && (
                  <>
                    <p className="px-6 py-4 text-xl">
                      Add to all available services?
                    </p>
                    {menuItem.breakfast && (
                      <span className="px-4">
                        <EditCheckbox
                          id="breakfast"
                          label={"Breakfast"}
                          val={setBreakfast}
                          value={breakfast}
                        />
                      </span>
                    )}
                    {menuItem.nutrition && (
                      <span className="px-4">
                        <EditCheckbox
                          id="nutrition"
                          label={"Nutrition"}
                          val={setNutrition}
                          value={nutrition}
                        />
                      </span>
                    )}
                    {menuItem.lunch && (
                      <span className="px-4">
                        <EditCheckbox
                          id="lunch"
                          label={"Lunch"}
                          val={setLunch}
                          value={lunch}
                        />
                      </span>
                    )}
                    {menuItem.after_school && (
                      <span className="px-4">
                        <EditCheckbox
                          id="after-school"
                          label={"After School"}
                          val={setAfterSchool}
                          value={afterSchool}
                        />
                      </span>
                    )}
                  </>
                )}
                {editOnly && (
                  <>
                    <span className="px-4">
                      <EditCheckbox
                        id="archive"
                        label={"Archive"}
                        val={setArchive}
                        value={archive}
                        locationEndDate={setLocationEndDate}
                        locationStartDate={setLocationStartDate}
                      />
                      <p className="ml-6 my-4 text-sm text-gray-500">
                        N.B. Archive sets the available dates to yesterday,
                        effectively removing the item from sale. You may then
                        hide archived items using the filter in the location
                        menu list.
                      </p>
                    </span>
                    <span className="inline-block w-full mt-4">
                      <button
                        className={`${
                          canDelete ? "bg-red-600" : "bg-gray-300"
                        } text-white active:bg-blue-700 font-bold uppercase text px-6 py-3 my-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                        onClick={() => canDelete && deleteMenuItem()}
                      >
                        Delete
                      </button>
                      {canDelete ? (
                        <p className="text-sm mt-4 text-gray-500">
                          Item can be deleted, or use archive to hide in list if
                          you want to retrieve it later.
                        </p>
                      ) : (
                        <p className="text-sm mt-4 text-gray-500">
                          Item is associated with orders, and cannot be deleted.
                          Use archive to hide.
                        </p>
                      )}
                    </span>
                  </>
                )}
                <hr className="mt-8" />
                <span className="inline-block text-center w-full mt-16">
                  <ButtonTwo
                    title="Save"
                    onClick={() => (editOnly ? updateMenu() : addToMenu())}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditMenuItem;
