import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";
export const CancelToken = axios.CancelToken;

const students = {
  getStudents: async (
    schoolId,
    filter,
    searchFilter,
    stipendFilter,
    franchiseId
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/students?franchise_id=${franchiseId}&order=last_name%20ASC%20&filter=(school_id=${schoolId})${filter}${searchFilter}${stipendFilter}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );

    return data;
  },

  updateStudent: async (
    allow_negative_balance,
    dairy_allergy,
    first_name,
    grade,
    id,
    last_name,
    max_spend,
    nut_allergy,
    gluten_allergy,
    vegetarian,
    vegan,
    school_id,
    roll_number,
    stipend
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/students/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        first_name,
        last_name,
        grade,
        dairy_allergy,
        nut_allergy,
        allow_negative_balance,
        max_spend,
        gluten_allergy,
        vegetarian,
        vegan,
        school_id,
        roll_number,
        stipend,
      }
    );
    return response;
  },

  updateRestrictions: async (id, restriction) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/restrictions?filter=student_id=${id}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { resource: [{ restriction }] }
    );
    return response;
  },

  newRestrictions: async (student_id, restriction) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.post(
      `${API_BASE_URL}/mysql/_table/restrictions?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { resource: [{ student_id, restriction }] }
    );
    return response;
  },

  uploadPreReg: async (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.post(
      `${API_BASE_URL}/mysql/_table/pre_reg_students?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { resource: payload }
    );
    return response;
  },

  updatePreReg: async (id, payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.patch(
      `${API_BASE_URL}/mysql/_table/pre_reg_students/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      payload
    );
    return response;
  },

  deletePreReg: async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { response } = await axios.delete(
      `${API_BASE_URL}/mysql/_table/pre_reg_students/${id}?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },

  getPreReg: async (schoolId, searchFilter) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/pre_reg_students?filter=(school_id=${schoolId})${searchFilter}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  checkRollNumber: async (schoolId, rollNumber) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/pre_reg_students?filter=(roll_number=${rollNumber})AND(school_id=${schoolId})&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data.resource;
  },

  checkUserExists: async (email) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/check_email_exists?email=${encodeURIComponent(
        email
      )}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  addNewFamilyAndStudent: async (
    allow_negative_balance,
    dairy_allergy,
    first_name,
    grade,
    last_name,
    max_spend,
    nut_allergy,
    email, // In wrong place alphabetically, because is parentEmail in component
    restriction,
    roll_number,
    school_id,
    // Given up on alphabetical order for now!
    gluten_allergy,
    vegetarian,
    vegan
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.post(
      `${API_BASE_URL}/new_family_and_student?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        resource: [
          {
            email,
            student: [
              {
                allow_negative_balance,
                dairy_allergy,
                first_name,
                grade,
                last_name,
                max_spend,
                nut_allergy,
                roll_number,
                school_id,
                gluten_allergy,
                vegetarian,
                vegan,
                // restrictions_by_student_id: [{ restriction }],
              },
            ],
          },
        ],
      }
    );
    return response;
  },

  addStudentToFamily: async (
    allow_negative_balance,
    dairy_allergy,
    family_id,
    first_name,
    grade,
    last_name,
    max_spend,
    nut_allergy,
    restriction,
    roll_number,
    school_id,
    gluten_allergy,
    vegetarian,
    vegan
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.post(
      `${API_BASE_URL}/mysql/_table/students?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        resource: [
          {
            allow_negative_balance,
            dairy_allergy,
            family_id,
            first_name,
            grade,
            last_name,
            max_spend,
            nut_allergy,
            roll_number,
            school_id,
            gluten_allergy,
            vegetarian,
            vegan,
            // restrictions_by_student_id: [{ restriction }],
          },
        ],
      }
    );
    return response;
  },

  getFamilies: async (franchiseId, filter) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/opening_balances?search_franchise_id=${franchiseId}&search_name=${filter}&wrapper=resource&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  getFamiliesWithChildren: async (franchiseId, filter, cancelToken) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/family?order=name%20ASC%20&filter=(franchise_id=${franchiseId})${filter}&related=students_v2_by_family_id,parent_admins_by_family_id&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { cancelToken: cancelToken.token }
    );

    return data;
  },

  getFamiliesByStudentId: async (schoolId, cancelToken) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_proc/family_by_student_id?wrapper=resource&school_id=${schoolId}&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      { cancelToken: cancelToken.token }
    );

    return data;
  },

  getPreRegStudentsWithSameEmail: async (email) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { data } = await axios.get(
      `${API_BASE_URL}/mysql/_table/pre_reg_students?filter=(parent_email=${email})&api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return data;
  },

  setOpeningBalance: async (
    family_id,
    credit,
    debit,
    local_date,
    local_time,
    note,
    franchise_id,
    uuid
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    // This needs to be changed to the opening balance script and tested - creates gift card if not in place.
    const response = await axios.post(
      `${API_BASE_URL}/mysql/_table/credit_and_debit_ledger?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`,
      {
        resource: [
          {
            franchise_id,
            family_id,
            credit,
            debit,
            local_date,
            local_time,
            note,
            uuid,
          },
        ],
      }
    );
    return response;
  },
};

export default students;
