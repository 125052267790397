import React from "react";
import { menuItems } from "../constants";

// Tabs for selcting location menus by service

const ServiceSelector = ({ service, updateService }) => {
  const MenuItem = ({ label, serviceTime }) => {
    return (
      <span
        className={`${
          service === serviceTime
            ? "border-b-4 border-blue-600 text-blue-600"
            : null
        } px-2 py-2 mx-6 text-center transition-all ease-in-out duration-500`}
        onClick={() => updateService(serviceTime)}
      >
        {label}
      </span>
    );
  };

  return (
    <>
      <span className="inline-block w-1/2">
        {menuItems.map((item) => (
          <MenuItem
            key={item.serviceTime}
            label={item.label}
            serviceTime={item.serviceTime}
          />
        ))}
      </span>
    </>
  );
};

export default ServiceSelector;
