import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";
import NavBar from "../components/Navbar";
import Modal from "../components/Modal";
import AddMenuItem from "../components/AddMenuItem";
import { activeOptions } from "../constants";
import Search from "../components/Search";
import EditMainMenu from "../components/EditMainMenu";
import Dropdown from "../components/Dropdown";
import HeaderButton from "../components/HeaderButton";
import MainMenuList from "../components/MainMenuList";

const MasterMenu = () => {
  const history = useHistory();
  const school = JSON.parse(localStorage.getItem("school"));
  const { schoolId } = school;
  const [franchiseId] = useState(localStorage.getItem("franchiseId"));
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [menu, setMenu] = useState([]);
  const [available, setAvailable] = useState("all");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [menuItem, setMenuItem] = useState({});

  const fetchMainMenu = useCallback(async () => {
    try {
      const menu = await api.menus.getMasterMenu(franchiseId, filter);
      setMenu(menu.resource);
    } catch (err) {
      alert("Your session has expired.  Please close this message and login");
      localStorage.removeItem("user");
      history.push("/login");
    }
  }, [filter, franchiseId, history]);

  useEffect(() => {
    if (!openModal) {
      fetchMainMenu();
    }
  }, [openModal, filter, search, fetchMainMenu]);

  useEffect(() => {
    let filter = "";
    if (available === "true") {
      filter = "AND(active=true)";
    } else if (available === "false") {
      filter = "AND(active=false)";
    }
    setFilter(filter);
  }, [available]);

  const deleteItem = async (id) => {
    try {
      await api.menus.deleteItem(id);
      fetchMainMenu();
      setOpenModal(false);
    } catch (err) {
      console.log(`Error is ${err}`);
      if (
        err?.response?.data?.error?.message.includes(
          "a foreign key constraint fails"
        )
      ) {
        alert("You cannot delete this item, because it is already in use");
      } else {
        alert("Unknown Error");
      }
    }
  };

  const addItem = () => {
    setEdit(false);
    setOpenModal(true);
  };

  return (
    <>
      <div>
        <div className="sticky block top-0 w-full bg-white z-10">
          <div>
            <NavBar
              title={"Master Menu"}
              schoolId={schoolId}
              schoolName={"Franchise"}
            />
          </div>
          <div className="w-full px-4 bg-white h-16">
            <span className="flex my-2">
              <Search
                id="search"
                placeholder={"Search"}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <span className="flex-1 bg-white w-full my-auto text-right">
                <HeaderButton title="Add Item" onClick={addItem} />
              </span>
            </span>
          </div>
          <div className="w-full bg-gray-700 text-white py-2">
            <span className="inline-block text-lg align-middle w-1/2">
              <span className="inline-block w-1/2 pl-6">Name</span>
              <span className="inline-block w-1/2 text-left">Price</span>
            </span>
            <span className="inline-block text-right w-1/2 text-xl pr-6">
              <Dropdown
                id="filter"
                title={"Filter"}
                data={activeOptions}
                onChange={(e) => setAvailable(e.target.value)}
              />
            </span>
          </div>
        </div>

        {openModal && (
          <Modal
            openModal={setOpenModal}
            title={!edit ? "Add a menu item" : "Edit Item"}
            content={
              !edit ? (
                <AddMenuItem
                  openModal={setOpenModal}
                  franchiseId={franchiseId}
                />
              ) : (
                <EditMainMenu
                  openModal={setOpenModal}
                  menuItem={menuItem}
                  deleteItem={deleteItem}
                />
              )
            }
          />
        )}
        <div className="w-full">
          <div className="w-full">
            <hr />
            {menu.length > 0 ? (
              <MainMenuList
                menu={menu}
                menuItem={setMenuItem}
                edit={setEdit}
                openModal={setOpenModal}
              />
            ) : (
              <span className="inline-block w-full text-2xl mt-12 text-center">
                No menu items. Use 'Add Item' button to create a master menu,
                then create your location menus in the 'Location Menu' page.
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MasterMenu;
