import React, { useState, useEffect } from "react";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import LoginForm from "./loginForm";
import ForgotPassword from "./forgotPassword";

const LoginPage = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const { session_token: sessionToken } = user || { sessionToken: null };

  const [path, setPath] = useState("login");
  const [, setEmail] = useState("");

  useEffect(() => {
    const tryLogin = async () => {
      try {
        if (user && sessionToken) {
          await api.user.refresh(sessionToken);
          getFranchise();
        } else {
          setPath("login");
        }
      } catch (err) {
        setPath("login");
      }
    };
    tryLogin();
  }, [user]);

  const getFranchise = async () => {
    try {
      const result = await api.franchise.getFranchise();
      const franchiseId = result?.data?.resource[0]?.franchise_id;
      if (franchiseId) {
        localStorage.setItem("franchiseId", franchiseId);
        switchView();
      } else {
        throw new Error("Franchise ID not found.");
      }
    } catch (err) {
      console.log(err);
      // Display an error message to the user
      setPath("login");
    }
  };

  const switchView = () => {
    history.push("/locations");
  };

  return (
    <>
      <div className="mx-auto flex flex-wrap items-center justify-between bg-gpsBrown w-full">
        <div className="flex-1">
          <img src="/logo192.png" className="w-12 h-12 m-5" alt="logo" />
        </div>
        <div className="w-full flex lg:w-auto  px-4 lg:static lg:block lg:justify-start text-white text-4xl">
          Welcome to GPSpay Admin
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="mt-4 text-center">
        {path === "login" && <LoginForm path={setPath} />}
        {path === "forgot" && (
          <ForgotPassword path={setPath} email={setEmail} />
        )}
      </div>
    </>
  );
};

export default LoginPage;
