import React from "react";
import DateManager from "../utils/convertDate";

export const SalesList = ({ data }) => {
  return data.map((item, index) => {
    return (
      <div className={`text-lg px-6 py-4 even:bg-gray-50`} key={index}>
        <div className="flex">
          <span className="flex-1 my-auto">
            {DateManager.USDate(item.local_date)}
          </span>
          <span className="flex-1 my-auto">{}</span>
          <span className="flex-1 my-auto">{}</span>
          <span className="flex-1 my-auto text-right">{}</span>
          <span className="flex-1 my-auto text-right">{}</span>
          <span className="flex-1 my-auto text-right">{item.daily_total}</span>
        </div>
      </div>
    );
  });
};

export const SalesListHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Date</span>
      <span className="flex-1"></span>
      <span className="flex-1"></span>
      <span className="flex-1 text-right"></span>
      <span className="flex-1 text-right"></span>
      <span className="flex-1 text-right">Daily Guest Total $</span>
    </div>
  );
};
