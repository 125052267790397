import React from "react";
import OpeningBalanceInput from "./OpeningBalanceInput";
import ButtonCancel from "./ButtonCancel";
const AdjustBalanceModal = ({
  id,
  franchiseId,
  familyId,
  fetchFamilies,
  loading,
  setOpenModal,
}) => {
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen bg-black bg-opacity-60 fixed top-0 rounded z-50">
      <div className="flex flex-col justify-center items-center w-96 h-72 bg-white rounded">
        <div className="mt-8 text-xl">Enter Adjustment and Reason</div>
        <OpeningBalanceInput
          id="adjustment"
          franchiseId={franchiseId}
          familyId={familyId}
          fetchFamilies={fetchFamilies}
          loading={loading}
          setOpenModal={setOpenModal}
        />
        <ButtonCancel title="Close" onClick={() => setOpenModal(false)} />
      </div>
    </div>
  );
};

export default AdjustBalanceModal;
