export const convertFalseToEmptyString = (obj) => {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(convertFalseToEmptyString);
    } else {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = convertFalseToEmptyString(value);
        return acc;
      }, {});
    }
  } else {
    return obj === false ? "" : obj;
  }
};

// Set one week span for date pickers
export const getCurrentWeek = () => {
  const today = new Date();
  const currentDayOfWeek = today.getDay(); // 0-6, Sunday is 0
  const daysToMonday = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;
  const daysToFriday = currentDayOfWeek === 0 ? 5 : 5 - currentDayOfWeek;

  // Calculate Monday and Friday
  const monday = new Date(today);
  monday.setDate(monday.getDate() + daysToMonday);
  const friday = new Date(today);
  friday.setDate(friday.getDate() + daysToFriday);

  return { monday, friday };
};

// Capitalize words in NavLinks
export const capitalizeWords = (str) => {
  return str
    .split(/[\s_]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Use the appropriate prefix for the page title
export const getPageTitle = (currentPage, schoolName) => {
  const titlePrefix =
    currentPage === "Show Duplicate Students"
      ? "Global"
      : currentPage === "Families and Children" ||
        currentPage === "Family List and Adjust Balances" ||
        currentPage === "Locations"
      ? "Franchise"
      : schoolName;

  return (
    <div>
      {titlePrefix} - {currentPage}
    </div>
  );
};
