import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";
import NavBar from "../components/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatDate from "../utils/helper";
import { serviceOptions } from "../constants";
import { print } from "../utils/print";
import Dropdown from "../components/Dropdown";

const Orders = () => {
  const school = JSON.parse(localStorage.getItem("school"));
  const [schoolId] = useState(school.schoolId);
  const [schoolName] = useState(school.schoolName);
  const [startDate, setStartDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [service, setService] = useState("breakfast");
  const history = useHistory();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderList = await api.orders.getOrders(
          schoolId,
          formatDate(startDate),
          service
        );
        setOrders(orderList.resource);
      } catch (err) {
        alert(
          `Your session has expired.  Please close this message and login.`
        );
        localStorage.removeItem("user");
        history.push("/login");
      } finally {
      }
    };
    fetchOrders();
  }, [service, startDate]);

  return (
    <>
      <div className="sticky top-0 w-full bg-white z-10">
        <div>
          <NavBar
            title={"Aggregated Orders"}
            schoolId={schoolId}
            schoolName={schoolName}
          />
        </div>

        <div className="w-full px-4 bg-white">
          <span className="flex my-3">
            <span className="my-4 px-3 text-xl">Date: </span>
            <span className="my-4 flex-1">
              <DatePicker
                id="date-picker"
                className="border border-solid border-gray-400 rounded w-32 text-xl"
                closeOnScroll={true}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </span>
            <span className="bg-white m-3 flex-1 text-lg">
              <Dropdown
                id="service-dropdown"
                title={"Service: "}
                data={serviceOptions}
                onChange={(e) => setService(e.target.value)}
              />
            </span>
            <span className="flex flex-row">
              <span className="my-3 flex-1">
                <span
                  className="w-28 bg-blue-600 text-white active:bg-blue-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 px-3 text-white bg-blue-600 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm text-center float-right"
                  onClick={() => history.push("/labels")}
                >
                  Labels
                </span>
              </span>
              <span className="my-3 flex-1">
                <span
                  className=" mx-2 w-28 bg-blue-600 text-white active:bg-blue-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 px-3 text-white bg-blue-600 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm text-center float-right"
                  onClick={print}
                >
                  Print
                </span>
              </span>
            </span>
          </span>
        </div>
        <div id="printable-div">
          <div>
            <div className="flex flex-row py-2 px-8 justify-between bg-gray-300 text-black">
              <div className="flex py-1 px-6 text-lg w-full">
                <span className="flex-1">Item</span>
                <span className="flex-1">Date</span>
                <span className="flex-1 capitalize">Service</span>
                <span className="flex-1 text-right">Number</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {orders ? (
          orders.map((e) => {
            return (
              <div className="w-full px-6" key={e._id}>
                <div className="flex py-2 px-6 text-lg w-full">
                  <span className="flex-1">{e.title}</span>
                  <span className="flex-1">{e.date}</span>
                  <span className="flex-1 capitalize">
                    {e.service.replace("_", " ")}
                  </span>
                  <span className="flex-1 text-right">{e.number_required}</span>
                  <hr />
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className="w-full text-lg text-center mt-16">
              There are no orders for this date and service
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Orders;
