import React, { useState } from "react";
import api from "../Api";
import { leadTime } from "../constants";
import Dropdown from "./Dropdown";
import { validateNumber } from "../utils/validations";
import Input from "./Input";
import ButtonTwo from "./ButtonTwo";
import { v4 as uuidv4 } from "uuid";

/**
 * The first 'school' added is not a school, but is used to hold the credentials of the default main location for the franchise.
 * This location receives the funds when a deposit is made.
 * The string "Default Main Location" is used on the server - do not change it.
 **/
const AddSchool = ({ openModal, franchiseId, firstSchool }) => {
  const [uuid] = useState(uuidv4());
  const [itemName, setItemName] = useState(
    // Do not change this string - it is used on the server - see above
    firstSchool ? "Default Main Location" : ""
  );
  const [itemCode, setItemCode] = useState(firstSchool ? uuid : "");
  const [itemCredit, setItemCredit] = useState(firstSchool ? 0 : "");
  const [orderLimit, setOrderLimit] = useState(0);
  const [error, setError] = useState("");

  const handleAddItem = () => {
    setError("");
    if (firstSchool) {
      addItem();
      return;
    } else if (!itemCode || !itemName || !itemCredit) {
      setError("Please fill all fields!");
      return;
    }
    if (!validateNumber(itemCredit)) {
      setError("Please enter numbers and decimal point only!");
      return;
    }
    addItem();
  };

  const addItem = async () => {
    try {
      await api.schoolInfo.addSchool(
        itemName,
        itemCode.toUpperCase(),
        itemCredit,
        orderLimit,
        franchiseId
      );
    } catch (err) {
      alert(`There was an error: ${err.response.data.error.message}`);
    } finally {
      openModal(false);
    }
  };

  return (
    <>
      <div>
        {firstSchool && (
          <div className="font-bold text-center mb-4">
            This is your default location for fund receipts. You can add your
            schools and connect to Square locations after clicking Save.
          </div>
        )}
        <form className="mb-3 pt-0 w-full">
          <Input
            id="school-name"
            placeholder="School Name"
            disabled={firstSchool}
            val={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
          <Input
            id="school-code"
            placeholder="School Code"
            disabled={firstSchool}
            val={itemCode}
            onChange={(e) => setItemCode(e.target.value)}
          />
          {!firstSchool && (
            <>
              <Input
                id="school-credit"
                placeholder="Maximum Credit $"
                disabled={firstSchool}
                val={itemCredit}
                onChange={(e) => setItemCredit(e.target.value)}
              />
              <div className="flex w-full flex-wrap items-stretch text-lg py-4">
                <Dropdown
                  disabled={firstSchool}
                  id="school-lead-time"
                  title="Order lead time"
                  data={leadTime}
                  value={orderLimit}
                  onChange={(e) => setOrderLimit(e.target.value)}
                />
              </div>
            </>
          )}
          <div className="text-red-600 text-center">{error} </div>
          <div className="w-full flex flex-col items-center mt-8 cursor-pointer">
            <ButtonTwo title="Save" onClick={() => handleAddItem()} />
          </div>
        </form>
      </div>
    </>
  );
};
export default AddSchool;
