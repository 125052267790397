import React, { useState, useEffect } from "react";
import api from "../Api";
import { grades, max } from "../constants";
import Input from "./Input";
import ButtonTwo from "./ButtonTwo";
import Dropdown from "./Dropdown";
import Checkbox from "./Checkbox";

// Now called customers in the menu and address

const AddStudent = ({ openModal, addStudent }) => {
  const school = JSON.parse(localStorage.getItem("school"));
  const [schoolId] = useState(school.schoolId);
  const [parentEmail, setParentEmail] = useState("");
  const [openInput, setOpenInput] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [grade, setGrade] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [familyId, setFamilyId] = useState();
  const [dairyAllergy, setDairyAllergy] = useState(false);
  const [nutAllergy, setNutAllergy] = useState(false);
  const [glutenAllergy, setGlutenAllergy] = useState(false);
  const [vegetarian, setVegetarian] = useState(false);
  const [vegan, setVegan] = useState(false);
  const [maxSpend, setMaxSpend] = useState(999);
  const [allowNegativeBalance, setAllowNegativeBalance] = useState(false);
  const [restriction, setRestriction] = useState("");

  useEffect(() => {
    console.log(familyId);
  }, [familyId]);

  const checkEmailExists = async () => {
    try {
      const result = await api.students.checkUserExists(parentEmail);
      result.family_id > 0 ? setFamilyId(result.family_id) : setFamilyId(0);
      setOpenInput(true);
    } catch (err) {
      console.log(`Error is ${err}`);
    }
  };

  const addNewStudent = async () => {
    try {
      await api.students.addNewFamilyAndStudent(
        allowNegativeBalance,
        dairyAllergy,
        firstName,
        grade,
        lastName,
        maxSpend,
        nutAllergy,
        parentEmail,
        restriction,
        rollNumber,
        schoolId,
        glutenAllergy,
        vegetarian,
        vegan
      );
      openModal(false);
      addStudent(false);
    } catch (err) {
      console.log(`Error is ${err}`);
    }
  };

  const addStudentToExistingFamily = async () => {
    try {
      await api.students.addStudentToFamily(
        allowNegativeBalance,
        dairyAllergy,
        familyId,
        firstName,
        grade,
        lastName,
        maxSpend,
        nutAllergy,
        restriction,
        rollNumber,
        schoolId,
        glutenAllergy,
        vegetarian,
        vegan
      );
      openModal(false);
      addStudent(false);
    } catch (err) {
      console.log(`Error is ${err}`);
    }
  };

  const handleSubmit = () => {
    if (familyId === 0) {
      addNewStudent();
    }
    if (familyId > 0) {
      addStudentToExistingFamily();
    }
    if (isNaN(familyId)) {
      alert(
        `Unknown error in add students, please screenshot and report.  Family id is ${familyId}`
      );
    }
  };

  return (
    <>
      <div className="">
        {!openInput ? (
          <form className="py-8 w-96">
            <Input
              autoComplete={"off"}
              id="email"
              placeholder={"Enter Primary Email"}
              val={parentEmail}
              onChange={(e) => setParentEmail(e.target.value)}
            />
            <div className="flex flex-col items-center mt-20">
              <ButtonTwo title="Submit" onClick={() => checkEmailExists()} />
            </div>
          </form>
        ) : (
          <form className="mb-4">
            <Input
              id="first-name"
              autoComplete={"off"}
              placeholder={"First Name"}
              val={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              autoComplete={"off"}
              id="last-name"
              placeholder={"Last Name"}
              val={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <Input
              id="roll-number"
              placeholder={"Customer ID"}
              val={rollNumber}
              onChange={(e) => setRollNumber(e.target.value)}
            />
            <span className="flex flex-1 mt-2 mb-8">
              <Dropdown
                id="grade"
                title="Service Assignment"
                data={grades}
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              />
            </span>
            <div className="mb-8">
              <Checkbox
                id="dairy-allergy"
                label="Dairy Allergy"
                value={dairyAllergy}
                action={setDairyAllergy}
              />
              <Checkbox
                id="nut-allergy"
                label="Nut Allergy"
                value={nutAllergy}
                action={setNutAllergy}
              />
              <Checkbox
                id="gluten-allergy"
                label="Gluten Allergy"
                value={glutenAllergy}
                action={setGlutenAllergy}
              />
              <Checkbox
                id="vegetarian"
                label="Vegetarian"
                value={vegetarian}
                action={setVegetarian}
              />
              <Checkbox
                id="vegan"
                label="Vegan"
                value={vegan}
                action={setVegan}
              />
            </div>
            <div className="mb-4">
              <Dropdown
                id="max-spend"
                title="Maximum Spend"
                value={maxSpend}
                data={max}
                onChange={(e) => setMaxSpend(e.target.value)}
              />
              <span className="ml-8">
                <Checkbox
                  id="allow-negative-balance"
                  label="Allow Negative Balance?"
                  value={allowNegativeBalance}
                  action={setAllowNegativeBalance}
                />
              </span>
            </div>
            <Input
              id="other-restrictions"
              placeholder={restriction ? restriction : "Other Restrictions"}
              val={restriction}
              onChange={(e) => setRestriction(e.target.value)}
            />
            <div className="flex flex-col items-center mt-4">
              <ButtonTwo title="Submit" onClick={() => handleSubmit()} />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default AddStudent;
