import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, onPageChange, forcePage }) => {
  return (
    <div className="fixed w-full bottom-0 border border-t-2 border-gray-100">
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        previousLabel="< previous"
        onPageChange={onPageChange}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        forcePage={forcePage}
        containerClassName="inline-flex flex-row items-center justify-center w-full h-12 bg-white"
        pageClassName="block border border-solid border-gray-300 flex items-center justify-center w-10 mx-2 rounded text-center"
        activeClassName="bg-blue-600 text-white"
      />
    </div>
  );
};

export default Pagination;
