import React from "react";
import orderLimitFunctions from "../utils/displayCutOff";
import ListElement from "./ListElement";
import SquareIndicator from "./SquareIndicator";
import { leadTime } from "../constants/Dropdowns";

// url/sales-by-item

const SchoolsList = ({ schools, edit, school, schoolId, handleAction }) => {
  const findLeadTimeLabel = (value) => {
    const found = leadTime.find((item) => item.value === value);
    return found ? found.label : "Unknown";
  };

  return (
    <>
      {schools.length > 0 &&
        schools.map((e, index) => {
          return (
            <div className="block w-full hover:bg-gray-100" key={e._id}>
              <hr />
              <div className=" flex flex-row justify-between px-8 h-24 items-center">
                <ListElement e={e} name={e.name} handleAction={handleAction} />
                <ListElement
                  e={e}
                  index={index}
                  name={e.code}
                  handleAction={handleAction}
                />
                <ListElement
                  e={e}
                  index={index}
                  name={`$${e.student_credit_limit.toFixed(2)}`}
                  handleAction={handleAction}
                />
                <ListElement
                  e={e}
                  index={index}
                  name={findLeadTimeLabel(e.last_order_time)}
                  handleAction={handleAction}
                />

                <span className="flex-1 px-1 text-right">
                  <SquareIndicator
                    schoolId={e._id}
                    onClick={() => {
                      school(e);
                      handleAction(e, "goToSettings");
                    }}
                  />
                </span>
                <span className="flex-1 text-right">
                  <ListElement
                    e={e}
                    index={index}
                    name={e.pos_fees ? "Yes" : "No"}
                    handleAction={handleAction}
                  />
                </span>

                <span className="flex-1 px-1 text-right">
                  <button
                    className={`pl-1 text-gray-600 material-symbols-outlined ${
                      index === 0 && "hidden"
                    }`}
                    onClick={() => {
                      edit(true);
                      schoolId(e._id);
                      school(e);
                    }}
                  >
                    edit
                  </button>
                </span>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SchoolsList;
