import React, { useEffect, useState } from "react";
import api from "../Api";
import { v4 as uuidv4 } from "uuid";

export const DuplicateStudentList = ({ data, refreshData }) => {
  const [expandedStates, setExpandedStates] = useState({});
  const [confirmationRequired, setConfirmationRequired] = useState({});
  const [currentConfirmationId, setCurrentConfirmationId] = useState(null);
  const [arr, setArr] = useState([]); // Array of family IDs to transfer transactions from
  const [studentArr, setStudentArr] = useState([]); // Array of student IDs to transfer pre-orders from

  // Array has two elements, the first is the family ID to transfer transactions from, the second is the family ID to transfer transactions to, and
  // then the studentArr has two elements, the first is the student ID to transfer pre-orders from, the second is the student ID to transfer pre-orders to
  const handleTransferClick = (item) => () => {
    let familyId = item.family_id;
    let studentId = item._id;
    if (arr.length < 2) {
      setArr([...arr, familyId]);
      setStudentArr([...studentArr, studentId]);
    }
  };

  useEffect(() => {
    console.log(arr);
  }, [arr]);

  //
  const transferFunds = async () => {
    if (arr.length !== 2 || arr[0] === arr[1]) {
      alert("You must select two family IDs to transfer funds.");
      return;
    }
    const tranferFromFamilyId = arr[0];
    const transferToFamilyId = arr[1];
    try {
      await api.reports.tranferTransactionsToCorrectAccount(
        tranferFromFamilyId,
        transferToFamilyId
      );

      if (typeof refreshData === "function") {
        refreshData();
      }
    } catch (err) {
      alert(`Error transferring funds: ${err}`);
    } finally {
      setArr([]);
    }
  };

  const transferPreOrders = async () => {
    if (arr.length !== 2 || arr[0] === arr[1]) {
      alert("You must select two student IDs to transfer pre-orders.");
      return;
    }
    const tranferFromStudentId = studentArr[0];
    const transferToStudentId = studentArr[1];
    try {
      await api.reports.transferPreOrdersToCorrectAccount(
        tranferFromStudentId,
        transferToStudentId
      );

      if (typeof refreshData === "function") {
        refreshData();
      }
    } catch (err) {
      alert(`Error transferring pre-orders: ${err}`);
    } finally {
      setArr([]);
    }
  };

  const transferPosOrders = async () => {
    if (arr.length !== 2 || arr[0] === arr[1]) {
      alert("You must select two student IDs to transfer POS orders.");
      return;
    }
    const tranferFromStudentId = studentArr[0];
    const transferToStudentId = studentArr[1];
    try {
      await api.reports.transferPosOrdersToCorrectAccount(
        tranferFromStudentId,
        transferToStudentId
      );

      if (typeof refreshData === "function") {
        refreshData();
      }
    } catch (err) {
      alert(`Error transferring POS orders: ${err}`);
    } finally {
      setArr([]);
    }
  };

  useEffect(() => {
    if (arr.length === 2 && studentArr.length === 2) {
      const userConfirmed = window.confirm(
        `Transfer transactions and orders from family ${arr[0]} to ${arr[1]}, and from student ${studentArr[0]} to ${studentArr[1]}?`
      );
      if (userConfirmed) {
        transferFunds();
        transferPreOrders();
      } else {
        console.log("User aborted the transfer.");
        setArr([]);
        setStudentArr([]);
      }
    }
  }, [arr]);

  // Check for orders with the student ID - we cannot delete a student with orders
  const checkForOrdersWithStudentId = async (id) => {
    try {
      const result = await api.reports.checkForOrdersWithStudentId(id);
      if (result.resource.length > 0) {
        alert(`Cannot delete student ${id} because they have orders`);
        setConfirmationRequired({});
        setCurrentConfirmationId(null);
        return true;
      }
      return false;
    } catch (err) {
      alert(`Error checking for orders with student id ${id}: ${err}`);
      return true;
    }
  };

  const deleteStudent = async (item) => {
    let uuid = uuidv4();
    try {
      await api.reports.deleteStudent(item._id, uuid, item.roll_number, true);
      setConfirmationRequired({
        ...confirmationRequired,
        [item._id]: false,
      });
      setCurrentConfirmationId(null);
      if (typeof refreshData === "function") {
        refreshData();
      }
    } catch (err) {
      alert(`Error deleting student ${item._id}: ${err}`);
    }
  };

  const toggleExpand = (id) => {
    setExpandedStates({
      ...expandedStates,
      [id]: !expandedStates[id],
    });
  };

  const handleDeleteClick = (item) => {
    if (confirmationRequired[item._id]) {
      checkForOrdersWithStudentId(item._id).then((hasOrders) => {
        if (!hasOrders) {
          const isConfirmed = window.confirm(
            `Are you sure you want to delete ${item._id}?`
          );
          if (isConfirmed) {
            deleteStudent(item);
          } else {
            setConfirmationRequired(
              {
                ...confirmationRequired,
                [item._id]: false,
              },
              setCurrentConfirmationId(null)
            );
          }
        }
      });
    } else {
      setConfirmationRequired({
        ...confirmationRequired,
        [item._id]: true,
      });
      setCurrentConfirmationId(item._id); // Set the current ID requiring confirmation
    }
  };

  let lastRollNumber = null;
  let isAlternate = false;

  return data.map((item, index) => {
    if (lastRollNumber !== item.roll_number) {
      isAlternate = !isAlternate;
      lastRollNumber = item.roll_number;
    }

    const bgColorClass = isAlternate ? "bg-white" : "bg-gray-200";
    const color = currentConfirmationId === item._id ? "text-red-500" : "";
    const familyIdColor =
      arr[0] === item.family_id
        ? "text-red-500"
        : arr[1] === item.family_id
        ? "text-green-500"
        : "";

    return (
      <div className={`text-lg px-6 ${bgColorClass}`} key={item._id}>
        <div className="flex">
          <span
            className={`flex-1 my-auto cursor-pointer ${color}`}
            onClick={() => handleDeleteClick(item)}
          >
            {item._id}
          </span>
          <span className="flex-1 my-auto">
            {item.first_name} {item.last_name}
          </span>
          <span className="flex-1 my-auto">{item.roll_number}</span>
          <span className="flex-1 my-auto">{item.school_name}</span>
          <span
            className={`flex-1 my-auto text-right cursor-pointer ${familyIdColor}`}
            onClick={handleTransferClick(item)}
          >
            {item.family_id}
          </span>
          <span className="flex-1 my-auto text-right">{item.total_credit}</span>
          <span
            className="flex-1 my-auto text-right"
            onClick={() => toggleExpand(item._id)}
          >
            {(-Math.abs(item.total_debit)).toFixed(2)}
          </span>
        </div>
        {expandedStates[item._id] && (
          <div className="text-xs text-right flex flex-1 flex-col">
            {item.debit_list
              ? item.debit_list
                  .split(",")
                  // NOTE: This is a hack to remove the 0.00 values from the list because they also contain dates
                  .filter((value) => value.trim().substring(0, 4) !== "0.00")
                  .map((value, index) => (
                    <span key={index}>{value.trim()}</span>
                  ))
              : ""}
          </div>
        )}
      </div>
    );
  });
};

export const DuplicateStudentListHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">ID</span>
      <span className="flex-1">Name</span>
      <span className="flex-1">Roll Number</span>
      <span className="flex-1 ">School Name</span>
      <span className="flex-1 text-right">Family ID</span>
      <span className="flex-1 text-right">Credit</span>
      <span className="flex-1 text-right">Debit</span>
    </div>
  );
};
