import React from "react";

const ButtonDelete = ({ onClick, canDelete }) => {
  return (
    <button
      className={`${
        canDelete ? "bg-red-600" : "bg-gray-300"
      } text-white active:bg-blue-700 font-bold uppercase text px-6 py-3 my-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
      onClick={onClick}
    >
      Delete
    </button>
  );
};

export default ButtonDelete;
