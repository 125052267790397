import auth from "./auth";
import franchise from "./franchise";
import menus from "./menus";
import orders from "./orders";
import schoolInfo from "./schoolInfo";
import students from "./students";
import user from "./user";
import reports from "./reports";

const api = {
  auth,
  franchise,
  menus,
  orders,
  schoolInfo,
  students,
  user,
  reports,
};

export default api;
