import React, { useState } from "react";
import NavBar from "../components/Navbar";

const MainLayout = ({
  pageTitle,
  content,
  leftContainer,
  rightContainer,
  headers,
}) => {
  const school = JSON.parse(localStorage.getItem("school"));
  const [schoolId] = useState(school.schoolId);
  const [schoolName] = useState(school.schoolName);

  return (
    <>
      <div className="sticky top-0 w-full bg-white z-10">
        <div>
          <NavBar
            title={pageTitle}
            schoolId={schoolId}
            schoolName={schoolName}
          />
        </div>
        <div className="w-full flex px-4 my-auto bg-white h-16">
          <div className="flex flex-1">
            {leftContainer}
            <div className="flex flex-1 my-auto text-right content-end">
              {rightContainer}
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-300 text-black px-6 py-3">{headers}</div>
      </div>
      <div className="relative">{content}</div>
    </>
  );
};
export default MainLayout;
