import React, { useEffect, useState } from "react";
import api from "../Api";

const SelectMenu = ({
  schoolName,
  service,
  openModal,
  newMenuItem,
  openEditItem,
}) => {
  const [menu, setMenu] = useState([]);
  const [franchiseId] = useState(localStorage.getItem("franchiseId"));

  useEffect(() => {
    const fetchMainMenu = async () => {
      try {
        const menu = await api.menus.getMasterMenu(
          franchiseId,
          `&(active=true)AND(${service}=true)`
        );
        setMenu(menu.resource);
      } catch (err) {
        // alert("Your session has expired.  Please close this message and login");
      }
    };
    fetchMainMenu();
  }, []);

  const handleAddItem = (e) => {
    newMenuItem(e);
    openModal(false);
    openEditItem(true);
  };

  return menu.length > 0 ? (
    <>
      <div className="fixed z-80 w-full h-full -mt-48 bg-black overflow-y-scroll bg-black bg-opacity-50 pt-4 pb-32">
        <div className="w-1/2 bg-white mx-auto rounded">
          <p
            className="text-right pt-4 px-8 text-2xl"
            onClick={() => openModal(false)}
          >
            X
          </p>
          <div className="text-center text-2xl pt-4">
            Select an Item to add to {service} menu at {schoolName}
          </div>
          <div className="relative top-8">
            <div className="px-6">
              <hr />
              <div className="mx-6 pb-32">
                {menu.map((e) => {
                  return (
                    <div onClick={() => {}} className="" key={`menu${e._id}`}>
                      <span className="inline-block p-5 text-lg w-full align-middle">
                        <span className="inline-block w-1/2">{e.title}</span>
                        <span className="inline-block w-1/6 text-right pr-2">
                          ${e.price ? e.price.toFixed(2) : null}
                        </span>
                        <span
                          className="absolute right-16 px-4 bg-blue-600 rounded-full text-white"
                          onClick={() => handleAddItem(e)}
                        >
                          Add
                        </span>
                      </span>

                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <span className="inline-block text-2xl text-center w-full mt-12">
        You don't have any menu items to select from. Please add to 'Master
        Menu' first.
      </span>
    </>
  );
};
export default SelectMenu;
