import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../components/Input";
import api from "../Api";

const AuthorizeSeller = () => {
  const history = useHistory();
  const crypto = require("crypto");
  const uuid = crypto.randomBytes(16).toString("hex");
  const [locationId, setLocationId] = useState("");

  const school = JSON.parse(localStorage.getItem("school"));
  const schoolId = school.schoolId;

  // ********** N.B. also revert CSRF test in AuthorizeSellerRedirect.js after testing **********

  const url = `https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-OAZCNN2LWSMuieeCFLbSpw&scope=PAYMENTS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+ORDERS_READ+ORDERS_WRITE+GIFTCARDS_WRITE+GIFTCARDS_READ&session=false&state=${uuid}`;
  // const url = `https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-rfn3YfWvEJGtN_lsxFP1Vg&scope=PAYMENTS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+ORDERS_READ+ORDERS_WRITE+GIFTCARDS_WRITE+GIFTCARDS_READ&session=false&state=${uuid}`;

  const handleAuthorize = () => {
    if (!locationId) {
      alert("Please enter your Square Location ID");
      return;
    }
    localStorage.setItem("locationId", locationId);
    localStorage.setItem("state", uuid);
    window.open(url, "_blank");
  };

  const handleRevoke = () => {
    try {
      const result = api.auth.revokeToken(schoolId);
      console.log(`result: ${JSON.stringify(result)}`);
      alert(
        "GPSpay are no longer authorized to access your Square account.  To reconnect, add your Location Id and click the Authorize button."
      );
    } catch (error) {
      if (error) {
        console.log(`error: ${JSON.stringify(error)}`);
        alert("Something went wrong. Please try again.");
      }
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col p-8">
      <div className="w-full">
        <button
          className="text-gray-500 hover:text-gray-700 font-bold py-2 rounded"
          onClick={() => {
            history.push("/settings");
          }}
        >
          Back to Settings
        </button>
        <h1 className="text-2xl my-4">Authorize My Square Account</h1>
        <span className="flex-1 text-xl">
          You must authorize your Square account to use this application. By
          clicking the button you will be redirected to Square's website.
        </span>
        <form className="flex flex-1 mt-8">
          <span className="flex-1 text-left my-auto">
            <Input
              id="location-id"
              className="w-96"
              placeholder={"Enter your Square Location ID"}
              onChange={(e) => {
                setLocationId(e.target.value);
              }}
              value={locationId}
            />
          </span>
          <span className="flex-1 text-right my-auto">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-48"
              onClick={() => {
                handleAuthorize();
              }}
            >
              Authorize
            </button>
          </span>
        </form>
      </div>
      <div className="flex flex-1 w-full justify-center mt-16">
        <p className="flex-1 text-xl">Remove authorization.</p>
        <div className="flex-1 my-auto text-right">
          <button
            className="bg-red-300 hover:bg-red-500 text-white font-bold py-2 px-4 rounded w-48"
            onClick={() => {
              handleRevoke();
            }}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};
export default AuthorizeSeller;
