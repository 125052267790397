import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../Api";
import ButtonTwo from "./ButtonTwo";

const ConfirmCode = ({ path, email, openModal }) => {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      await api.user.recover(code.toUpperCase(), email, password);
      history.push("/locations");
    } catch (err) {
      alert(err.response.data.error.message);
    }
  };

  return (
    <>
      <div className="w-full m-auto">
        <div className="w-1/2 mx-auto">
          <span className="text-xl inline-block my-2">Sign In</span>
          <form>
            <div className="relative flex w-full flex-wrap items-stretch mt-5">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-envelope"></i>
              </span>
              <input
                defaultValue={email}
                onChange={(e) => {
                  email(e.target.value);
                }}
                type="email"
                placeholder="Email"
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"
              />
              <span className="inline-block text-red-600 text-sm mt-3 w-full text-center"></span>
            </div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-circle"></i>
              </span>
              <input
                type="text"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                placeholder="Code"
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"
              />
            </div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-lock"></i>
              </span>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"
              />
            </div>
          </form>
          <div className="flex flex-col items-center pt-6">
            <ButtonTwo title="submit" onClick={() => handleSubmit()} />
            <span
              className="text-gray-400 text-sm my-10 hover:text-blue-600 inline-block"
              onClick={() => {
                openModal(false);
              }}
            >
              Didn't receive a code?
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmCode;
