import React, { useEffect } from "react";
import DateManager from "../utils/convertDate";

export const FamilyTransactionList = ({ data }) => {
  let lastId = null;
  let isAlternate = false;
  return data.map((item) => {
    if (lastId !== item._id) {
      isAlternate = !isAlternate;
      lastId = item._id;
    }
    const bgColorClass = isAlternate ? "bg-white" : "bg-gray-200";

    return (
      <div className={`text-lg px-6 py-4 ${bgColorClass}`} key={item._id}>
        <div className="flex">
          <span className="flex-1 my-auto">
            {DateManager.USDate(item.local_date)}
          </span>
          <span className="flex-1 my-auto">{item.note}</span>
          <span className="flex-1 my-auto">{item.roll_number}</span>
          <span className="flex-1 my-auto">
            {item.first_name} {item.last_name}
          </span>

          <span className="flex-1 my-auto text-right items-right">
            {item.credit === "0.00" ? "" : item.credit}
          </span>
          <span className="flex-1 my-auto text-right items-right">
            {item.debit === "0.00" ? "" : item.debit}
          </span>
        </div>
      </div>
    );
  });
};

export const FamilyTransactionListHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Date</span>
      <span className="flex-1">Type</span>
      <span className="flex-1 ">Customer ID</span>
      <span className="flex-1 ">Name</span>
      <span className="flex-1 text-right">Credit</span>
      <span className="flex-1 text-right">Debit</span>
    </div>
  );
};
