import React from "react";

const PreRegList = ({ students, openModal, addEdit, student }) => {
  return (
    <div className="w-full" id="printable-div">
      {students.map((e) => {
        return (
          <div key={e._id} className={`${e.claimed && "text-gray-400"}`}>
            <div className="flex py-5 px-6 text-lg">
              <span className="flex-grow-2 flex-shrink-1 w-2/12">
                {e.first_name}
              </span>
              <span className="flex-grow-2 flex-shrink-1 w-2/12">
                {e.last_name}
              </span>
              <span className="flex-grow-2 flex-shrink-1 w-4/12">
                {e.parent_email}
              </span>
              <span className="flex-grow-2 flex-shrink-1 w-2/12">
                {e.roll_number}
              </span>
              <span className="flex-grow-1 flex-shrink-1 w-1/12">
                {e.claimed ? "Yes" : "No"}
              </span>
              <span className="flex-grow text-right">
                <button
                  onClick={() => {
                    openModal(true);
                    addEdit(true);
                    student(e);
                  }}
                  className="material-symbols-outlined"
                >
                  edit
                </button>
              </span>
            </div>

            <hr className="w-full" />
          </div>
        );
      })}
    </div>
  );
};

export default PreRegList;
