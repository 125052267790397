import React, { useEffect, useState } from "react";
import api from "../Api";
import { leadTime } from "../constants";
import Dropdown from "./Dropdown";
import Input from "./Input";
import ButtonTwo from "./ButtonTwo";
import ButtonCancel from "./ButtonCancel";
import { validateNumber } from "../utils/validations";
import ButtonDelete from "./ButtonDelete";

const EditSchool = ({ edit, schoolId, school }) => {
  const {
    last_order_time: lastOrderTime,
    student_credit_limit: creditLimit,
    name,
  } = school;
  const [credit, setCredit] = useState(creditLimit);
  const [orderLimit, setOrderLimit] = useState(lastOrderTime);

  useEffect(() => {
    console.log(`last is ${JSON.stringify(lastOrderTime)}`);
  }, [lastOrderTime]);

  const handleAddItem = () => {
    // Can't test for !orderLimit because 0 is a valid value
    !credit ||
    orderLimit === null ||
    orderLimit === "" ||
    orderLimit === undefined
      ? alert("Please fill all fields!")
      : !validateNumber(credit)
      ? alert("Please enter numbers and decimal point only")
      : changeLimit();
  };

  const changeLimit = async () => {
    try {
      await api.schoolInfo.updateSchool(schoolId, credit, orderLimit);
    } catch (err) {
      alert("Sorry, that wasn't possible");
    } finally {
      edit(false);
    }
  };

  const deleteSchool = async () => {
    try {
      await api.schoolInfo.deleteSchool(schoolId);
    } catch (err) {
      if (
        err.response.data.error.message.includes(
          "a foreign key constraint fails"
        )
      )
        alert(`This school has students or orders and cannot be deleted`);
    } finally {
      edit(false);
    }
  };

  return (
    <>
      <div className="w-full h-full bg-black fixed bg-opacity-50 z-80">
        <div>
          <div className="mb-3 pt-0 w-1/2 h-1/2 mx-auto bg-white p-8 rounded-xl mt-24">
            <span className="inline-block text-gray-700 text-3xl pt-8 w-3/4">
              {name}
            </span>
            <span className="block mt-12">
              <Input
                id="student-credit-limit"
                label="Student Credit Limit $"
                placeholder="Set Credit Limit"
                val={credit}
                onChange={(e) => setCredit(e.target.value)}
              />
            </span>

            <div className="flex w-full flex-wrap items-stretch text-lg py-4">
              <Dropdown
                id="school-lead-time"
                title="Order lead time"
                data={leadTime}
                value={orderLimit}
                onChange={(e) => setOrderLimit(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <ButtonDelete onClick={() => deleteSchool()} canDelete={true} />
            </div>
            <div className="w-full flex flex-col items-center mt-8">
              <ButtonTwo title="Save" onClick={() => handleAddItem()} />
              <ButtonCancel title="Cancel" onClick={() => edit(false)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditSchool;
