import React, { useState } from "react";
import api from "../Api";
import Input from "./Input";
import ButtonTwo from "./ButtonTwo";

const AddEditPreRegStudent = ({ openModal, addEdit, student }) => {
  const school = JSON.parse(localStorage.getItem("school"));
  const [schoolId] = useState(school.schoolId);
  const [firstName, setFirstName] = useState(student.first_name || "");
  const [lastName, setLastName] = useState(student.last_name || "");
  const [email, setEmail] = useState(student.parent_email || "");
  const [rollNumber, setRollNumber] = useState(student.roll_number || "");
  const [id] = useState(student._id || 0);

  const handleSubmit = () => {
    !firstName || !lastName || !email || !rollNumber
      ? alert("Please fill all fields!")
      : id === 0
      ? checkRollNumber()
      : updateStudent();
  };

  const checkRollNumber = async () => {
    try {
      const result = await api.students.checkRollNumber(schoolId, rollNumber);
      result.length === 0
        ? addStudent()
        : alert(
            `This ID is taken by ${result[0].first_name} ${result[0].last_name}, and was created at ${result[0].created_at}!`
          );
    } catch (err) {
      console.log(err);
    }
  };

  const addStudent = async () => {
    const payload = [
      {
        first_name: firstName,
        last_name: lastName,
        parent_email: email,
        roll_number: rollNumber,
        school_id: schoolId,
      },
    ];
    try {
      await api.students.uploadPreReg(payload);
    } catch (err) {
      alert("Sorry, that wasn't possible");
    } finally {
      openModal(false);
      addEdit(false);
    }
  };

  const updateStudent = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      parent_email: email,
      roll_number: rollNumber,
      school_id: schoolId,
    };
    try {
      await api.students.updatePreReg(id, payload);
    } catch (err) {
      alert("Sorry, that wasn't possible");
    } finally {
      openModal(false);
      addEdit(false);
    }
  };

  const deleteStudent = async () => {
    try {
      await api.students.deletePreReg(id);
    } catch (err) {
      alert("Sorry, that wasn't possible");
    } finally {
      openModal(false);
      addEdit(false);
    }
  };

  return (
    <>
      <div>
        <form className="mb-3 pt-0">
          <Input
            placeholder="First Name"
            val={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            placeholder="Last Name"
            val={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Input
            placeholder="Email"
            val={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="Customer ID"
            val={rollNumber}
            onChange={(e) => setRollNumber(e.target.value)}
          />
          {id !== 0 && (
            <div className="w-full flex flex-row items-left mt-4">
              <button
                className="bg-red-500 flex-start text-white focus:bg-gray-400 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-ring ease-linear transition-all duration-150"
                type="button"
                onClick={() => deleteStudent()}
              >
                Delete
              </button>
            </div>
          )}
          <div className="w-full flex flex-col items-center mt-4">
            <ButtonTwo title="Save" onClick={() => handleSubmit()} />
          </div>
        </form>
      </div>
    </>
  );
};
export default AddEditPreRegStudent;

// import React, { useState, useEffect } from "react";
// import api from "../Api";
// import Input from "./Input";
// import ButtonTwo from "./ButtonTwo";

// const AddEditPreRegStudent = ({ openModal, addEdit, student }) => {
//   const { schoolId } = JSON.parse(localStorage.getItem("school"));
//   const [formData, setFormData] = useState({
//     schoolId,
//     first_name: "",
//     last_name: "",
//     parent_email: "",
//     roll_number: "",
//     id: 0,
//   });

//   useEffect(() => {
//     if (student) {
//       setFormData({ ...student, schoolId });
//     }
//   }, [student, schoolId]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const { first_name, last_name, parent_email, roll_number, id } = formData;

//     if (!first_name || !last_name || !parent_email || !roll_number) {
//       showAlert("Please fill all fields!");
//     } else {
//       try {
//         if (id === 0) {
//           await checkRollNumber();
//         } else {
//           await updateStudent();
//         }
//       } catch (err) {
//         showAlert("An error occurred, please try again.");
//         console.error(err);
//       }
//     }
//   };

//   const checkRollNumber = async () => {
//     const { schoolId, roll_number } = formData;
//     const result = await api.students.checkRollNumber(schoolId, roll_number);

//     if (result.length === 0) {
//       await addStudent();
//     } else {
//       showAlert(
//         `This ID is taken by ${result[0].first_name} ${result[0].last_name}, and was created at ${result[0].created_at}!`
//       );
//     }
//   };

//   const addStudent = async () => {
//     await api.students.uploadPreReg([formData]);
//     resetForm();
//   };

//   const updateStudent = async () => {
//     const { id, ...payload } = formData;
//     await api.students.updatePreReg(id, payload);
//     resetForm();
//   };

//   const deleteStudent = async () => {
//     const { id } = formData;
//     await api.students.deletePreReg(id);
//     resetForm();
//   };

//   const showAlert = (message) => alert(message);

//   const resetForm = () => {
//     setFormData({
//       schoolId,
//       first_name: "",
//       last_name: "",
//       parent_email: "",
//       roll_number: "",
//       id: 0,
//     });
//     openModal(false);
//     addEdit(false);
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <Input
//         name="first_name"
//         placeholder="First Name"
//         value={formData.first_name}
//         onChange={handleChange}
//       />
//       <Input
//         name="last_name"
//         placeholder="Last Name"
//         value={formData.last_name}
//         onChange={handleChange}
//       />
//       <Input
//         name="parent_email"
//         placeholder="Email"
//         value={formData.parent_email}
//         onChange={handleChange}
//       />
//       <Input
//         name="roll_number"
//         placeholder="Customer ID"
//         value={formData.roll_number}
//         onChange={handleChange}
//       />

//       {formData.id !== 0 && (
//         <button type="button" onClick={deleteStudent}>
//           Delete
//         </button>
//       )}

//       <ButtonTwo title="Save" onClick={handleSubmit} />
//     </form>
//   );
// };

// export default AddEditPreRegStudent;
