/*
 * Hello later developers!  I'm afraid you will find this project a little confusing!  We were kept in the dark on its scope and purpose.
 * We were told it was for schools, with four ordering points in the day.  It turns out it's for anyone running food service.
 * You will find the naming confusing, and the code a little messy; we are sorry about that.  I hope you can make sense of it.
 * We weren't given the budget to refactor (or not as I write this), so I'm afraid you'll have to take it as it is!
 *
 * N.B. combined_logo.png is not used here, but is used in HTML emails.  It is in the public folder.  Please don't delete it!
 */

/*
 * GPSpay brown color: #B95B09
 * GPSpay dark gray: #313238
 */

import React from "react";
import AppRoutes from "./routes";
import { useHistory } from "react-router-dom";

const App = () => {
  const history = useHistory();

  return (
    <>
      <React.StrictMode>
        <AppRoutes history={history} />
      </React.StrictMode>
    </>
  );
};

export default App;

// To deploy a version to staging, use the following command, and distribute the returned url
// firebase hosting:channel:deploy staging
