import React, { useState } from "react";

const EditCheckbox = ({
  service,
  label,
  value,
  val,
  locationEndDate,
  locationStartDate,
  id,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  function handleOnChange() {
    setIsChecked(!isChecked);
    val(!isChecked);
    if (!isChecked && locationEndDate) {
      locationEndDate(yesterday);
      locationStartDate(yesterday);
    }
  }

  return (
    <>
      <label
        htmlFor={id}
        className="mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {label}
      </label>
      <input
        id={id}
        name={service}
        className="w-4 h-4 mt-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        type="checkbox"
        value={isChecked}
        defaultChecked={isChecked}
        key={service}
        onChange={() => handleOnChange()}
      ></input>
    </>
  );
};

export default EditCheckbox;
