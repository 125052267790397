import React, { useEffect, useState } from "react";
// API
import api from "../Api";
// Layouts
import MainLayout from "../layout/Main";
//Helpers
import DateManager from "../utils/convertDate";
import { getCurrentWeek } from "../utils/functions";
// Components
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Search from "../components/Search";
import DateRangePicker from "../components/DateRangePicker";
import {
  TransactionList,
  TransactionListHeaders,
} from "../components/StudentTransactionListItemised";

// url/student-transactions-itemized

const StudentTransactionsPOSItemised = () => {
  const school = JSON.parse(localStorage.getItem("school") || "{}");
  const schoolId = school?.schoolId;
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [studentTransactions, setStudentTransactions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const pageCount = Math.ceil(studentTransactions.length / itemsPerPage);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const mealType = "pos-order";

  useEffect(() => {
    // Set the date range to the current week
    const { monday, friday } = getCurrentWeek();
    setStartDate(monday);
    setEndDate(friday);
  }, []);

  const filterData = (keys, value, data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(value.toLowerCase()))
    );
  };

  useEffect(() => {
    if (search.length > 2) {
      setFilteredData(
        filterData(["last_name", "roll_number"], search, studentTransactions)
      );
    } else {
      setFilteredData(studentTransactions);
    }
  }, [search, studentTransactions]);

  const fetchStudentTransactions = async () => {
    if (!startDate || !endDate) {
      return;
    }
    setLoading(true);
    try {
      const transactions = await api.reports.studentTransactionsItemised(
        schoolId,
        DateManager.formatDateForMysql(startDate),
        DateManager.formatDateForMysql(endDate)
      );
      if (transactions.resource) {
        let data = transactions.resource;
        data = groupByStudent(data);
        setStudentTransactions(data);
      }
    } catch (err) {
      alert("Error fetching transactions");
      console.log(`Error is ${err}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchTransactions = async () => {
      if (isMounted) {
        await fetchStudentTransactions();
      }
    };
    fetchTransactions();
    return () => {
      isMounted = false;
    };
  }, [startDate, endDate, schoolId]);

  const handlePaginationClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const paginatedData = filteredData.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  // Group the data by student,. because MySQL doesn't have JSON_AGG etc
  function groupByStudent(rows) {
    const grouped = {};
    rows.forEach((row) => {
      if (!grouped[row.student_id]) {
        grouped[row.student_id] = {
          student_id: row.student_id,
          first_name: row.first_name,
          last_name: row.last_name,
          roll_number: row.roll_number,
          family_id: row.family_id,
          orders: [],
          total_price: 0,
        };
      }

      if (row.transaction_id !== null) {
        grouped[row.student_id].orders.push({
          transaction_id: row.transaction_id,
          local_date: row.local_date,
          local_time: row.local_time,
          price: row.price,
          created_by: row.created_by,
          payment_type: row.payment_type,
          short_name: row.short_name,
          collected: null,
        });
        grouped[row.student_id].total_price += parseFloat(row.price);
      }
    });
    return Object.values(grouped);
  }

  return (
    <MainLayout
      pageTitle=""
      rightContainer={
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      }
      leftContainer={
        <Search
          id={"search"}
          placeholder={"Search Last Name or Student ID"}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      }
      headers={<TransactionListHeaders />}
      content={
        loading ? (
          <Loading />
        ) : (
          paginatedData && (
            <>
              <div className="mb-16">
                <TransactionList data={paginatedData} mealType={mealType} />
              </div>
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePaginationClick}
              />
            </>
          )
        )
      }
    />
  );
};

export default StudentTransactionsPOSItemised;
