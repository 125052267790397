import React, { useEffect, useState } from "react";
// API
import api from "../Api";
// Layouts
import MainLayout from "../layout/Main";
//Helpers
import DateManager from "../utils/convertDate";
// Components
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import Search from "../components/Search";
import DateRangePicker from "../components/DateRangePicker";
import {
  TransactionList,
  TransactionListHeaders,
} from "../components/StudentTranactionBySchoolList";

const StudentTransactions = () => {
  const school = JSON.parse(localStorage.getItem("school") || "{}");
  const schoolId = school?.schoolId;
  const itemsPerPage = 50;
  const [itemOffset, setItemOffset] = useState(0);
  const [studentTransactions, setStudentTransactions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const pageCount = Math.ceil(studentTransactions.length / itemsPerPage);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const filterData = (key, value, data) => {
    return data.filter((item) =>
      item[key].toLowerCase().includes(value.toLowerCase())
    );
  };

  useEffect(() => {
    if (search.length > 2) {
      setFilteredData(filterData("last_name", search, studentTransactions));
    } else {
      setFilteredData(studentTransactions);
    }
  }, [search, studentTransactions]);

  const fetchStudentTransactions = async () => {
    if (!startDate || !endDate) {
      return;
    }
    setLoading(true);
    try {
      const transactions = await api.reports.studentTransactions(
        schoolId,
        DateManager.formatDateForMysql(startDate),
        DateManager.formatDateForMysql(endDate)
      );
      if (transactions.resource) {
        let data = transactions.resource;
        data = data.map((item) => {
          if (item.debits) {
            const debitsArray = item.debits.split(",").map(Number);
            const total = debitsArray.reduce((acc, curr) => acc + curr, 0);
            return { ...item, total };
          }
          return item;
        });

        setStudentTransactions(data);
      }
    } catch (err) {
      console.log(`Error is ${err}`);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;
    const fetchTransactions = async () => {
      if (isMounted) {
        await fetchStudentTransactions();
      }
    };
    fetchTransactions();
    return () => {
      isMounted = false;
    };
  }, [startDate, endDate, schoolId]);

  const handlePaginationClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const paginatedData = filteredData.slice(
    itemOffset,
    itemOffset + itemsPerPage
  );

  return (
    <MainLayout
      pageTitle=""
      rightContainer={
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      }
      leftContainer={
        <Search
          id={"search"}
          placeholder={"Search Last Name"}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      }
      headers={<TransactionListHeaders />}
      content={
        loading ? (
          <Loading />
        ) : (
          paginatedData && (
            <>
              <div className="mb-16">
                <TransactionList data={paginatedData} />
              </div>
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePaginationClick}
              />
            </>
          )
        )
      }
    />
  );
};

export default StudentTransactions;
