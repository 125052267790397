import React from "react";
import DateManager from "../utils/convertDate";

export const PosSalesList = ({ data }) => {
  return data.map((item, index) => {
    return (
      <div className={`text-lg px-6 py-4 even:bg-gray-50`} key={index}>
        <div className="flex">
          <span className="flex-1 my-auto">{item.type}</span>
          <span className="flex-1 my-auto">
            {DateManager.USDate(item.date)}
          </span>
          <span className="flex-1 my-auto">{item.short_name}</span>
          <span className="flex-1 my-auto text-right">{item.order_count}</span>
          <span className="flex-1 my-auto text-right">{}</span>
          <span className={"flex-1 my-auto text-right"}>
            {item.total_value}
          </span>
        </div>
      </div>
    );
  });
};

export const PosSalesListHeaders = () => {
  return (
    <div className="flex">
      <span className="flex-1">Type</span>
      <span className="flex-1">Date</span>
      <span className="flex-1">Short Name</span>
      <span className="flex-1 text-right">Order Count</span>
      <span className="flex-1 text-right"></span>
      <span className="flex-1 text-right">Total $</span>
    </div>
  );
};
