// Component editSchoolMenuItem.js takes the object from the master menu. This reconstructs that form for editing an item.
export class EditableObject {
  constructor(e) {
    this._id = e._id;
    this.title = e.menu_item.title;
    this.price = e.price;
    this.start_date = e.start_date;
    this.end_date = e.end_date;
    this.breakfast = e.menu_item.breakfast;
    this.nutrition = e.menu_item.nutrition;
    this.lunch = e.menu_item.lunch;
    this.after_school = e.menu_item.after_school;
    this.pos_position = e.pos_position;
    this.short_name = e.short_name;
    this.archive = e.archive;
    this.pre_order = e.pre_order;
  }
}
