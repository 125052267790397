import axios from "axios";
import { API_BASE_URL, COMMON_API_KEY } from "./config";

const franchise = {
  // Filter on server returns only the franchises with the users id
  getFranchise: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await axios.get(
      `${API_BASE_URL}/mysql/_table/franchise_admins?api_key=${COMMON_API_KEY}&session_token=${user.session_token}`
    );
    return response;
  },
};

export default franchise;
