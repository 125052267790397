import React, { useState } from "react";
import api from "../../Api";
import Modal from "../../components/Modal";
import ConfirmCode from "../../components/ConfirmCode";
import ButtonTwo from "../../components/ButtonTwo";
import { validateEmail } from "../../utils/validations";

const ForgotPassword = ({ path }) => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [reset] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = async () => {
    setEmailErr("");
    if (validateEmail(email)) {
      try {
        await api.user.reset(email, reset);
        setOpenModal(true);
      } catch (err) {
        setEmailErr(err.response.data.error.message);
      }
    } else {
      setEmailErr("Email format is invalid!");
    }
  };

  return openModal ? (
    <>
      <Modal
        title={"Check your email for a confirmation code"}
        openModal={setOpenModal}
        content={<ConfirmCode openModal={setOpenModal} email={email} />}
      />
    </>
  ) : (
    <>
      <div className="outer">
        <div className="input-container">
          <span className="text-xl inline-block my-2">Reset Account</span>
          <form>
            <div className="relative flex w-full flex-wrap items-stretch my-5">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-envelope"></i>
              </span>
              <input
                id="email"
                value={email}
                autoComplete="off"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErr("");
                }}
                type="email"
                placeholder="Email"
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"
              />
              <span className="inline-block text-red-600 text-sm mt-3 w-full text-center">
                {emailErr}
              </span>
            </div>
          </form>
          <div className="flex flex-col items-center py-8">
            <ButtonTwo title="Submit" onClick={() => handleSubmit()} />
          </div>
          <button
            className="text-gray-400 text-sm my-10 hover:text-blue-600 inline-block cursor-pointer"
            onClick={() => path("login")}
          >
            Or sign in?
          </button>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
